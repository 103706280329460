import React from 'react'
import { useState, useEffect } from 'react';
import './index.css'
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import socket from '../../socket';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import { PaginationControl } from 'react-bootstrap-pagination-control';

const domainName = window.location.hostname;
  
const isIncluded = domainName.includes("testholdem.smgame77.com");

let BackendURL; 
let FrontURL;

if(isIncluded) {
    BackendURL = "http://ec2-43-207-107-237.ap-northeast-1.compute.amazonaws.com:8080/";
    FrontURL = "http://ec2-43-207-107-237.ap-northeast-1.compute.amazonaws.com/";
} else {
    BackendURL = "http://ec2-13-231-89-219.ap-northeast-1.compute.amazonaws.com:8080/";
    FrontURL = "http://ec2-13-231-89-219.ap-northeast-1.compute.amazonaws.com/";
}

//const BackendURL = "http://ec2-13-231-89-219.ap-northeast-1.compute.amazonaws.com:8080/";
//const FrontURL = "http://ec2-13-231-89-219.ap-northeast-1.compute.amazonaws.com/";

const lang = require('../../Config/lang.js');

const INITIAL_STATE = {
  errorMsg: '',
  oldPassword: '',
  newPassword: '',
  verifyNewPassword: ''
};

const MyAccount = (props) => {
  const [form, setForm] = useState(INITIAL_STATE);
  const userData = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(true)
  const [userProfilePhoto, setUserProfilePhoto] = useState(userData ? userData.avatar : 0);
  const [userProfilePicOption, setUserProfilePicOption] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
    61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75]);
  const [userProfileForm, setUserProfileForm] = useState(userData);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)
  const [gameHistoryList, setGameHistoryList] = useState({});
  const [jackpotHistoryList, setJackpotHistoryList] = useState({});
  const [affiliatesData, setAffiliatesData] = useState({});
  const [gameHistory, setGameHistory] = useState({})
  const [page, setPage] = useState(1);
  const [isGameHistory, setIsGameHistory] = useState(false);
  const [isEditProfile, setIsEditProfile] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [preFlop, setPreFlop] = useState({});
  const [flop, setFlop] = useState({});
  const [turn, setTurn] = useState({});
  const [river, setRiver] = useState({});
  const [showDown, setShowDown] = useState({});
  const [isCopyToClipboard, setIsCopyToClipboard] = useState(false);
  const [tableThemeSkinColor, setTableThemeSkinColor] = useState('default');
  const [tableThemeBackgroundColor, setTableThemeBackgroundColor] = useState('default');
  const [tableThemeFrontDeck, setTableThemeFrontDeck] = useState('default');
  const [tableThemeBackDeck, setTableThemeBackDeck] = useState('default');
  const [tableSettingTab, seTableSettingTab] = useState('frontDeck');
  const [totalCashHist, setTotalCashHist] = useState(0);
  const [totalDepoHist, setTotalDepoHist] = useState(0);
  
  const [activeHistoryList, setActiveHistoryList] = useState({});
  const [isActiveHistory, setIsActiveHistory] = useState({
    table: 'DepositHistory'
  })

  const [isActive, setIsActive] = useState({
    table: 'profile'
  })

  const [isActiveHand, setIsActiveHand] = useState({
    table: 'game'
  })

  useEffect(() => {
    const mobile = window.innerWidth <= 1024;
    setIsMobile(mobile);
  }, [window.innerWidth]);

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.id]: event.target.value,
    });
  };
  
  const replaceFirstThreeChars = (text) => {
      if (text.length <= 3) {
          return "***";
      }
      return "***" + text.slice(3);
  };

  const handleProfileChange = (event) => {
    setUserProfileForm({
      ...userProfileForm,
      [event.target.id]: event.target.value,
    });
  };

  const playerProfilePhoto = (img) => {
    return '../Images/Avtar/' + (img + 1) + '.png?v=001'
  }

  const changePage = (value) => {
    setPage(value)
    
    var id = isActiveHistory.table;
    //console.log(id);
    if (id == 'CashHistory') {
      const data = {
        playerId: userData.playerId,
        pageNo: value,
        productName: 'PokerHills'
      }
      socket.emit('getWithdrawHistory', data, (res) => {
        //console.log(res)
        if (res.status == 'success') {
          //console.log(res.result.WithdrawHistoryList);
          setActiveHistoryList(res.result.WithdrawHistoryList);
        } else {
          setActiveHistoryList({});
        }
      });
    } else if (id == 'jackpotHistory') {
      const data = {
        playerId: userData.playerId,
        pageNo: value,
        productName: 'PokerHills'
      }
      socket.emit('jackpotHistory', data, (res) => {
        //console.log(res)
        if (res.status == 'success') {
          //console.log(res.result.jackpotHistory);
          setJackpotHistoryList(res.result);
        } else {
          setActiveHistoryList({});
        }
      });
    } else if (id == 'DepositHistory') {
      const data = {
        playerId: userData.playerId,
        pageNo: value,
        productName: 'PokerHills'
      }
      socket.emit('getDepositHistory', data, (res) => {
        //console.log(res)
        if (res.status == 'success') {
          //console.log(res.result.DepositHistoryList);
          setActiveHistoryList(res.result.DepositHistoryList);
        } else {
          setActiveHistoryList({});
        }
      });
    } else {
      const data = {
        playerId: userData.playerId,
        pageNo: value,
        productName: 'PokerHills'
      }
      socket.emit('playerGameHistory', data, (res) => {
        //console.log(res.result);
        setGameHistoryList(res.result);
      });
    }
  }

  const changePlayerAvtar = (type) => {
    if (type == 0) {
      const data = {
        playerId: userData.playerId,
        profilePic: userProfilePhoto,
      }
      socket.emit('playerProfilePic', data, (res) => {
        if (res.status == 'success') {
          let gamePlayer = userData;
          gamePlayer.profilePic = userProfilePhoto
          localStorage.setItem('user', JSON.stringify(gamePlayer));
          props.refreshData();
          closeModal();
        }
      });
    } else {
      setUserProfilePhoto(userData.avatar)
      closeModal();
    }
  }

  const changePlayerProfile = (type) => {
    const data = {
      playerId: userData.playerId,
      firstname: userProfileForm.firstname,
      lastname: userProfileForm.lastname,
      email: userProfileForm.email,
      mobile: userProfileForm.mobile,
    }
    socket.emit('changePlayerProfile', data, (res) => {
      if (res.status == 'success') {
        toast.success(res.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        socket.emit('Playerprofile', {
          playerId: userData.playerId,
          productName: 'PokerHills'
        }, (res) => {
          localStorage.setItem('user', JSON.stringify(res.result));
          props.refreshData();
          setIsEditProfile(false);
        });
      } else {
        toast.error(res.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    });
  }

  const changePassword = (type) => {
    form.errorMsg = '';
    if (type == 0) {
      const data = {
        playerId: userData.playerId,
        oldPassword: form.oldPassword,
        newPassword: form.newPassword,
        verifyNewPassword: form.verifyNewPassword,
      }
      socket.emit('playerChangePassword', data, (res) => {
        if (res.status == 'success') {
          toast.success(res.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setForm({
            oldPassword: '',
            newPassword: '',
            verifyNewPassword: '',
          });
          closeModal();
        } else {
          toast.error(res.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      });
    } else {
      closeModal();
    }
  }

  const saveTableThemeSetting = (e) => {
    const data = {
      playerId: userData.playerId,
      tableTheme: tableThemeSkinColor,
      tableBackground: tableThemeBackgroundColor,
      backDeck: tableThemeBackDeck,
      frontDeck: tableThemeFrontDeck,
      productName: 'PokerHills'
    }
    socket.emit('TableThemeSettingsUpdate', data, (res) => {
      closeModal();
    });
  }

  const logout = (e) => {
    localStorage.removeItem("user");
    navigate('/')
  }

  const closeModal = () => {
    props.closeMyProfile()
  }

  const openModal = () => {
    setIsLogoutModalOpen(true);
  }
  const closeLogoutModal = () => {
    setIsLogoutModalOpen(false);
  }

  const hideShowDiv = (id) => {
    if(id=='allTra')
    {
      setIsActiveHistory({
        table: 'DepositHistory',
      })
    }
    else
    {
      setIsActiveHistory({
        table: id,
      })
    }
    
    setPage(1);
    
    if (id == 'jackpotHistory') {
      
      setIsActiveHistory({
        table: id,
      })
      const data = {
        playerId: userData.playerId,
        pageNo: 1,
        productName: 'PokerHills'
      }
      socket.emit('jackpotHistory', data, (res) => {
        //console.log(res.result);
        setJackpotHistoryList(res.result);
      });
      setIsActive({
        table: 'gameplayer',
      })
    }
    if (id == 'handHistory') {
      setIsGameHistory(false);
      const data = {
        playerId: userData.playerId,
        pageNo: 1,
        productName: 'PokerHills'
      }
      socket.emit('playerGameHistory', data, (res) => {
        //console.log(res.result);
        setGameHistoryList(res.result);
      });
      setIsActive({
        table: 'gameplayer',
      })
    }
    if (id == 'myAffiliates') {
      const data = {
        playerId: userData.playerId,
        productName: 'PokerHills'
      }
      socket.emit('MyAffiliates', data, (res) => {
        //console.log(res.result);
        setAffiliatesData(res.result);
      });
    }
    
    if (id == 'DepositHistory' || id == "allTra") {
      const data = {
        playerId: userData.playerId,
        pageNo: 1,
        productName: 'PokerHills'
      }
      socket.emit('getDepositHistory', data, (res) => {
        //console.log(res)
        if (res.status == 'success') {
          //console.log(res.result.DepositHistoryList);
          setTotalDepoHist(res.result.recordsTotal);
          setActiveHistoryList(res.result.DepositHistoryList);
        } else {
          setActiveHistoryList({});
        }
      });
    }
    
    if (id == 'tableTheme') {
      const data = {
        playerId: userData.playerId,
        productName: 'PokerHills'
      }
      socket.emit('TableThemeSettings', data, (res) => {
        console.log(res)
        if (res.status == 'success') {
          setTableThemeSkinColor(res.result.tableTheme)
          setTableThemeBackgroundColor(res.result.tableBackground)
          setTableThemeFrontDeck(res.result.frontDeck)
          setTableThemeBackDeck(res.result.backDeck)
        }
      });
    }
    setIsActive({
      table: id,
    })
  }

  const hideShowHandTab = (id) => {
    setPage(1)
    if (id == 'CashHistory') {
      const data = {
        playerId: userData.playerId,
        pageNo: 1,
        productName: 'PokerHills'
      }
      socket.emit('getWithdrawHistory', data, (res) => {
        //console.log(res)
        if (res.status == 'success') {
          // console.log("wd-res:",res.result.recordsTotal);
          setTotalCashHist(res.result.recordsTotal);
          setActiveHistoryList(res.result.WithdrawHistoryList);
        } else {
          setActiveHistoryList({});
        }
      });
      
      setIsActiveHistory({
        table: id,
      })
    }
    if (id == 'DepositHistory') {
      const data = {
        playerId: userData.playerId,
        pageNo: 1,
        productName: 'PokerHills'
      }
      socket.emit('getDepositHistory', data, (res) => {
        //console.log(res)
        if (res.status == 'success') {
          //console.log(res.result.DepositHistoryList);
          setTotalDepoHist(res.result.recordsTotal);
          setActiveHistoryList(res.result.DepositHistoryList);
        } else {
          setActiveHistoryList({});
        }
      });
      
      setIsActiveHistory({
        table: id,
      })
    }
    
    setIsActiveHand({
      table: id,
    });
    
    
  }
  const createeNewAffiliaates = (code) => {
    const data = {
      playerId: userData.playerId,
      productName: 'PokerHills'
    }
    socket.emit('CeateMyAffiliates', data, (res) => {
      hideShowDiv('myAffiliates');
    });
  }

  const copyToClipboard = (code) => {
    navigator.clipboard.writeText(FrontURL + '#/signup/' + code);
    setIsCopyToClipboard(true);
    setTimeout(() => {
      setIsCopyToClipboard(false);
    }, 1000);
  }

  const openGameHistory = (gameId) => {
    const data = {
      playerId: userData.playerId,
      gameId: gameId,
      productName: 'PokerHills'
    }
    socket.emit('GameHistory', data, (res) => {
      if (res.result) {
        setIsGameHistory(true);
        hideShowHandTab('gameplayer')
        setGameHistory(res.result.gameHistory);
        const data = res.result.gameHistory;
        let preFlopArr = [];
        let flopArr = [];
        let turnArr = [];
        let riverArr = [];
        let showDownArr = [];
        data.handsEvents.map(((evnt, index) => {
          if (evnt.gameRound == 'Preflop') {
            preFlopArr.push(evnt);
          }
          if (evnt.gameRound == "Flop") {
            flopArr.push(evnt);
          }
          if (evnt.gameRound == 'Turn') {
            turnArr.push(evnt);
          }
          if (evnt.gameRound == 'River') {
            riverArr.push(evnt);
          }
          if (evnt.gameRound == 'ShowDown') {
            showDownArr.push(evnt);
          }
        }));
        setPreFlop(preFlopArr);
        setFlop(flopArr);
        setTurn(turnArr);
        setRiver(riverArr);
        setShowDown(showDownArr);
      }
    });
  }

  const playerActionArr = (action) => {
    if (action == 0) { return 'Small Blind' }
    else if (action == 1) { return 'Big Blind' }
    else if (action == 2) { return 'Check' }
    else if (action == 3) { return 'Bet' }
    else if (action == 4) { return 'Call' }
    else if (action == 5) { return '' }
    else if (action == 6) { return 'Fold' }
    else if (action == 7) { return 'Timeout' }
    else if (action == 8) { return 'All-in' }
    else if (action == 9) { return 'Straddle' }
    else { return 'Straddle' }
  }

  const mobile = window.innerWidth <= 1024;
  let customStylesLogout = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#242234',
      width: '30%',
      height: '35%',
      border: '1px solid #ffd050'
    },
  }
  let customStyles = {
    content: {
      inset: '44% auto auto 49.5%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#474B6B00',
      width: "60.625em",
      border: '1px solid #ffd0506e',
      boxShadow: '4px 4px 18px #ffd05075, -4px -4px 18px #ffd05075',
      padding: '0px',
      borderRadius: '15px',
    },
  }
  if (mobile) {
    customStylesLogout = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#242234',
        width: '60%',
        height: '30%',
        border: 'none'
      },
    }
    customStyles = {
      content: {
        inset: '50% auto auto 50%',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#474B6B00',
        width: '100%',
        padding: '0px',
        border: 'none'
      },
    }
  }
  
    const historyTabStatus = function(text){
      switch(text)
      {
        case 'Pending': case 'pending':
          return '보류';
          break;
        case 'Approved': case 'approved':
          return '승인';
          break;
        case 'Denied': case 'denied':
          return '실패';
          break;
        default:
          return text;
          break;
      }

    }


  return (
    <div >
      <ToastContainer />
      <Modal
        isOpen={isAccountModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className={ mobile ? 'mobileView plyerProfile' : 'plyerProfile'}>
          <div className="cashier-modal myacc">
            <div className='d-flex min-h-100'>
              <div className='p-col-3 modal-sidebar min-h-100'>
                <div className='modal-sidebar-header'>
                  <div className='cashier-heading'>
                    <h2>{lang.ko.my_account}</h2>
                    { isMobile && (
                      <img src={'../Images/close.png'} onClick={() => closeModal(false)} className='close-modal-btn'></img>
                    )}
                  </div>
                </div>
                <div className={ isMobile ? 'cashier-modal-menu' : 'cashier-modal-body-profile-sidebar'}>
                  <button className={isActive.table === 'profile' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="profile"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>{lang.ko.profile}</button>
                  { !isMobile && (
                    <hr></hr>
                  )}
                  <button className={isActive.table === 'changeavatar' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="changeavatar"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>{lang.ko.change_avatar}</button>
                  {/*
                  <button className={isActive.table === 'changepassword' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="changepassword"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>{lang.ko.change_password}</button>
                  { !isMobile && (
                    <hr></hr>
                  )}
                  */}
                  {/*<button className={isActive.table === 'myAffiliates' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="myAffiliates"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>{lang.ko.my_affiliates}</button>
                  <button className={isActive.table === 'accountSecurity' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="accountSecurity"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>{lang.ko.account_security}</button>*/}
                  {/*<button className={isActive.table === 'settings' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="settings"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>{lang.ko.settings}</button>
                  { !isMobile && (
                    <hr></hr>
                  )}*/}
                  <button className={isActive.table === 'tableTheme' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="tableTheme"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>{lang.ko.table_theme}</button>
                  { !isMobile && (
                    <hr></hr>
                  )}
                  <button className={isActive.table === 'handHistory' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="handHistory"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>{lang.ko.hands_history}</button>
                    
                  <button className={isActive.table === 'allTra' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="allTra"
                      onClick={(e) => {hideShowDiv(e.target.id)}}>{lang.ko.transaction_history}</button>
                  { !isMobile && (
                    <hr></hr>
                  )}
                  {/*
                  <button className='btn modal-sidebar-button'
                    onClick={() => openModal(true)}>{lang.ko.logout}</button>
                  */}
                  {/*userData.usejackpot == "true" ?
                  <button className={isActive.table === 'jackpotHistory' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="jackpotHistory"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>{lang.ko.jackpot_history}</button>
                    :''*/}
                </div>
              </div>
              <div className='p-col-9 min-h-100 theme-bg'>
                <div className='cashier-modal-card'>
                  <div className='cashier-modal-heading'>
                    {isActive.table === 'profile' ?
                      <h2 >{lang.ko.profile}</h2>
                      : isActive.table === 'changeavatar' ?
                        <h2 >{lang.ko.change_avatar}</h2>
                        : isActive.table === 'changepassword' ?
                          <h2 >{lang.ko.change_password}</h2>
                          : isActive.table === 'accountSecurity' ?
                            <h2 >{lang.ko.account_settings}</h2>
                            : isActive.table === 'myAffiliates' ?
                              <h2 >{lang.ko.my_affiliates}</h2>
                              : isActive.table === 'settings' ?
                                <h2 >{lang.ko.settings}</h2>
                                : isActive.table === 'tableTheme' ?
                                  <h2 >{lang.ko.table_theme}</h2>
                                  : isActive.table === 'handHistory' ?
                                    <h2 >{lang.ko.hands_history}</h2>
                                    : ''
                    }
                    { !isMobile && (
                      <img src={'../Images/close.png'} onClick={() => closeModal(false)}></img>
                    )}
                  </div>
                  <div className='cashier-modal-body'>
                    <div className={isActive.table === 'profile' ? `profile` : 'profile d-none'}>
                      <div className='playerProfileInfo cashier-box'>
                        <Row>
                          {isEditProfile ?
                            <Col lg={6}>
                              <div className='form-group'>
                                <label>{lang.ko.first_name}</label>
                                <div className="inputWithIcon">
                                  <input type="text" placeholder="Not Set" onChange={handleProfileChange} value={userProfileForm.firstname} id="firstname" />
                                </div>
                              </div>
                              <div className='form-group'>
                                <label>{lang.ko.last_name}</label>
                                <div className="inputWithIcon">
                                  <input type="text" placeholder="Not Set" onChange={handleProfileChange} value={userProfileForm.lastname} id="lastname" />
                                </div>
                              </div>
                              {/* <div className='form-group'>
                                <label>{lang.ko.username}</label>
                                <div className="inputWithIcon">
                                  <input type="text" placeholder="Not Set" onChange={handleProfileChange} value={userProfileForm.username} id="username" disabled/>
                                </div>
                              </div> */}
                              <div className='form-group'>
                                <label>{lang.ko.email}</label>
                                <div className="inputWithIcon">
                                  <input type="text" placeholder="Not Set" onChange={handleProfileChange} value={userProfileForm.email} id="email" />
                                </div>
                              </div>
                              <div className='form-group'>
                                <label>{lang.ko.mobile}</label>
                                <div className="inputWithIcon">
                                  <input type="text" placeholder="Not Set" onChange={handleProfileChange} value={userProfileForm.mobile} id="mobile" />
                                </div>
                              </div>
                            </Col>
                            :
                            <Col lg={6}>
                              <div className='mb-1'>
                                <label>{lang.ko.username}</label>
                                <p className='profileDetails'>{userData?.username ? replaceFirstThreeChars(userData.username.split('_')[0]) : 'Not Set'}</p>
                              </div>
                              <div className='mb-1'>
                                <label>{lang.ko.first_name}</label>
                                <p className='profileDetails'>{userData?.firstname ? replaceFirstThreeChars(userData.firstname.split('_')[0]) : 'Not Set'}</p>
                              </div>
                              {/*
                              <div className='mb-1'>
                                <label>{lang.ko.last_name}</label>
                                <p className='profileDetails'>{userData?.lastname ? userData?.lastname : 'Not Set'}</p>
                              </div>
                              <div className='mb-1'>
                                <label>{lang.ko.email}</label>
                                <p className='profileDetails'>{userData?.email ? userData?.email : 'Not Set'}</p>
                              </div>
                              <div className='mb-1'>
                                <label>{lang.ko.mobile}</label>
                                <p className='profileDetails'>{userData?.mobile}</p>
                              </div>
                              */}
                              <div className='mb-1'>
                                <label>{lang.ko.total_chips}</label>
                                <p className='profileDetails'>{userData ? Number(userData.chips).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}</p>
                              </div>
                              {/*
                              <div className='mb-1'>
                                <label>{lang.ko.total_balance_hours}</label>
                                <p className='profileDetails'>{userData?.hour_balance ? userData?.hour_balance : '00:00'}</p>
                              </div>
                              */}
                            </Col>
                          }
                          <Col lg={6}>
                            <div className='plyerProfileAvtar'>
                              <div className={'playerProfileWithFrame vipLevel' + userData.vipLevel}>
                                <img src={playerProfilePhoto(userProfilePhoto)} className='plyrProfilePicMain'></img>
                              </div>
                            </div>
                            {/*
                            <div className='vip-level-section text-center'>
                              <h4>{lang.ko.vip_level}</h4>
                              <h4><span>{userData.vipLevel == 5 ? 'Diamond' : userData.vipLevel == 4 ? 'Platinum' : userData.vipLevel == 3 ? 'Gold' : userData.vipLevel == 2 ? 'Silver' : 'Bronze'}</span></h4>
                            </div>
                            */}
                          </Col>
                        </Row>
                      </div>
                      {/*
                      isEditProfile ?
                        <div className='backButtonSection mb-4'>
                          <button type="button" className='defaultBtn' onClick={() => setIsEditProfile(false)}>Cancel</button>
                          <button type="button" className='edtAvtarBtn' onClick={() => changePlayerProfile()}>{lang.ko.save}</button>
                        </div>
                        :
                        <div className='backButtonSection mb-4'>
                          <button type="button" className='edtAvtarBtn' onClick={() => setIsEditProfile(true)}>{lang.ko.edit}</button>
                        </div>
                      */}
                    </div>
                    <div className={isActive.table === 'changeavatar' ? `changeavatar` : 'changeavatar d-none'}>
                      <div>
                        <div className='text-center'>
                          {userProfilePicOption.map((pic, index) => {
                            return userProfilePhoto == index ?
                              <img onClick={() => setUserProfilePhoto(index)} key={index} src={playerProfilePhoto(index)} className='plyrProfilePic activeProfile'></img> :
                              <img onClick={() => setUserProfilePhoto(index)} key={index} src={playerProfilePhoto(index)} className='plyrProfilePic'></img>
                          })}
                        </div>
                        <div className='avtarBtnSection mb-4 text-center'>
                          {/* <button className='edtAvtarBtn' onClick={() => changePlayerAvtar(1)}>Cancel</button> */}
                          <button className='edtAvtarBtn confirm-btn rounded-btn px-5' onClick={() => changePlayerAvtar(0)}>{lang.ko.update}</button>
                        </div>
                      </div>
                    </div>
                    <div className={isActive.table === 'changepassword' ? `changepassword` : 'changepassword d-none'}>
                      <div className='playerProfileInfo cashier-box'>
                        <hr></hr>
                        <Row className='justify-content-center'>
                          <Col lg={8}>
                            <div className='form-group'>
                              <label>{lang.ko.current_password}</label>
                              <div className="inputWithIcon">
                                <input id="oldPassword"
                                  type="password"
                                  value={form.oldPassword}
                                  onChange={handleChange}
                                  placeholder="Enter Current Password" />
                              </div>
                            </div>
                          </Col>
                          <Col lg={8}>
                            <div className='form-group'>
                              <label>{lang.ko.new_password}</label>
                              <div className="inputWithIcon">
                                <input id="newPassword"
                                  type="password"
                                  value={form.newPassword}
                                  onChange={handleChange}
                                  placeholder="New Password" />
                              </div>
                            </div>
                          </Col>
                          <Col lg={8}>
                            <div className='form-group'>
                              <label>{lang.ko.confirm_password}</label>
                              <div className="inputWithIcon">
                                <input id="verifyNewPassword"
                                  type="text"
                                  value={form.verifyNewPassword}
                                  onChange={handleChange}
                                  placeholder="Confirm Password" />
                              </div>
                            </div>
                          </Col>
                        </Row>

                      </div>
                      <div className='avtarBtnSection mb-4 text-center'>
                        {/* <button type="button" className='defaultBtn' onClick={() => changePassword(1)}>Cancel</button> */}
                        <button type="button" className='edtAvtarBtn confirm-btn rounded-btn px-5' onClick={() => changePassword(0)}>{lang.ko.change}</button>
                      </div>
                    </div>
                    <div className={isActive.table === 'accountSecurity' ? `accountSecurity` : 'accountSecurity d-none'}>
                      <div className='acount-security-table'>
                        <table className='table p-lb-table table-borderless'>
                          <tbody>
                            <tr>
                              <td>{lang.ko.factory_authentication}</td>
                              <td><input type="checkbox" checked={true}></input></td>
                            </tr>
                            <tr>
                              <td>{lang.ko.security_questions}</td>
                              <td><input type="checkbox" checked={true}></input></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className={isActive.table === 'myAffiliates' ? `myAffiliates` : 'myAffiliates d-none'}>
                      <div className='acount-security-table'>
                        {affiliatesData.affliateUrl && affiliatesData.affliateUrl != '' ?
                          <Row>
                            <Col lg={6}>
                              <div className='affiliate-box text-center'>
                                <div className='affiliate-qr-img'>
                                  {affiliatesData.affliateCode && affiliatesData.affliateCode != '' ?
                                    <img src={BackendURL + affiliatesData.affliateCode} />
                                    : ''
                                  }
                                </div>
                                <p>Share the code with friends to get bonus in your account</p>
                                <div className='affiliate-code-text'>
                                  <p className='m-3 code-text'>{FrontURL + '#/signup/' + affiliatesData.affliateUrl}</p>
                                  {isCopyToClipboard ?
                                    <button type="button" className='edtAvtarBtn'>Copied</button>
                                    :
                                    <button type="button" className='edtAvtarBtn' onClick={() => copyToClipboard(affiliatesData.affliateUrl)}>Tap to copy</button>
                                  }
                                </div>
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className='affiliate-total-box'>
                                <div className='total-box border-bottom-gray'>
                                  <h5>{lang.ko.total_signup}</h5>
                                  <h5>{affiliatesData.totalSignup}</h5>
                                </div>
                                <div className='total-box border-bottom-gray'>
                                  <h5>{lang.ko.total_bonus}</h5>
                                  <h5>{affiliatesData.totalBonus}</h5>
                                </div>
                                <div className='total-box'>
                                  <h5>{lang.ko.total_commission}</h5>
                                  <h5>{affiliatesData.totalCommission}</h5>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          :
                          <Row>
                            <Col lg={6}>
                              <div className='affiliate-box text-center'>
                                <p className='m-4'>{lang.ko.alertmsg_givebonus}</p>
                                <button type="button" className='edtAvtarBtn' onClick={() => createeNewAffiliaates()}>{lang.ko.tap_to_create}</button>
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className='affiliate-total-box'>
                                <div className='total-box border-bottom-gray'>
                                  <h5>{lang.ko.total_signup}</h5>
                                  <h5>0</h5>
                                </div>
                                <div className='total-box border-bottom-gray'>
                                  <h5>{lang.ko.total_bonus}</h5>
                                  <h5>0</h5>
                                </div>
                                <div className='total-box'>
                                  <h5>{lang.ko.total_commission}</h5>
                                  <h5>0</h5>
                                </div>
                              </div>
                            </Col>

                          </Row>
                        }
                      </div>
                    </div>
                    <div className={isActive.table === 'settings' ? `settings` : 'settings d-none'}>
                      <div className='acount-security-table'>
                        <table className='table p-lb-table table-borderless'>
                          <tbody>
                            <tr>
                              <td>{lang.ko.sound_settings}</td>
                              <td><input type="checkbox" checked={true}></input></td>
                            </tr>
                            <tr>
                              <td>{lang.ko.vibration_settings}</td>
                              <td><input type="checkbox" checked={true}></input></td>
                            </tr>
                            <tr>
                              <td>{lang.ko.push_settings}</td>
                              <td><input type="checkbox" checked={true}></input></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    
                    <div className={isActive.table === 'allTra' ? `allTra` : 'allTra d-none'}>
                        <div className='cahsierForm'>
                          <div className='d-flex gap-2 login_header'>
                            <h6 className={isActiveHistory.table === 'DepositHistory' ? 'active' : ''} id="DepositHistory" onClick={(e) => { hideShowHandTab(e.target.id,1) }}>{lang.ko.deposit_history}</h6>
                            <h6 className={isActiveHistory.table === 'CashHistory' ? 'active' : ''} id="CashHistory" onClick={(e) => { hideShowHandTab(e.target.id,1) }}>{lang.ko.cash_out_history}</h6>
                            {/*<h6 className={isActiveHistory.table === 'BuyHourHistory' ? 'active' : ''} id="BuyHourHistory" onClick={(e) => { hideShowHandTab(e.target.id) }}>{lang.ko.buy_hours_history}</h6>*/}
                          </div>
                          {isActiveHistory.table === 'CashHistory' ?
                            <div>
                              <table className='table p-lb-table table-borderless'>
                                <thead>
                                  <tr>
                                    <th>{lang.ko.sr_no}</th>
                                    <th>{lang.ko.date_time}</th>
                                    {/*<th>{lang.ko.refrerence_no}</th>*/}
                                    <th>{lang.ko.type}</th>
                                    <th>{lang.ko.status}</th>
                                    <th>{lang.ko.amount}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {/*<tr>
                                    <td>1</td>
                                    <td>10-05-2023 3:45 pm</td>
                                    <td>RN15412198425</td>
                                    <td>Card Payment</td>
                                    <td>Success</td>
                                    <td>500</td>
                                  </tr>
                                  <tr>
                                    <td>2</td>
                                    <td>10-05-2023 3:45 pm</td>
                                    <td>RN15412135412</td>
                                    <td>Crypto Payment</td>
                                    <td>Success</td>
                                    <td>1000</td>
                                  </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>10-05-2023 3:45 pm</td>
                                    <td>RN1541284512</td>
                                    <td>Cash Payment</td>
                                    <td>Success</td>
                                    <td>5000</td>
                                  </tr>*/}
                                   {activeHistoryList.length > 0 ? activeHistoryList.map((history, index) => {
                                   return (
                                       <tr>
                                        <td className="wdAmnt">{page > 1 ? (((page - 1) * 25) + (index + 1)) : (index + 1)}</td>
                                        <td>{moment(history.dateTime).format("YY/MM/DD HH:mm  ")}</td>
                                        <td>출금</td>
                                        <td>{historyTabStatus(history?.status)}</td>
                                        <td>{history?.withdrawAmount ? Number(history.withdrawAmount).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}</td>
                                      </tr>
                                      );
                                   }) : <tr>
                                <td colSpan="7">No Game Found</td>
                              </tr>}
                                </tbody>
                              </table>
                              <div className="historyPagination">
                                <PaginationControl
                                  page={page}
                                  between={2}
                                  total={totalCashHist}
                                  limit={25}
                                  changePage={(page) => {
                                    changePage(page);
                                  }}
                                  ellipsis={1}
                                />
                              </div>
                            </div>
                            : isActiveHistory.table === 'BuyHourHistory' ?
                              <div>
                                <table className='table p-lb-table table-borderless'>
                                  <thead>
                                    <tr>
                                      <th>{lang.ko.sr_no}</th>
                                      <th>{lang.ko.date_time}</th>
                                      <th>{lang.ko.refrerence_no}</th>
                                      <th>{lang.ko.type}</th>
                                      <th>{lang.ko.status}</th>
                                      <th>{lang.ko.hours}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>1</td>
                                      <td>10-05-2023 3:45 pm</td>
                                      <td>RN15412198425</td>
                                      <td>Card Payment</td>
                                      <td>Success</td>
                                      <td>2</td>
                                    </tr>
                                    <tr>
                                      <td>2</td>
                                      <td>10-05-2023 3:45 pm</td>
                                      <td>RN15412135412</td>
                                      <td>Crypto Payment</td>
                                      <td>Success</td>
                                      <td>10</td>
                                    </tr>
                                    <tr>
                                      <td>3</td>
                                      <td>10-05-2023 3:45 pm</td>
                                      <td>RN1541284512</td>
                                      <td>Cash Payment</td>
                                      <td>Success</td>
                                      <td>20</td>
                                    </tr>
                                    <tr>
                                      <td>4</td>
                                      <td>10-05-2023 3:45 pm</td>
                                      <td>RN1541284512</td>
                                      <td>Cash Payment</td>
                                      <td>Success</td>
                                      <td>2</td>
                                    </tr>
                                    <tr>
                                      <td>5</td>
                                      <td>10-05-2023 3:45 pm</td>
                                      <td>RN1541284512</td>
                                      <td>Cash Payment</td>
                                      <td>Success</td>
                                      <td>5</td>
                                    </tr>
                                    <tr>
                                      <td>6</td>
                                      <td>10-05-2023 3:45 pm</td>
                                      <td>RN1541284512</td>
                                      <td>Cash Payment</td>
                                      <td>Success</td>
                                      <td>20</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              : <div>
                                <table className='table p-lb-table table-borderless'>
                                  <thead>
                                    <tr>
                                      <th>{lang.ko.sr_no}</th>
                                      <th>{lang.ko.date_time}</th>
                                      {/*<th>{lang.ko.refrerence_no}</th>*/}
                                      <th>{lang.ko.type}</th>
                                      <th>{lang.ko.status}</th>
                                      <th>{lang.ko.amount}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {/*<tr>
                                      <td>1</td>
                                      <td>1-05-2023 1:00 pm</td>
                                      <td>RN15498745125</td>
                                      <td>Card Payment</td>
                                      <td>Success</td>
                                      <td>1000</td>
                                    </tr>
                                    <tr>
                                      <td>2</td>
                                      <td>18-05-2023 2:45 pm</td>
                                      <td>RN154129451251</td>
                                      <td>Card Payment</td>
                                      <td>Success</td>
                                      <td>500</td>
                                    </tr>*/}
                                    {activeHistoryList.length > 0 ? activeHistoryList.map((history, index) => {
                                   return (
                                       <tr>
                                        <td className="dpAmnt">{page > 1 ? (((page - 1) * 25) + (index + 1)) : (index + 1)}</td>
                                        <td>{moment(history.dateTime).format("YY/MM/DD HH:mm  ")}</td>
                                        <td>입금</td>
                                        <td>{historyTabStatus(history?.status)}</td>
                                        <td>{history?.depositAmount ? Number(history.depositAmount).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}</td>
                                      </tr>
                                      );
                                   }) : <tr>
                                <td colSpan="7">No Game Found</td>
                              </tr>}
                                  </tbody>
                                </table>
                                <div className="historyPagination">
                                <PaginationControl
                                  page={page}
                                  between={2}
                                  total={totalDepoHist}
                                  limit={25}
                                  changePage={(page) => {
                                    changePage(page);
                                  }}
                                  ellipsis={1}
                                />
                              </div>
                              </div>}
                        </div>
                      </div>
                    
                    <div className={isActive.table === 'tableTheme' ? `tableTheme` : 'tableTheme d-none'}>
                      <div className='table-theme-block'>
                        {/*
                        <div className={`table-theme-design table-bg-${tableThemeBackgroundColor} ${mobile ? 'mobile' : 'desktop'}`}>
                        */}
                        <div className={`table-theme-design table-bg-default ${mobile ? 'mobile' : 'desktop'}`}>
                          <div className='table-theme-game-area'>
                            <div className='table-shadow skin-section'></div>
                            <div className='table-border skin-section'></div>
                            {/*
                            <div className={`table-${tableThemeSkinColor} skin-section`}></div>
                            */}
                            <div className="table-default skin-section"></div>
                          </div>
                        </div>
                        <div className='table-theme-setting'>
                          <div className='d-flex gap-2 table-theme-setting-header'>
                            <h6 className={tableSettingTab === 'feltColor' ? 'active' : ''} id="feltColor" onClick={(e) => { seTableSettingTab(e.target.id) }} style={{display:'none'}}>{lang.ko.felt_color}</h6>
                            <h6 className={tableSettingTab === 'background' ? 'active' : ''} id="background" onClick={(e) => { seTableSettingTab(e.target.id) }} style={{display:'none'}}>{lang.ko.background}</h6>
                            <h6 className={tableSettingTab === 'frontDeck' ? 'active' : ''} id="frontDeck" onClick={(e) => { seTableSettingTab(e.target.id) }}>{lang.ko.front_deck}</h6>
                            <h6 className={tableSettingTab === 'backDeck' ? 'active' : ''} id="backDeck" onClick={(e) => { seTableSettingTab(e.target.id) }}>{lang.ko.back_deck}</h6>
                            {/* <h6 className={tableSettingTab === 'frontDeck' ? 'active' : ''} id="frontDeck" >FRONT DECK</h6> */}
                            {/* <h6 className={tableSettingTab === 'backDeck' ? 'active' : ''} id="backDeck" >BACK DECK</h6> */}
                          </div>

                          {tableSettingTab === 'feltColor' ?
                            <div className='table-setting-action-footer'>
                              <button className={tableThemeSkinColor === 'default' ? "table-setting-btn active " : "table-setting-btn"} onClick={() => setTableThemeSkinColor('default')}><div className='btn-box green-btn'></div></button>
                              <button className={tableThemeSkinColor === 'red' ? "table-setting-btn active " : "table-setting-btn"} onClick={() => setTableThemeSkinColor('red')}><div className='btn-box red-btn'></div></button>
                              {/* <button className={tableThemeSkinColor === 'gray' ? "table-setting-btn active " : "table-setting-btn"} onClick={() => setTableThemeSkinColor('gray')}><div className='btn-box gray-btn'></div></button> */}
                              <button className={tableThemeSkinColor === 'purpal' ? "table-setting-btn active " : "table-setting-btn"} onClick={() => setTableThemeSkinColor('purpal')}><div className='btn-box purpal-btn'></div></button>
                              {/* <button className={tableThemeSkinColor === 'gold' ? "table-setting-btn active " : "table-setting-btn"} onClick={() => setTableThemeSkinColor('gold')}><div className='btn-box gold-btn'></div></button> */}
                              <button className={tableThemeSkinColor === 'blue' ? "table-setting-btn active " : "table-setting-btn"} onClick={() => setTableThemeSkinColor('blue')}><div className='btn-box blue-btn'></div></button>
                            </div>
                            : tableSettingTab === 'background' ?
                              <div className='table-setting-action-footer'>
                                {/* <button className={tableThemeBackgroundColor === 'green' ? "table-setting-btn active " : "table-setting-btn"} onClick={() => setTableThemeBackgroundColor('green')}><div className='btn-box default-btn'></div></button> */}
                                <button className={tableThemeBackgroundColor === 'default' ? "table-setting-btn active " : "table-setting-btn"} onClick={() => setTableThemeBackgroundColor('default')}><div className='btn-box red-btn red-bg'></div></button>
                                {/* <button className={tableThemeBackgroundColor === 'default' ? "table-setting-btn active " : "table-setting-btn"} onClick={() => setTableThemeBackgroundColor('default')}><div className='btn-box gray-btn'></div></button> */}
                                <button className={tableThemeBackgroundColor === 'purpal' ? "table-setting-btn active " : "table-setting-btn"} onClick={() => setTableThemeBackgroundColor('purpal')}><div className='btn-box purpal-btn violet-bg'></div></button>
                                <button className={tableThemeBackgroundColor === 'gold' ? "table-setting-btn active " : "table-setting-btn"} onClick={() => setTableThemeBackgroundColor('gold')}><div className='btn-box gold-btn brown-bg'></div></button>
                                <button className={tableThemeBackgroundColor === 'blue' ? "table-setting-btn active " : "table-setting-btn"} onClick={() => setTableThemeBackgroundColor('blue')}><div className='btn-box blue-btn blue-bg'></div></button>
                                <button className={tableThemeBackgroundColor === 'gray' ? "table-setting-btn active " : "table-setting-btn"} onClick={() => setTableThemeBackgroundColor('gray')}><div className='btn-box gray-btn gray-bg'></div></button>
                              </div>
                              : tableSettingTab === 'frontDeck' ?
                                <div class="scroll mt-0">
                                  <div class="table-setting-container">
                                    <div class="table-setting-scroll">
                                      <button className={tableThemeFrontDeck === 'default' ? "box active " : "box"} onClick={() => setTableThemeFrontDeck('default')}>
                                        <img src="../Images/Deck/Front/default/TD.png"></img>
                                        <img src="../Images/Deck/Front/default/QC.png"></img>
                                        <img src="../Images/Deck/Front/default/KH.png"></img>
                                        <img src="../Images/Deck/Front/default/AS.png"></img>
                                      </button>
                                      <button className={tableThemeFrontDeck === 'premium' ? "box active " : "box"} onClick={() => setTableThemeFrontDeck('premium')}>
                                        <img src="../Images/Deck/Front/premium/TD.png?v=001"></img>
                                        <img src="../Images/Deck/Front/premium/QC.png?v=001"></img>
                                        <img src="../Images/Deck/Front/premium/KH.png?v=001"></img>
                                        <img src="../Images/Deck/Front/premium/AS.png?v=001"></img>
                                      </button>
                                      <button className={tableThemeFrontDeck === 'gold' ? "box active " : "box"} onClick={() => setTableThemeFrontDeck('gold')}>
                                        <img src="../Images/Deck/Front/gold/TD.png"></img>
                                        <img src="../Images/Deck/Front/gold/QC.png"></img>
                                        <img src="../Images/Deck/Front/gold/KH.png"></img>
                                        <img src="../Images/Deck/Front/gold/AS.png"></img>
                                      </button>
                                      <button className={tableThemeFrontDeck === 'silver' ? "box active " : "box"} onClick={() => setTableThemeFrontDeck('silver')}>
                                        <img src="../Images/Deck/Front/silver/TD.png"></img>
                                        <img src="../Images/Deck/Front/silver/QC.png"></img>
                                        <img src="../Images/Deck/Front/silver/KH.png"></img>
                                        <img src="../Images/Deck/Front/silver/AS.png"></img>
                                      </button>
                                      <button className={tableThemeFrontDeck === 'classic' ? "box active " : "box"} onClick={() => setTableThemeFrontDeck('classic')}>
                                        <img src="../Images/Deck/Front/classic/TD.png"></img>
                                        <img src="../Images/Deck/Front/classic/QC.png"></img>
                                        <img src="../Images/Deck/Front/classic/KH.png"></img>
                                        <img src="../Images/Deck/Front/classic/AS.png"></img>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                : tableSettingTab === 'backDeck' ?
                                  <div class="scroll mt-0">
                                    <div class="table-setting-container">
                                      <div class="table-setting-scroll">
                                        <button className={tableThemeBackDeck === 'default' ? "box active " : "box"} onClick={() => setTableThemeBackDeck('default')}>
                                          <img src="../Images/Deck/Back/default.png"></img>
                                        </button>
                                        <button className={tableThemeBackDeck === 'two' ? "box active " : "box"} onClick={() => setTableThemeBackDeck('two')}>
                                          <img src="../Images/Deck/Back/two.png"></img>
                                        </button>
                                        <button className={tableThemeBackDeck === 'three' ? "box active " : "box"} onClick={() => setTableThemeBackDeck('three')}>
                                          <img src="../Images/Deck/Back/three.png"></img>
                                        </button>
                                        <button className={tableThemeBackDeck === 'four' ? "box active " : "box"} onClick={() => setTableThemeBackDeck('four')}>
                                          <img src="../Images/Deck/Back/four.png"></img>
                                        </button>
                                        <button className={tableThemeBackDeck === 'five' ? "box active " : "box"} onClick={() => setTableThemeBackDeck('five')}>
                                          <img src="../Images/Deck/Back/five.png"></img>
                                        </button>
                                        <button className={tableThemeBackDeck === 'six' ? "box active " : "box"} onClick={() => setTableThemeBackDeck('six')}>
                                          <img src="../Images/Deck/Back/six.png"></img>
                                        </button>
                                        <button className={tableThemeBackDeck === 'seven' ? "box active " : "box"} onClick={() => setTableThemeBackDeck('seven')}>
                                          <img src="../Images/Deck/Back/seven.png"></img>
                                        </button>
                                        <button className={tableThemeBackDeck === 'eight' ? "box active " : "box"} onClick={() => setTableThemeBackDeck('eight')}>
                                          <img src="../Images/Deck/Back/eight.png"></img>
                                        </button>
                                        <button className={tableThemeBackDeck === 'nine' ? "box active " : "box"} onClick={() => setTableThemeBackDeck('nine')}>
                                          <img src="../Images/Deck/Back/nine.png"></img>
                                        </button>
                                        <button className={tableThemeBackDeck === 'ten' ? "box active " : "box"} onClick={() => setTableThemeBackDeck('ten')}>
                                          <img src="../Images/Deck/Back/ten.png"></img>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  : ''}

                          <div className='avtarBtnSection text-center'>
                            {/* <button type="button" className='defaultBtn' onClick={() => changePassword(1)}>Cancel</button> */}
                            <button type="button" className='edtAvtarBtn confirm-btn rounded-btn px-5' onClick={() => saveTableThemeSetting()}>{lang.ko.save}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={isActive.table === 'jackpotHistory' ? `jackpotHistory` : 'jackpotHistory d-none'}>
                      <div className='playerProfileInfo'>
                          <table className='table p-lb-table table-borderless'>
                            <thead>
                              <tr>
                                <th width="10%">{lang.ko.date_time}</th>
                                <th width="20%">{lang.ko.tables}</th>
                                <th width="20%">{lang.ko.players}</th>
                                <th width="20%">{lang.ko.jackpot_amount}</th>
                                <th width="30%">{lang.ko.hands}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {jackpotHistoryList && jackpotHistoryList.jackpotHistory && jackpotHistoryList.jackpotHistory.length > 0 ? jackpotHistoryList.jackpotHistory.map((history, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{moment(history.datetime).format("HH:mm YY/MM/DD ")}</td>
                                    <td>{history.roomName}</td>
                                    <td>{history.userName}</td>
                                    <td>{history.amount}</td>
                                    <td>{lang.ko[history.handName]}</td>
                                  </tr>
                                );
                              }) : <tr>
                                <td colSpan="5">No Jackpot Found</td>
                              </tr>}
                            </tbody>
                          </table>
                          <div className="historyPagination">
                            <PaginationControl
                              page={page}
                              between={0}
                              total={jackpotHistoryList.recordsTotal}
                              limit={jackpotHistoryList.resultPerPage}
                              changePage={(page) => {
                                changePage(page);
                              }}
                              ellipsis={1}
                            />
                          </div>
                        </div>
                    </div>
                    <div className={isActive.table === 'handHistory' ? `handHistory` : 'handHistory d-none'}>
                      {isGameHistory ?
                        <div className='gameHistoryData'>
                          <div className='playerProfileInfo pt-0'>
                            <Row>
                              <Col lg={12}>
                                <div className='d-flex gap-2 login_header no_border m-0 mb-2'>
                                  <h6 className='active goback-btn' onClick={() => setIsGameHistory(false)}>뒤로가기</h6>
                                </div>
                                <div className='cashier-box'>
                                  <Row>
                                    <Col lg={6}>
                                      <div className='mb-1'>
                                        <label>{lang.ko.game_name}</label>
                                        <p className='profileDetails'>{gameHistory.game?.tableName}</p>
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className='mb-1'>
                                        <label>Game ID</label>
                                        <p className='profileDetails'>{gameHistory.gameName}</p>
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className='mb-1'>
                                        <label>{lang.ko.stakes}</label>
                                        <p className='profileDetails'>{gameHistory.stack}</p>
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className='mb-1'>
                                        <label>{lang.ko.date_time}</label>
                                        <p className='profileDetails'>{moment(gameHistory.dataTime).format("DD MMM, YYYY hh:mm A")}</p>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              <Col lg={12}>
                                <div className={mobile ? "mobileView" : ''}>
                                  <div className='d-flex gap-2 login_header'>
                                    <h6 className={isActiveHand.table === 'gameplayer' ? 'active' : ''} id="gameplayer" onClick={(e) => { hideShowHandTab(e.target.id) }}>{lang.ko.players}</h6>
                                    {gameHistory?.winners?.length > 0 ? <h6 className={isActiveHand.table === 'winner' ? 'active' : ''} id="winner" onClick={(e) => { hideShowHandTab(e.target.id) }}>Winners</h6> : ''}
                                    {preFlop?.length > 0 ? <h6 className={isActiveHand.table === 'preflop' ? 'active' : ''} id="preflop" onClick={(e) => { hideShowHandTab(e.target.id) }}>Pre Flop</h6> : ''}
                                    {flop?.length > 0 ? <h6 className={isActiveHand.table === 'flop' ? 'active' : ''} id="flop" onClick={(e) => { hideShowHandTab(e.target.id) }}>Flop</h6> : ''}
                                    {turn?.length > 0 ? <h6 className={isActiveHand.table === 'turn' ? 'active' : ''} id="turn" onClick={(e) => { hideShowHandTab(e.target.id) }}>Turn</h6> : ''}
                                    {river?.length > 0 ? <h6 className={isActiveHand.table === 'river' ? 'active' : ''} id="river" onClick={(e) => { hideShowHandTab(e.target.id) }}>River</h6> : ''}
                                    {showDown?.length > 0 ? <h6 className={isActiveHand.table === 'showdown' ? 'active' : ''} id="showdown" onClick={(e) => { hideShowHandTab(e.target.id) }}>Showdown</h6> : ''}
                                  </div>
                                  <div className='cashier-box'>
                                    <div className='theme-bg'>
                                      <div className={isActiveHand.table === 'winner' ? `winner` : 'winner d-none'}>
                                        {gameHistory?.winners?.length > 0 ?
                                          <div>
                                            <h4 className='gameNameTitle gameDateilsTitle'>Winners</h4>
                                            <table className='table p-lb-table table-borderless'>
                                              <thead>
                                                <tr>
                                                  <th>{lang.ko.player}</th>
                                                  <th>{lang.ko.winning_amount}</th>
                                                  <th>{lang.ko.best_cards}</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {gameHistory?.winners?.map((winner, index) => {
                                                  return (
                                                    <tr key={index}>
                                                      <td className='histPlayer'>{winner.playerName}</td>
                                                      <td>{winner.winningAmount ? Number(winner.winningAmount).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}</td>
                                                      <td className='d-flex histCard-wrap'>
                                                        {winner.winningHands.length > 0 ? winner.winningHands.map((card, k) => {
                                                          return (
                                                            <img key={k} src={'../Images/Card/' + card + '.png'} className='historyCard cardlst'></img>
                                                          )
                                                        }) : ''}</td>
                                                    </tr>
                                                  );
                                                })}
                                              </tbody>
                                            </table>
                                          </div>
                                          : ''}
                                      </div>
                                      <div className={isActiveHand.table === 'gameplayer' ? `gameplayer` : 'gameplayer d-none'}>
                                        <h4 className='gameNameTitle gameDateilsTitle'>Players List</h4>
                                        <table className='table p-lb-table table-borderless'>
                                          <thead>
                                            <tr>
                                              <th>{lang.ko.player}</th>
                                              <th>Cards</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {gameHistory?.players?.length > 0 ? gameHistory?.players?.map((plr, index) => {
                                              return (
                                                <tr key={index}>
                                                  <td className='histPlayer'>{plr.playerName}</td>
                                                  <td className='d-flex histCard-wrap'>
                                                    {plr.cards.length > 0 ? plr.cards.map((card, k) => {
                                                      return (
                                                        <img key={k} src={'../Images/Card/' + card + '.png'} className='historyCard cardlst'></img>
                                                      )
                                                    }) : ''}</td>
                                                </tr>
                                              );
                                            }) : <tr>
                                              <td colSpan="7">No Player Found</td>
                                            </tr>}
                                          </tbody>
                                        </table>
                                      </div>
                                      <div className={isActiveHand.table === 'preflop' ? `preflop` : 'preflop d-none'}>
                                        {preFlop?.length > 0 ?
                                          <div>
                                            <h4 className='gameNameTitle gameDateilsTitle'>Hand Event - Pre Flop</h4>
                                            <table className='table p-lb-table table-borderless'>
                                              <thead>
                                                <tr>
                                                  <th>{lang.ko.player}</th>
                                                  <th>{lang.ko.action}</th>
                                                  <th>{lang.ko.chips}</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {preFlop.map((data, index) => {
                                                  return (
                                                    <tr key={index}>
                                                      <td style={{ width: '40%' }}>{data.playerName}</td>
                                                      <td style={{ width: '30%' }}>{playerActionArr(data.playerAction)}</td>
                                                      <td style={{ width: '30%' }}>{data.betAmount ? Number(data.betAmount).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}</td>
                                                      {/*<td style={{ width: '30%' }}>{data.betAmount > 0 ? data.betAmount.toFixed(0) : ''}</td>*/}
                                                    </tr>
                                                  );
                                                })}
                                              </tbody>
                                            </table>
                                          </div>
                                          : ''}
                                      </div>
                                      <div className={isActiveHand.table === 'flop' ? `flop` : 'flop d-none'}>
                                        {flop?.length > 0 ?
                                          <div>
                                            <h4 className='gameNameTitle gameDateilsTitle'>Hand Event - Flop

                                            </h4>
                                            <table className='table p-lb-table table-borderless'>
                                              <tbody>
                                                {gameHistory?.tableCards?.Flop.length > 0 ?
                                                  <tr>
                                                    <td className='flopTitle' style={{ width: '40%' }}>Flop</td>
                                                    <td style={{ width: '30%' }}>
                                                      {gameHistory?.tableCards?.Flop.map((card) => {
                                                        return (
                                                          <img className='historyCard' src={'../Images/Card/' + card + '.png'}></img>
                                                        )
                                                      })
                                                      }
                                                    </td>
                                                    <td style={{ width: '30%' }}></td>
                                                  </tr>
                                                  : ''}
                                                {flop.map((data, index) => {
                                                  return (
                                                    <tr key={index}>
                                                      <td style={{ width: '40%' }}>{data.playerName}</td>
                                                      <td style={{ width: '30%' }}>{playerActionArr(data.playerAction)}</td>
                                                      <td style={{ width: '30%' }}>{data.betAmount ? Number(data.betAmount).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}</td>
                                                      {/*<td style={{ width: '30%' }}>{data.betAmount > 0 ? data.betAmount.toFixed(0) : ''}</td>*/}
                                                    </tr>
                                                  );
                                                })}
                                              </tbody>
                                            </table>
                                          </div>
                                          : ''}
                                      </div>
                                      <div className={isActiveHand.table === 'turn' ? `turn` : 'turn d-none'}>
                                        {turn?.length > 0 ?
                                          <div>
                                            <h4 className='gameNameTitle gameDateilsTitle'>Hand Event - Turn</h4>
                                            <table className='table p-lb-table table-borderless'>
                                              <tbody>
                                                {gameHistory?.tableCards?.Turn.length > 0 ?
                                                  <tr >
                                                    <td style={{ width: '40%' }}>Turn</td>
                                                    <td style={{ width: '30%' }}>
                                                      {gameHistory?.tableCards?.Turn.map((card) => {
                                                        return (
                                                          <img className='historyCard' src={'../Images/Card/' + card + '.png'}></img>
                                                        )
                                                      })
                                                      }
                                                    </td>
                                                    <td style={{ width: '30%' }}></td>
                                                  </tr>
                                                  : ''}
                                                {turn.map((data, index) => {
                                                  return (
                                                    <tr key={index}>
                                                      <td style={{ width: '40%' }}>{data.playerName}</td>
                                                      <td style={{ width: '30%' }}>{playerActionArr(data.playerAction)}</td>
                                                      <td style={{ width: '30%' }}>{data.betAmount ? Number(data.betAmount).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}</td>
                                                      {/*<td style={{ width: '30%' }}>{data.betAmount > 0 ? data.betAmount.toFixed(0) : ''}</td>*/}
                                                    </tr>
                                                  );
                                                })}
                                              </tbody>
                                            </table>
                                          </div>
                                          : ''}
                                      </div>
                                      <div className={isActiveHand.table === 'river' ? `river` : 'river d-none'}>
                                        {river?.length > 0 ?
                                          <div>
                                            <h4 className='gameNameTitle gameDateilsTitle'>Hand Event - River</h4>
                                            <table className='table p-lb-table table-borderless'>
                                              <tbody>
                                                {gameHistory?.tableCards?.River.length > 0 ?
                                                  <tr >
                                                    <td style={{ width: '40%' }}>River</td>
                                                    <td style={{ width: '30%' }}>
                                                      {gameHistory?.tableCards?.River.map((card) => {
                                                        return (
                                                          <img className='historyCard' src={'../Images/Card/' + card + '.png'}></img>
                                                        )
                                                      })
                                                      }
                                                    </td>
                                                    <td style={{ width: '30%' }}></td>
                                                  </tr>
                                                  : ''}
                                                {river.map((data, index) => {
                                                  return (
                                                    <tr key={index}>
                                                      <td style={{ width: '40%' }}>{data.playerName}</td>
                                                      <td style={{ width: '30%' }}>{playerActionArr(data.playerAction)}</td>
                                                      <td style={{ width: '30%' }}>{data.betAmount ? Number(data.betAmount).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}</td>
                                                      {/*<td style={{ width: '30%' }}>{data.betAmount > 0 ? data.betAmount.toFixed(0) : ''}</td>*/}
                                                    </tr>
                                                  );
                                                })}
                                              </tbody>
                                            </table>
                                          </div>
                                          : ''}
                                      </div>
                                      <div className={isActiveHand.table === 'showdown' ? `showdown` : 'showdown d-none'}>
                                        {showDown?.length > 0 ?
                                          <div>
                                            <h4 className='gameNameTitle gameDateilsTitle'>Hand Event - Show Down</h4>
                                            <table className='table p-lb-table table-borderless'>
                                              <thead>
                                                <tr>
                                                  <th>{lang.ko.player}</th>
                                                  <th>{lang.ko.action}</th>
                                                  <th>{lang.ko.chips}</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {showDown.map((data, index) => {
                                                  return (
                                                    <tr key={index}>
                                                      <td style={{ width: '40%' }}>{data.playerName}</td>
                                                      <td style={{ width: '30%' }}>{playerActionArr(data.playerAction)}</td>
                                                      <td style={{ width: '30%' }}>{data.betAmount ? Number(data.betAmount).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}</td>
                                                      {/*<td style={{ width: '30%' }}>{data.betAmount > 0 ? data.betAmount.toFixed(0) : ''}</td>*/}
                                                    </tr>
                                                  );
                                                })}
                                              </tbody>
                                            </table>
                                          </div>
                                          : ''}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        :
                        <div className='playerProfileInfo'>
                          <table className='table p-lb-table table-borderless'>
                            <thead>
                              <tr>
                                <th width="10%">{lang.ko.date_time}</th>
                                <th width="20%">{lang.ko.game_name}</th>
                                <th width="20%">{lang.ko.winning_amount}</th>
                                {mobile ? <th width="20%">{lang.ko.hands}</th> : <th width="10%">{lang.ko.hands}</th> }
                                {mobile ?
                                  ''
                                  : <th width="50%">{lang.ko.best_cards}</th>}
                              </tr>
                            </thead>
                            <tbody>
                              {gameHistoryList && gameHistoryList.gamesHistoryList && gameHistoryList.gamesHistoryList.length > 0 ? gameHistoryList.gamesHistoryList.map((history, index) => {
                                return (
                                  <tr key={index} onClick={() => openGameHistory(history.gameId)}>
                                    <td>{moment(history.dateTime).format("HH:mm, YY/MM/DD ")}</td>
                                    <td>{history.game?.tableName}</td>
                                    <td>{history.winner?.winningAmount ? Number(history.winner?.winningAmount).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}</td>
                                    {/*<td>{history.winner?.winningAmount}</td>*/}
                                    <td className='histCard-wrap'>
                                      {history.handCards.length > 0 ? history.handCards.map((card, k) => {
                                        return (
                                          <img key={k} src={'../Images/Card/' + card + '.png'} className='historyCard cardlst'></img>
                                        )
                                      }) : ''}</td>
                                    {mobile ?
                                      ''
                                      :
                                      <td>
                                        {history.winner?.winningHands?.length > 0 ? history.winner.winningHands.map((winningCard, k) => {
                                          return (
                                            <img key={k} src={'../Images/Card/' + winningCard + '.png'} className='historyCard cardlst'></img>
                                          )
                                        }) : ''}</td>
                                    }
                                  </tr>
                                );
                              }) : <tr>
                                <td colSpan="7">No Game Found</td>
                              </tr>}
                            </tbody>
                          </table>
                          <div className="historyPagination">
                            <PaginationControl
                              page={page}
                              between={0}
                              total={gameHistoryList.recordsTotal}
                              limit={gameHistoryList.resultPerPage}
                              changePage={(page) => {
                                changePage(page);
                              }}
                              ellipsis={1}
                            />
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal >

      <Modal
        isOpen={isLogoutModalOpen}
        onRequestClose={closeLogoutModal}
        style={customStylesLogout}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className={mobile ? "gameModel mobileView" : 'gameModel'}>
          <div className='modalBody logout-modal'>
            <center>
              <h2 className='color-1 logout-title'>{lang.ko.warning}</h2>
              <h4 className='color-1 mt-4 logout-msg'>{lang.ko.alert_msg_leave_game}</h4>
              <div className='avtarBtnSection'>
                <button className='selectBuyInBtn logout-btn' onClick={() => logout()}>{lang.ko.yes}</button>
                <button className='selectBuyInBtn logout-btn' onClick={() => closeLogoutModal()}>{lang.ko.no}</button>
              </div>
            </center>
          </div>
        </div>
      </Modal>
    </div >
  );
};

export default MyAccount

