import React from 'react'
import { useState, useEffect, useRef } from 'react'
import '../Css/Game.css';
import '../Css/GameMain.css';
import '../Css/GameMobile.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCookies } from 'react-cookie';
import * as io from 'socket.io-client';
import Modal from 'react-modal';
import { useNavigate, useParams } from "react-router-dom";
import useSound from 'use-sound';
import chipsMove from '../Audio/call.wav';
import chip1000 from '../Audio/chip_1000.mp3';
import chip10000 from '../Audio/chip_10000.mp3';
import chip100000 from '../Audio/chip_100000.mp3';
import chip1000000 from '../Audio/chip_1000000.mp3';

import collect from '../Audio/collect.wav';
import foldCard from '../Audio/fold.mp3';
import cardDealt from '../Audio/deal_cards.wav';
import checkNew from '../Audio/checkTurn.mp3';
import myTurn from '../Audio/my_turn.wav';
import winner from '../Audio/win_sound.mp3';
import card_shuffle from '../Audio/card_shuffle.wav';

import allinsnd from '../Audio/allin.mp3';
import betsnd from '../Audio/bet.mp3';
import callsnd from '../Audio/call.mp3';
import checksnd from '../Audio/check.mp3';
import halfsnd from '../Audio/half.mp3';
import quartersnd from '../Audio/quarter.mp3';
import fullsnd from '../Audio/full.mp3';
import raisesnd from '../Audio/raise.mp3';
import timersnd from '../Audio/timer.mp3'

import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { Row, Col } from 'react-bootstrap'
import socketMain from '../socket';
import TableSetting from '../Components/TableSetting';
import HandHistory from '../Components/HandHistory';
import Jackpot from '../Components/Jackpot';
import ImageLoader from "../Components/ImageLoader";
const lang = require('../Config/lang.js');


const domainName = window.location.hostname;
  
const isIncluded = domainName.includes("testholdem.smgame77.com");

let BackendURL;
if(isIncluded) {
    BackendURL = "http://ec2-43-207-107-237.ap-northeast-1.compute.amazonaws.com:8080/";
} else {
    BackendURL = "http://ec2-13-231-89-219.ap-northeast-1.compute.amazonaws.com:8080/";
}
//const BackendURL = "http://ec2-13-231-89-219.ap-northeast-1.compute.amazonaws.com:8080/";

const GameScreen = () => {
    const containerRef = useRef(null);
    const [isTimer, setIsTimer] = useState(false);
    const [voice, setVoice] = useState(true);
    const [chipsVoice] = useSound(chipsMove);
    const [chip1000Voice] = useSound(chip1000);
    const [chip10000Voice] = useSound(chip10000);
    const [chip100000Voice] = useSound(chip100000);
    const [chip1000000Voice] = useSound(chip1000000);
    const [foldCardVoice] = useSound(foldCard);
    const [cardDealtVoice] = useSound(cardDealt);
    const [checkActionVoice] = useSound(checkNew);
    const [myTurnVoice] = useSound(myTurn);
    const [winnerVoice] = useSound(winner);
    const [collectVoice] = useSound(collect);
    const [cardShuffleVoice] = useSound(card_shuffle);
    const [allinVoice] = useSound(allinsnd);
    const [betVoice] = useSound(betsnd);
    const [callVoice] = useSound(callsnd);
    const [checkVoice] = useSound(checksnd);
    const [halfVoice] = useSound(halfsnd);
    const [quarterVoice] = useSound(quartersnd);
    const [fullVoice] = useSound(fullsnd);
    const [raiseVoice] = useSound(raisesnd);
    const [timerSnd, { stop }] = useSound(timersnd);
    const [sidebarWidth, setSidebarWidth] = useState({});
    const [infoSidebarWidth, setInfoSidebarWidth] = useState({});
    const [isSettingOpen, setIsSettingOpen] = useState(false);
    const [isHandOpen, setIsHandOpen] = useState(false);
    const [sidebarMobileWidth, setSidebarMobileWidth] = useState({});
    const [userMessage, setUserMessage] = useState('');
    const [openEmojiBox, setOpenEmojiBox] = useState(false);
    const [gameChatMessages, setGameChatMessages] = useState([]);
    const messageEl = useRef(null);
    const [dealerPlayer, setDealerPlayer] = useState('abcd');
    const [isBuyHourModalOpen, setIsBuyHourModalOpen] = useState(false);
    const [isLowBalanceModalOpen, setIsLowBalanceModalOpen] = useState(false);
    const [isCloseLowBalanceModalOpen, setIsCloseLowBalanceModalOpen] = useState(true);
    const [isCloseInsufficientBalanceModalOpen, setIsCloseInsufficientBalanceModalOpen] = useState(true);
    const [isInsufficientBalanceModalOpen, setIsInsufficientBalanceModalOpen] = useState(false);
    const [isRotateScreen, setIsRotateScreen] = useState(false);
    const [socketIo, setSocketIo] = useState('');
    const [socketIoSetting, setSocketIoSetting] = useState('');
    const [gameSocketId, setGameSocketId] = useState('ee');
    const [breakTime, setBreakTime] = useState(0);
    const [socketIoReconnect, setSocketIoReconnect] = useState(0);
    const [socketIoConnect, setSocketIoConnect] = useState(false);
    const [playerData, setPlayerData] = useState(JSON.parse(localStorage.getItem('user')));
    const [tableSeats, setTableSeats] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8]);
    const [emptyTable, setEmptyTable] = useState(true)
    const [playerInfo, setPlayerInfo] = useState([]);
    const [gameStart, setGameStart] = useState(false)
    const [isRevertPointFolded, setIsRevertPointFolded] = useState(false)
    const [gameId, setGameId] = useState("")    
    const [tableData, setTableData] = useState();
    const [minBuyIn, setMinBuyIn] = useState(0);
    const [maxBuyIn, setMaxBuyIn] = useState(100);
    const [start, setStart] = useState(0);
    const [sliderPct, setSliderPct] = useState(0);
    const [raisePct, setRaisePct] = useState(0);
    const [isRoomModalOpen, setIsRoomModalOpen] = useState(false);
    const [seatPosition, setSeatPosition] = useState()
    const [buyIn, setBuyIn] = useState(false)
    const [gameData, setGameData] = useState({});
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)
    const [sendGiftModal, setSendGiftModal] = useState(false)
    const navigate = useNavigate();
    const [roomData, setRoomData] = useState({})
    const [tablePot, setTablePot] = useState(0);
    const [playerCards, setPlayerCards] = useState({});
    const [multiPlayerCards, setMultiPlayerCards] = useState({});
    const [defaultAction, setDefaultAction] = useState('');
    const [isCardView, setIsCardView] = useState(false);
    const [isCardViewPlayer, setIsCardViewPlayer] = useState({});
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const [isRaiseAmount, setIsRaiseAmount] = useState(false);
    const [isOnOpenBuyInPanel, setIsOnOpenBuyInPanel] = useState(false);
    const [playerActionType, setPlayerActionType] = useState('');
    const [playerActionData, setPlayerActionData] = useState(0);
    const [raiseAmount, setRaiseAmount] = useState('');
    const [minRaise, setMinRaise] = useState(-1);
    const [maxRaise, setMaxRaise] = useState(100);
    const [roomOut, setRoomOut] = useState(false);
    const [sitOut, setSitOut] = useState({
        nextBlind: false,
        bigBlind: false
    });
    const [imageUrls, setImageUrls] = useState([]);
    const [isImageUrls, setIsImageUrls] = useState(false);
    const [gameFinishedPlayersCards, setGameFinishedPlayersCards] = useState([]);
    const [gameFinishedCardGameId, setGameFinishedCardGameId] = useState('');
    const [onPlayersCardsDistribution, setOnPlayersCardsDistribution] = useState([]);
    /*
    위너 여러명 처리
    const [winnerSeatIndex, setWinnerSeatIndex] = useState(-1);
    const [winningAmount, setWinningAmount] = useState(-1);
    const [winningType, setWinningType] = useState('');
    */
    const [winnerSeatIndex, setWinnerSeatIndex] = useState({});
    const [winningAmount, setWinningAmount] = useState({});
    const [winningType, setWinningType] = useState({});
    const [bestCard, setBestCard] = useState([]);
    const [idealPlayer, setIdealPlayer] = useState({})
    const [gamePlayData, setGamePlayData] = useState({});
    const [maxTimer, setMaxTimer] = useState(20);
    const [inProgressTimer, setInProgressTimer] = useState(null);
    const [isFooterPanel, setIsFooterPanel] = useState(false);
    const [playerSidePot, setPlayerSidePot] = useState(0);
    const [hiddenCards, sethiddenCards] = useState([]);
    const [tableInfo, settableInfo] = useState([]);
    const [uniqueid, setUniqueid] = useState(null);
    const [gameBootData, setGameBootData] = useState({});
    const [gameGifts, setGameGifts] = useState({});
    const [gameGiftPlayer, setGameGiftPlayer] = useState({});
    const [smallBlindChips, setSmallBlindChips] = useState(0);
    const [displayCard1, setDisplayCard1] = useState(false);
    const [displayCard2, setDisplayCard2] = useState(false);
    const [displayCard3, setDisplayCard3] = useState(false);
    const [displayCard4, setDisplayCard4] = useState(false);
    const [interval, setIntervalId] = useState('');
    const [playerGiftData, setPlayerGiftData] = useState({});
    const [sitDownMsgDisplayed, setSitDownMsgDisplayed] = useState(false);
    //--- 토너먼트 인지 아닌지
    const [isTournament, setIsTournament] = useState(false);
    
    const [isShowdownShown, setIsShowdownShown] = useState(false);
    
    const mobileFontSize = 0;
    // const maxWidth = 1176;
    const maxWidth = 1665;
    // const maxHeight = 698;
    const maxHeight = 770;
    const mobileMaxWidth = 430;
    const mobileMaxHeight = 624;
    const initialFontSize = 18.5;
    const [mobileView, setMobileView] = useState({
        width: '100%',
        height: '100%',
        fontSize: '0',
    });
    const [tableImage, setTableImage] = useState({
        width: `${maxWidth}px`,
        height: `${maxHeight}px`,
        fontSize: `${initialFontSize}px`,
    });
    const [isMobile, setIsMobile] = useState('')
    const [roomType, setRoomType] = useState('')
    const [gameType, setGametype] = useState('')
    const [gameFormat, setGameFormat] = useState('')
    const [playerMultiTable, setPlayerMultiTable] = useState([])
    // const roomType = cookies.roomType;
    // const roomId = cookies.roomId;
    // const gameType = cookies.gameType;
    // const gameFormat = cookies.gameFormat;
    
    //const URL = "http://ec2-13-231-89-219.ap-northeast-1.compute.amazonaws.com:8080/";
    const domainName = window.location.hostname;
  
    const isIncluded = domainName.includes("testholdem.smgame77.com");
    
    
    let URL; 
    if(isIncluded) {
        URL = "https://sctest.poker-hill.com/";
    } else {
        //const URL = "http://ec2-13-231-89-219.ap-northeast-1.compute.amazonaws.com:8080/";
        URL = "https://sc.poker-hill.com/";
    }
    const [isLoader, setIsLoader] = useState(true);
    const [switchLoader, setSwitchLoader] = useState(false);
    const [giftForPlayer, setGiftForPlayer] = useState('');
    const [currentTableId, setCurrentTableId] = useState('');
    const [tableThemeSkinColor, setTableThemeSkinColor] = useState('default');
    const [tableThemeBackgroundColor, setTableThemeBackgroundColor] = useState('default');
    const [tableThemeFrontDeck, setTableThemeFrontDeck] = useState('default');
    const [tableThemeBackDeck, setTableThemeBackDeck] = useState('default');
    const [hourSubscription, setHourSubscription] = useState(false);
    const { tableId } = useParams();
    const [isBroadCastMessage, setIsBroadCastMessage] = useState(false);
    const [broadCastMessage, setBroadCastMessage] = useState({});
    const [isJackpotMessage, setIsJackpotMessage] = useState(false);
    const [jackpotMessage, setJackpotMessage] = useState({});
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isBetRaiseOpen, setIsBetRaiseOpen] = useState(true);
    const [timerCountdown, setTimerCountdown] = useState(null);
    
    const [chatOpen, setchatOpen] = useState(false);
    const notChatOpen = [".24.2.1.34", ".24.2.1.40",".24.2.1.35"];
    
    

    
    const dropdownRef = useRef(null);
    const [initFinishTimeout, setInitFinishTimeout] = useState('');
    const toggleDropdown = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const toggleBetRaise = () => {
        setIsBetRaiseOpen(!isBetRaiseOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsMenuOpen(false);
        }
    };
    
    const truncateRoomName = (rname) => {
      const maxLength = 8;
      let length = 0;
    
      for (let i = 0; i < rname.length; i++) {
        const charCode = rname.charCodeAt(i);
        // Check if the character is a Korean character (Hangul syllables range)
        if (charCode >= 0xAC00 && charCode <= 0xD7AF) {
          length += 2; // Count Korean character as 2
        } else {
          length += 1; // Count other characters as 1
        }
    
        if (length > maxLength) {
          return rname.slice(0, i) + '...';
        }
      }
      return rname;
    };
    
    const roomSettingInit = (room) => {
        if(roomData.room)
        {
            //roomData.room.name = room.roomName;
            //roomData.room.bigBlind = room.bigBlind;
            //roomData.room.smallBlind = room.smallBlind;
            //roomData.room.tableType = room.room.tableType;
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    
    useEffect(()=>{
    },[playerCards])

    // Timer Countdown for sound reminder <= 5sec
    useEffect(() => {
        if (timerCountdown === null || timerCountdown <= 0) {
            stop();
            return;
        }
    
        const timerC = setTimeout(() => {
            setTimerCountdown((prev) => {
                if (prev == 5) {
                    timerSnd(); 
                }
                return prev - 1;
            });
        }, 1000);
    
        return () => clearTimeout(timerC); // Cleanup on unmount or state change
    }, [timerCountdown]);

    useEffect(() => {
        if (!playerData || playerData == null) {
            navigate(`/`);
        }
        setTimeout(() => {
            setIsLoader(false);
        }, 1000)
        const socketUrl = io(URL,{
          transports: ['websocket','polling']
        });
        setSocketIoSetting(socketUrl);
        const data = {
            id: tableId,
            playerId: playerData.playerId,
            productName: 'PokerHills'
        }

        if (socketIo) {
            socketIo.disconnect();
        }

        const socketId = socketUrl.id;
        socketUrl.emit('broadcast-message', { socketId })
        socketUrl.on('broadcast-message', (data) => {
            //console.log('broadcast-message',data)
            //if (data.playerId == playerData.playerId) {
                setIsBroadCastMessage(true);
                setBroadCastMessage(data)
                setTimeout(() => {
                    setIsBroadCastMessage(false)
                    setBroadCastMessage({})
                }, 10000)
            //}
        });
        socketUrl.on('jackpot-message', (data) => {
            data.currentRoomId = tableId
            setIsJackpotMessage(true);
            setJackpotMessage(data)
            setTimeout(() => {
                setIsJackpotMessage(false)
                setJackpotMessage({})
            }, 5000)
        });
        /*
        setTimeout(function(){
            let data ={ 
                currentRoomId : tableId+,
                roomId: tableId,
                roomName : "test",
                playerName: 'test' ,
                jackpotAmount : 100,
                handName : '스트레이트 플러쉬'
            }
            setIsJackpotMessage(true);
            setJackpotMessage(data)
        },5000)
        */

        socketUrl.emit('TableThemeSettings', data, (res) => {
            if (res.status == 'success') {
                setTableThemeSkinColor(res.result.tableTheme)
                setTableThemeBackgroundColor(res.result.tableBackground)
                setTableThemeFrontDeck(res.result.frontDeck)
                setTableThemeBackDeck(res.result.backDeck)
                //console.log('res.result.soundOnOff ',res.result.soundOnOff)
                setVoice(res.result.soundOnOff != null ? res.result.soundOnOff : true)
            }
        });
        socketUrl.emit('gameRoomData', data, (res) => {
            if (res.result) {
                const result = res.result;
                const room = res.result.room;
                setRoomType(result.namespaceString);
                setGametype(room.tableType);
                setGameFormat('');
                let socketIoD = '';
                
                if(room.tablePrivacy == "public") {
                    setchatOpen(false);
                } else {
                    setchatOpen(true);
                }
                
                let tableRoomType = result.namespaceString
                //console.log('게임데이터 소켓 재연결')
                socketIoD = io(URL + tableRoomType,{
                  transports: ['websocket','polling']
                });
                setSocketIo(socketIoD);
                getPlayerTableList()
            }
        });
        socketUrl.emit('GameSettings', data, (res) => {
            if (res.status == 'success') {
                if (res.result && res.result.hour_subscription) {
                    if (res.result.hour_subscription === 'active') {
                        setHourSubscription(true);
                    } else {
                        setHourSubscription(false);
                    }
                }
            }
        });
    }, [tableId]);

    const getPlayerTableList = () => {
        //---  0712 이슈6 로 return 주석
        return;
        const socketUrl = io(URL,{
          transports: ['websocket','polling']
        });
        // socketUrl.emit('playerTableList', { id: playerData.playerId, productName: 'PokerHills' }, (res) => {
        // });
        socketUrl.emit('GetRunningGameList', { playerId: playerData.playerId, productName: 'PokerHills' }, (res) => {
            setPlayerMultiTable(res.result)
        });
    }
    
    const changeTableData = (tId) => {
        setInitFinishTimeout('');

        /*
        테이블 변경될때 IdealPlayer 초기화
        */
        
        /*
        추가 초기화 데이터
        */
        setUniqueid(null)
        setPlayerActionData({});
        setPlayerSidePot(0)
        setIsPanelOpen(false);
        setIsFooterPanel(false);
        setGamePlayData({});

        setWinnerSeatIndex({});
        setWinningAmount({});
        setWinningType({});
        
        setBestCard([]);
        setGameStart(false);
        sethiddenCards({});
        
        setIsCardView(false);
        setIsCardViewPlayer({})
        
        
        setPlayerCards({})
        //console.log('gameFinishedPlayersCards,changeTableData')
        setGameFinishedPlayersCards({});

        setIdealPlayer({})
        setGameId(null)

        setSitOut({
            nextBlind: false,
            bigBlind: false
        });
        
        setPlayerInfo([])
        /*
        추가 초기화 데이터
        */ 
        
        setOnPlayersCardsDistribution([])
        
        
        setSwitchLoader(true);
        setTimeout(() => {
            setSwitchLoader(false);
        }, 1000);

        if (socketIo) {
            socketIo.disconnect();
        }
        setSocketIo('');
        
        setDefaultAction('')
        setCurrentTableId('');
        navigate(`/game/${tId}`);
    }

    const addNewTable = (tId) => {
        if (socketIo) {
            socketIo.disconnect();
        }
        setSocketIo('');
        
        setCurrentTableId('');
        navigate(`/lobby`);
    }
    
    const checkOrientation = () => {
        if(window.screen && window.screen.orientation)
        {
            const orientationType = window.screen.orientation.type;
            const orientationAngle = window.screen.orientation.angle;

            // Check if the device is in landscape mode
            if (orientationType === "landscape-primary" || orientationType === "landscape-secondary") {
              if (orientationAngle === 90 || orientationAngle === 270) {
                setIsRotateScreen(true);
              } else {
                setIsRotateScreen(false);
              }
            } else {
              setIsRotateScreen(false);
            }
        }
    };
    

    const currentTableCardMultitable = (datas) => {
        //setPlayerCards({cards:datas.cards})
    }

    useEffect(() => {
        // if (window.screen.orientation.type == "landscape-primary") {
        //   if ((window.screen.orientation.angle == 90) || (window.screen.orientation.angle == 270)) {
        //         setIsRotateScreen(true);
        //     } else {
        //         setIsRotateScreen(false);
        //     }
        // } else {
        //     setIsRotateScreen(false);
        // }
        
        // Initial check
        checkOrientation();
    
        // Event listener for orientation changes
        const handleOrientationChange = () => {
          checkOrientation();
        };

        window.addEventListener('orientationchange', handleOrientationChange);
        
        if (window.screen && window.screen.orientation && window.screen.orientation.type == "portrait-primary") {
            setIsMobile(true);
        } else {
            if (window.innerWidth <= 1024) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        }
        
        if (window.screen && window.screen.orientation && window.screen.orientation.type == "portrait-primary") {
            // setIsRotateScreen(true);
        } else {
            // setIsRotateScreen(false);
        }
        
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('orientationchange', handleOrientationChange);
        };
        
    }, [window.innerWidth]);

    useEffect(() => {
        if (messageEl) {
            messageEl.current.addEventListener('DOMNodeInserted', event => {
                const { currentTarget: target } = event;
                target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
            });
        }
    }, [openEmojiBox])

    useEffect(() => {
        
        const url = decodeURI(window.location.search);
        const params = new URLSearchParams(url);
        var token = params.get("token") ? params.get("token") : "";
        
        const socket = io(URL,{
          transports: ['websocket','polling']
        });
        const data = {
            playerId: playerData.playerId,
            productName: 'PokerHills'
        }

        socket.emit('gamePlayedLastTime', data, (res) => {
        });
        socket.emit('GetRunningGameList', { playerId: playerData.playerId, productName: 'PokerHills' }, (res) => {
            setPlayerMultiTable(res.result)
            //console.log('GetRunningGameList!!! RES');
            //console.log(res);
            //console.log('GetRunningGameList!!! RES');
        });
        const intervalId = setInterval(() => {
            socket.emit('gamePlayedLastTime', data, (res) => {
            });
            socket.emit('Playerprofile', { 
                playerId: playerData.playerId,
                productName: 'PokerHills',
                tokenId: token
            }, (res) => {
                
                if(res.result.is_login == false)
                {
                    alert('다른곳에서 접속 되었습니다');
                    window.close()
                }
                
                localStorage.setItem('user', JSON.stringify(res.result));
                //console.log('##########playerData##############');
                //console.log(res.result);
                //console.log('##########playerData##############');
                setPlayerData(res.result);
                if (res.result.hour_balance == "00:05") {
                    if (isCloseLowBalanceModalOpen == true) {
                        setIsLowBalanceModalOpen(true);
                    }
                }
                if (res.result.hour_balance == "00:00") {
                    if (isCloseInsufficientBalanceModalOpen == true) {
                        setIsInsufficientBalanceModalOpen(true);
                    }
                }
                
                // 채팅 온 오프
                /*if(notChatOpen.includes(res.result.userline)){
                    setchatOpen(false);
                } else {
                    setchatOpen(true);
                }*/
                
               

            });
            socket.emit('GetRunningGameList', { playerId: playerData.playerId, productName: 'PokerHills' }, (res) => {
                let multiPlayerCard = {};
                if (res.result.length > 0) {
                    for (let tableKey = 0; tableKey < res.result.length; tableKey++) {
                        if(res.result[tableKey].room.players.length > 0)
                        {
                            for (let playerKey = 0; playerKey < res.result[tableKey].room.players.length; playerKey++) {
                                if(res.result[tableKey].room.players[playerKey].id == playerData.playerId && res.result[tableKey].room.players[playerKey].cards.length > 0){
                                    multiPlayerCard[res.result[tableKey].roomId] = res.result[tableKey].room.players[playerKey].cards; 
                                    if(document.querySelector('.variable').getAttribute('data-initfinishtimeout')!='Finished' && res.result[tableKey].room.players[playerKey].folded==true)
                                    {
                                        setPlayerCards({cards:res.result[tableKey].room.players[playerKey].cards})
                                    }
                                }
                            }
                        }
                        if(res.result[tableKey].roomId == tableId) {
                            //console.log('isTournamentTable '  +res.result[tableKey].room.isTournamentTable);
                            //setIsTournament(res.result[tableKey].room.isTournamentTable);
                            
                            settableInfo(res.result[tableKey]);
                        }
                    }
                    
                    
                    setMultiPlayerCards(multiPlayerCard);

                }
                setPlayerMultiTable(res.result)
            });
            
            

            //socket.emit('CheckRunningGame', { playerId: playerData.playerId, productName: 'PokerHills' }, (res) => {
                //console.log('##########################CheckRunningGame')
                //console.log(res.result)
                //setPlayerMultiTable(res.result)
            //});
 
        }, 2000);

        setIntervalId(intervalId);

        return () => clearInterval(intervalId);

    }, [isCloseLowBalanceModalOpen, isCloseInsufficientBalanceModalOpen]);
    
    useEffect(() => {
        //console.log('isTournament 업데이트됨:', isTournament);
    }, [isTournament]);

    useEffect(() => {
        if (socketIo) {
            if (tableId) {
                
                //console.log('socketIo 재연결');
                if (gameFormat == 'tournament' || gameFormat == 'sng') {
                    setEmptyTable(false);
                }
                const data = {
                    playerId: playerData.playerId,
                    roomId: tableId,
                    latitude: 0,
                    longitude: 0,
                    productName: 'PokerHills'
                }
                
               
                let socketId = socketIo.id;
                const playerId = playerData.playerId;
                
                
                
                /*
                테이블 변경될때 IdealPlayer 초기화
                */
                setIdealPlayer({})
                socketIo.on('connect', () => {
                    setGameSocketId(socketIo.id);
                    socketId = socketIo.id;
                    setSocketIoConnect(true);
                });
            
                // 연결 끊김 이벤트 처리
                socketIo.on('disconnect', () => {
                  setSocketIoConnect(false);
                });
            
                // 재연결 시도 이벤트 처리
                socketIo.on('reconnect_attempt', (attemptNumber) => {
                });
            
                // 재연결 성공 이벤트 처리
                socketIo.on('reconnect', (attemptNumber) => {
                  setSocketIoReconnect(socketIoReconnect+1);
                });
            
                // 재연결 실패 이벤트 처리
                socketIo.on('reconnect_failed', () => {
                });
                
                
                socketIo.on('businessClosed', (data) => {
                    toast.error(data.message, {
                        position: "top-center",
                        autoClose: 20000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });                    
                });

                

                socketIo.emit('SubscribeRoom', data, (res) => {
                    if (res.result) {
                        //console.log('SubscribeRoom')
                        //console.log(res.result)
                        setRoomData(res.result);
                        setCurrentTableId(res.result.roomId)
                        if (res.result) {
                            if (res.result.room && res.result.room.players.length > 0) {
                                for (let pI = 0; pI < res.result.room.players.length; pI++) {
                                    if (res.result.room.players[pI].id == playerData.playerId) {
                                        setRoomOut(res.result.room.players[pI].roomOutNextHand===true?true:false);
                                    }
                                }
                            }
                        }
                    }
                });
                
                socketIo.on('cardViewAction', (data) => {
                    let gameFinishedCard = JSON.parse(document.querySelector('.variable').getAttribute('data-gameFinishedPlayersCards'));
                    let cardViewPlayer = JSON.parse(document.querySelector('.variable').getAttribute('data-isCardViewPlayer'));



                    let existingPlayer = gameFinishedCard.playersCards.find(
                        player => player.playerId === data.playerId
                    );
                    
                    if (existingPlayer) {
                        existingPlayer.cards = data.playersCards;
                    } else {
                        gameFinishedCard.playersCards.push({
                            playerId: data.playerId,
                            cards: data.playersCards
                        });
                    }
                    
                    cardViewPlayer[data.playerId] = true;
                    setIsCardViewPlayer(cardViewPlayer);

                    setGameFinishedPlayersCards(gameFinishedCard);
                    setIsShowdownShown(false);

                })

                socketIo.emit('PlayerGift', { tableId });
                socketIo.on('PlayerGift', (data) => {
                    setPlayerGiftData(data);
                    setTimeout(() => {
                        setPlayerGiftData({});
                    }, 4000)
                });
                
                


                socketIo.emit('OnReservedSeatList', { tableId });
                socketIo.on('OnReservedSeatList', (data) => {
                });

                socketIo.on('Chat', (res) => {
                    if (res.message != '') {
                        const oldMessage = gameChatMessages;
                        oldMessage.push(res);
                        setGameChatMessages(oldMessage)
                    }
                });

                socketIo.emit('OnSubscribeRoom', { socketId });
                socketIo.on('OnSubscribeRoom', OnSubscribeRoomReceived);

                socketIo.emit('playerInfoList', { tableId });
                socketIo.on('playerInfoList', PlayerInfoListReceived);

                socketIo.emit('OnGameStartWait', { tableId });
                socketIo.on('OnGameStartWait', (data) => {
                });

                socketIo.emit('superPlayerData', { socketId });
                socketIo.on('superPlayerData', (data) => {

                });
                socketIo.emit('OnReservedSeatList', { tableId });
                socketIo.on('OnReservedSeatList', (data) => {

                });
                
                
                socketIo.on('broadcast-message', (data) => {
                    //console.log('broadcast-message',data)
                    setIsBroadCastMessage(true);
                    setBroadCastMessage(data)
                    setTimeout(() => {
                        setIsBroadCastMessage(false)
                        setBroadCastMessage({})
                    }, 10000)
                });
                // socketIo.emit('RevertPoint', { tableId });
                // socketIo.on('RevertPoint', (data) => {
                // });

                // socketIo.emit('OnWaitingJoinRoom', { socketId });
                // socketIo.on('OnWaitingJoinRoom', (data) => {
                // })

                socketIo.emit('OnPlayersCardsDistribution', { tableId });
                socketIo.on('OnPlayersCardsDistribution', (data) => {
                    //console.log('onPlayersCardsDistribution',data)
                    setOnPlayersCardsDistribution(data);
                });

                socketIo.emit('GameFinishedPlayersCards', { tableId });
                socketIo.on('GameFinishedPlayersCards', (data) => {
                    let existingPlayer = null;
                    const lastCard = document.querySelector('.hiddenCard5.flipped');
                    
                    if(data.playersCards && data.playersCards.length > 0)
                    {
                        existingPlayer = data.playersCards.find(
                            player => player.playerId === playerData.playerId
                        );
                    }
                    if (existingPlayer) {
                        
                    } else {
                        setIsCardView(true);
                    }
                    
                    // console.log("Showdown!");
                    //setIsShowdownShown(true);
                    if (lastCard) {
                        setIsShowdownShown(false);
                    } else {
                        setIsShowdownShown(true);
                    }
                    
                    setGameFinishedPlayersCards(data);
                    console.log(gameFinishedPlayersCards);
                    
                    
                    /*
                    백엔드에서 GameFinishedPlayersCards가 인원수대로 들어오는문제가있었는데 수정되어서 밑에껀 안씀
                    */
                    /*
                    console.log('GameFinishedPlayersCards',data)
                    if(data.playersCards && data.playersCards.length > 0)
                    {
                        setTimeout(function(){
                            //console.log('setTimeout',data)
                            console.log(document.querySelector('.variable').getAttribute('data-gameFinishedCardGameId')!=data.gameId,"#",document.querySelector('.variable').getAttribute('data-isRevertPointFolded') == "false","#",document.querySelector('.variable').getAttribute('data-initfinishtimeout')=='Finished')
                            if(document.querySelector('.variable').getAttribute('data-gameFinishedCardGameId')!=data.gameId && document.querySelector('.variable').getAttribute('data-isRevertPointFolded') == "false" && document.querySelector('.variable').getAttribute('data-initfinishtimeout')=='Finished')
                            {
                                console.log('GameFinishedPlayersCards setTimeout',data)
                                setGameFinishedPlayersCards(data);
                                setGameFinishedCardGameId(data.gameId);
                            }
                            if(document.querySelector('.variable').getAttribute('data-gameFinishedCardGameId')!=data.gameId && document.querySelector('.variable').getAttribute('data-isRevertPointFolded') == "true")
                            {
                                //console.log('setGameFinishedCardGameId(data.gameId);',data.gameId)
                                setGameFinishedCardGameId(data.gameId);
                            }
                            
                        },100)
                    }
                    */
                });

                socketIo.on('ResetGame',(data) => {
                })
                socketIo.on('OnGameBoot',(data) => {
                    setGameFinishedPlayersCards({})
                })

                socketIo.emit('GameFinished', { tableId });
                socketIo.on('GameFinished', (data) => {
                    if (data) {
                        const SubscribeRoomdata = {
                            playerId: playerData.playerId,
                            roomId: tableId,
                            latitude: 0,
                            longitude: 0,
                            productName: 'PokerHills'
                        }
                        
                        if(document.querySelector('.variable').getAttribute('data-socketIoId') != socketId){
                            socketIo.disconnect();
                            return;
                        }
                        
                        socketIo.emit('SubscribeRoom', SubscribeRoomdata, (res) => {
                            if (res.result) {
                                setRoomData(res.result);
                            }
                        });
                        setPlayerSidePot(0)
                        setIsPanelOpen(false);
                        setIsFooterPanel(false);
                        setGamePlayData({});
                        //console.log(data.winners.length)
                        if (data.winners.length > 0) {
                            /*
                            위너 여러명 처리
                            setWinnerSeatIndex(data.winners[0].winnerSeatIndex);
                            setWinningAmount(data.winners[0].amount);
                            setWinningType(data.winners[0].winningType);
                            */
                            //console.log('setBestCard@@@@',data.winners[0].bestCards)
                            setBestCard(data.winners[0].bestCards);
                            //console.log(data.winners)
                            let winnerData = {
                                winnerSeatIndex:{},
                                winningAmount:{},
                                winningType:{}
                            };
                            for (let winneridx = 0; winneridx < data.winners.length; winneridx++) {
                                winnerData.winnerSeatIndex[data.winners[winneridx].winnerSeatIndex] = true;
                                winnerData.winningAmount[data.winners[winneridx].winnerSeatIndex] = data.winners[winneridx].amount;
                                winnerData.winningType[data.winners[winneridx].winnerSeatIndex] = getCardTypeKorean(data.winners[winneridx].winningType);
                            }
                            //console.log(winnerData);
                            setWinnerSeatIndex(winnerData.winnerSeatIndex);
                            setWinningAmount(winnerData.winningAmount);
                            
                            
                            
                            
                            setWinningType(winnerData.winningType);
                            
                            let timerTableId = currentTableId;
                            setInitFinishTimeout('Finished');
                            setTimeout(initFinish,6000);
                            /*
                            setTimeout(() => {
                                if(false)
                                {
                                    //console.log("@@@initFinishTimeout",initFinishTimeout)
                                    initFinishTimeout = false;
    
                                    //console.log("timerid",timerTableId);
                                    //console.log('getCurrentTableId',getCurrentTableId());
                                    
                                    //위너 여러명 처리
                                    //setWinnerSeatIndex(-1);
                                    //setWinningAmount(-1);
                                    //setWinningType('');
                                    
                                    setWinnerSeatIndex({});
                                    setWinningAmount({});
                                    setWinningType({});
                                    setBestCard([]);
                                    setGameStart(false);
                                    sethiddenCards({});
                                    
                                    setPlayerCards({})
                                    setGameFinishedPlayersCards({});
                                }
                                initFinish();
                            }, 4000)
                            */
                        }
                    }
                });
                socketIo.on('avatarChange',OnAvatarChange);
            }
        }

    }, [socketIo, tableId, socketIoReconnect]);

    useEffect(() => {
        const handleResize = () => {
            const mobileFontSize = (window.innerWidth * 64.68) / 414;
            setMobileView({
                width: `${window.innerWidth}px`,
                height: `${window.innerHeight}px`,
                fontSize: `${mobileFontSize}%`,
            });

            if (isMobile) {

                const baseFontSize = 9;
                const baseWidth = 360;
                const baseHeight = 522;

                // Current window dimensions
                const windowWidth = window.innerWidth; // replace with actual window width
                const windowHeight = window.innerWidth * 1.452; // replace with actual window height

                // Calculate scaling factors
                const widthScale = windowWidth / baseWidth;
                const heightScale = windowHeight / baseHeight;

                // Choose the minimum scaling factor to maintain aspect ratio
                const scale = Math.min(widthScale, heightScale);

                // Calculate the scaled font size
                const scaledFontSize = baseFontSize * scale;

                let newFontSize = (initialFontSize * (window.innerWidth * 1.45)) / mobileMaxHeight;
                setTableImage({
                    width: `${window.innerWidth}px`,
                    height: `${window.innerWidth * 1.452}px`,
                    fontSize: `${scaledFontSize}px`,
                });

                // if (containerWidth / containerHeight > aspectRatio) {
                //     let newWidth = containerHeight * aspectRatio;
                //     let newFontSize = (initialFontSize * containerHeight) / mobileMaxHeight;
                //     setTableImage({
                //         width: `${newWidth}px`,
                //         height: `${containerHeight}px`,
                //         fontSize: `${newFontSize}px`,
                //     });
                // } else {
                //     let newHeight = containerWidth / aspectRatio;
                //     let newFontSize = (initialFontSize * containerWidth) / mobileMaxWidth;
                //     let newWidth = Math.min(containerWidth, mobileMaxWidth);
                //     newHeight = Math.min(newHeight, mobileMaxHeight);
                //     newFontSize = Math.min(newFontSize, initialFontSize);
                //     setTableImage({
                //         width: `${newWidth}px`,
                //         height: `${newHeight}px`,
                //         fontSize: `${newFontSize}px`,
                //     });
                // }
            } else {
                const aspectRatio = maxWidth / maxHeight;
                const containerWidth = window.innerWidth;
                const containerHeight = window.innerHeight - '160';
                if (containerWidth / containerHeight > aspectRatio) {
                    let newWidth = containerHeight * aspectRatio;
                    let newFontSize = (initialFontSize * containerHeight) / maxHeight;
                    setTableImage({
                        width: `${newWidth}px`,
                        height: `${containerHeight}px`,
                        fontSize: `${newFontSize}px`,
                    });
                } else {
                    let newHeight = containerWidth / aspectRatio;
                    let newFontSize = (initialFontSize * containerWidth) / maxWidth;
                    let newWidth = Math.min(containerWidth, maxWidth);
                    newHeight = Math.min(newHeight, maxHeight);
                    newFontSize = Math.min(newFontSize, initialFontSize);
                    setTableImage({
                        width: `${newWidth}px`,
                        height: `${newHeight}px`,
                        fontSize: `${newFontSize}px`,
                    });
                }
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMobile]);

    useEffect(() => {
        if (socketIo) {
            socketIo.emit('PlayerLeft', { tableId });
            socketIo.on('PlayerLeft', (data) => {
                let gamePlayer = playerInfo;
                if (gamePlayer.length > 0) {
                    let playerInfoData = gamePlayer.find((playerEle) => {
                        if (playerEle.id === data.playerId) {
                            if (data.playerId === playerData.playerId) {
                                if (socketIo) {
                                    socketIo.disconnect();
                                }
                                setSocketIo('');
                                navigate('/lobby');
                            }
                            return playerEle;
                        }
                    });
                    var index = gamePlayer.indexOf(playerInfoData);
                    if (index !== -1) {
                        gamePlayer.splice(index, 1);
                        //console.log("PlayerLeft",gamePlayer)
                        handlePlayerInfo(gamePlayer)
                    }

                    if (idealPlayer.playerId == data.playerId) {
                        setIdealPlayer({});
                    }
                }
            });



            socketIo.emit('RevertPointFolded', { tableId });
            socketIo.on('RevertPointFolded', (data) => {
                
                setIsRevertPointFolded(true)
                let gamePlayer = playerInfo;
                if (gamePlayer.length > 0) {
                    let playerInfoData = gamePlayer.find((playerEle) => {
                        if (playerEle.id === data.playerId) {
                            return playerEle;
                        }
                    });

                    var index = gamePlayer.indexOf(playerInfoData);
                    if (gamePlayer[index]) {

                        gamePlayer[index].BuyInAmount = data.chips
                    }

                    if (gamePlayer.length > 0) {
                        handlePlayerInfo(gamePlayer)
                    }

                }
                setIsFooterPanel(false);
                
                
                /*
                위너 여러명 처리
                setWinnerSeatIndex(data.winnerSeatIndex);
                setWinningAmount(data.amount);
                */
                let winnerData = {
                    winnerSeatIndex:{},
                    winningAmount:{},
                    winningType:{}
                };
                winnerData.winnerSeatIndex[data.winnerSeatIndex] = true;
                winnerData.winningAmount[data.winnerSeatIndex] = data.amount;
                setWinnerSeatIndex(winnerData.winnerSeatIndex);
                setWinningAmount(winnerData.winningAmount);
                setWinningType({});
                
                 let timerTableId = currentTableId;
                             
                setInitFinishTimeout('Finished');
                setTimeout(initFinish,6000);
                
                /*
                setTimeout(() => {
                    if(false)
                    {
                        //console.log("####",initFinishTimeout)
                        initFinishTimeout = false;
                        //console.log("timerid",timerTableId);
                        //console.log('getCurrentTableId',getCurrentTableId())                
                        //위너 여러명 처리
                        //setWinnerSeatIndex(-1);
                        //setWinningAmount(-1);
                        
                        setWinnerSeatIndex({});
                        setWinningAmount({});
                        
                        setGameStart(false);
                        setBestCard([]);
                        // setDisplayCard1(false);
                        // setDisplayCard2(false);
                        // setDisplayCard3(false);
                        // setDisplayCard4(false);
                        sethiddenCards({});
                        setPlayerCards({})
                    }
                    initFinish();
                }, 4000)
                */
            });


        };
    }, [socketIo, playerInfo, tableId]);

    useEffect(() => {
    }, [dealerPlayer]);

    useEffect(() => {
        if (Object.keys(winnerSeatIndex).length >= 1) {
            playVoice(6)
        }
    }, [winnerSeatIndex]);

    useEffect(() => {
        //console.log('#############')
        //console.log(gameStart)
        //console.log('#############')
        if (gameStart == true) {
            playVoice(8);
        }
    }, [gameStart]);

    useEffect(() => {
        /**
        이전 값과 같은 playerActionType가 나왔을땐
        useEffect에서 처리되지않아서 playerid를 함께 넣어놓았음
        **/
        let plyrid = playerActionType.split("___")[0];
        const alarm = playerActionType.split("___")[1];
        let amount = playerActionType.split("___")[2];
        
        
        if (playerActionType != '') {
            // const alarm = playerActionType.split("___")[1]
            if (alarm == 6) {
                playVoice(2);
            } else {
                if (alarm == 2) {
                    // playVoice(4);
                    playVoice(20);
                } else {
                    // let amount = playerActionType.split("___")[2]
                    if (amount < 10000) {
                        //playVoice(9);
                    } else if (amount < 100000) {
                        //playVoice(10);
                    } else if (amount < 1000000) {
                        //playVoice(11);
                    } else {
                        //playVoice(12);
                    }

                    //playVoice(1);
                }
            }
            // voices
            if (playerData.playerId !== plyrid) {
                if (alarm == 3) {
                    if (gamePlayData.buttonAction.raise) {
                        // console.log('raise');
                        playVoice(19);
                    } else {
                        // console.log('bet');
                        playVoice(14);
                    }
                }
            }
            if (alarm == 4) {
                // console.log('call');
                playVoice(15);
            }
            if (alarm == 8) {
                // console.log('allin');
                playVoice(13);
            }
        }
    }, [playerActionType]);
    

    const playVoice = (type) => {
        if (voice) {
            if (type == 1) {
                chipsVoice();
            }
            if (type == 2) {
                foldCardVoice();
            }
            if (type == 3) {
                cardDealtVoice();
            }
            if (type == 4) {
                checkActionVoice();
            }
            if (type == 5) {
                myTurnVoice();
            }
            if (type == 6) {
                winnerVoice();
            }
            if (type == 7) {
                collectVoice();
            }
            if (type == 8) {
                cardShuffleVoice();
            }
            if (type == 9) {
                chip1000Voice();
            }
            if (type == 10) {
                chip10000Voice();
            }
            if (type == 11) {
                chip100000Voice();
            }
            if (type == 12) {
                chip1000000Voice();
            }
            if (type == 13) {
                allinVoice();
            }
            if (type == 14) {
                betVoice();
            }
            if (type == 15) {
                callVoice();
            }
            if (type == 16) {
                fullVoice();
            }
            if (type == 17) {
                halfVoice();
            }
            if (type == 18) {
                quarterVoice();
            }
            if (type == 19) {
                raiseVoice();
            }
            if (type == 20) {
                checkVoice();
            }
            if (type == 21) {
                timerSnd();
            }
        }
    }
    const handlePlayerInfo = (playerInfoArr) => {
        //console.log("handlePlayerInfo",playerInfoArr)
        if (playerInfoArr.length > 0) {
            let playerDataInfo = playerInfoArr.find((playerEle) => {
                return playerEle.id === playerData.playerId;
            });
            if (playerDataInfo) {
                setSeatPositionCss(playerDataInfo.seatIndex)
            }
            
            playerInfoArr.map((player, index) => {
                //* FOLD했는데 살아나는 경우 */
                if(playerInfoArr[index].status=='Playing' && playerInfoArr[index].folded==true)
                {
                    playerInfoArr[index].status = 'Fold';
                }
                if (playerInfoArr[index].BuyInAmount) {
                    //-- 의미없는코드라 주석 * 올인시 이전금액으로 돌아간다는 이슈 ? *
                    //playerInfoArr[index].BuyInAmount = playerInfoArr[index].BuyInAmount
                } else {
                    if (player.waitingGameChips > 0) {
                        playerInfoArr[index].BuyInAmount = player.waitingGameChips
                    } else{
                        playerInfoArr[index].BuyInAmount = player.chips
                    }
                    if (player.id == playerData.playerId) {
                        setEmptyTable(false);
                    }
                }
                if (playerInfoArr[index].BetAmount) {
                    playerInfoArr[index].BetAmount = playerInfoArr[index].BetAmount

                } else {
                    playerInfoArr[index].BetAmount = player.betAmount
                }
                if (player.id == playerData.playerId && playerInfoArr[index].BetAmount>0) {
                    //console.log('setGameStart11111111111');
                    
                    //# 대기중인 인원 나갈때 실행되서 패가 나옴
                    //setGameStart(true);
                }
            });
            setPlayerInfo(playerInfoArr);
        }
    }
    const OnSubscribeRoomReceived = (data) => {
        
        setUniqueid(null);
        //console.log('setBestCard,OnSubscribeRoomReceived')
        
        //# 기존에 setBestCard가 나옴과 동시에 subscribeRoom이 나와서 바로 없어짐
        //setBestCard([]);
        
        setGameStart(false);
        sethiddenCards({});

        setPlayerActionType('')
        setGamePlayData({});
        setMaxTimer(0)
        setInProgressTimer(null)
        setIsFooterPanel(false);
        setIsPanelOpen(false);
        setRaiseAmount(0);
        setTablePot(0)
        setPlayerSidePot(0);
        setIsCardView(false);
        setIsCardViewPlayer({})
        
        
        
        //console.log("####################OnSubscribeRoomReceived###################");
        //console.log(data);
        //console.log(data.isTournament)
        //console.log("####################OnSubscribeRoomReceived###################");
        /*
        SubscribeRoomReceived에서 playerCard들이 나오면 안될것같음
        */
        //setPlayerCards({})
        
        
        if (data) {
            setSmallBlindChips(data.smallBlindChips)
            if(false)
            {
                if (data.room.game != null) {
                    setGameId(data.room.game.id);
                    setGameStart(true);
                    
                    /*
                    SubscribeRoomReceived에서 playerCard들이 나오면 안될것같음
                    */
                    if (data.room.players.length > 0) {
                        for (let pI = 0; pI < data.room.players.length; pI++) {
                            if (data.room.players[pI].id == playerData.playerId && data.room.players[pI].cards.length > 0 && data.room.players[pI].status=='Playing') {
                                
                                const plyData = {
                                    cards: data.room.players[pI].cards,
                                    flipAnimation: true,
                                    playerId: '',
                                    roomId: ''
                                };
                                setPlayerCards(plyData)
                            }
                        }
                        for (let pI = 0; pI < data.room.game.players.length; pI++) {
                            if (data.room.game.players[pI].id == playerData.playerId) {
                                //setGameSocketId(data.room.game.players[pI].socketId)
                                
                            }
                        }
                    }
                    setUniqueid(data.uniqueid)
                    sethiddenCards({
                        card:data.room.game.board,
                        playing:false
                    })
                    if (data.room.game.gameTotalChips > 0) {
                        setTablePot(data.room.game.gameTotalChips)
                    }
                    /*
                    베타 이슈1로 0으로 수정
                    
                    //# 추가:: 새로고침시 사이드팟이 안나옴 다시 수정
                    */
                    /* 재수정 0으로 */
                    //* 20240712 - 사이드팟이 방이동, 새로고침시 안나와서  다시수정
                    setPlayerSidePot(data.room.game.gameMainPot)
                    //setPlayerSidePot(0);
                }
            }
            
            setGameId(data.gameId);
            //console.log('setGameStart2222222222222222222');
            //---- 기존에에 true인데 방에 접속하면 바로 gamestart true이므로 게임이 시작된 상태가되어 false 로 변경 -0920
            setGameStart(false);
            sethiddenCards({
                card:data.board,
                playing:false
            })
            if (data.gameTotalChips > 0) {
                setTablePot(data.gameTotalChips)
            }
            //-- 게임시작시 메인팟 초기화안됨
            //setPlayerSidePot(data.gameMainPot);
            //console.log('data.isTournament',data.isTournament)
            setIsTournament(data.isTournament)
            CallSocket();
        }
    }
    const prevValues = useRef({ socketIo, tableId, currentTableId, playerInfo });
    useEffect(() => {
        const changedValues = {};
        if (prevValues.current.socketIo !== socketIo) changedValues.socketIo = { from: prevValues.current.socketIo, to: socketIo };
        //if (prevValues.current.tableId !== tableId) changedValues.tableId = { from: prevValues.current.tableId, to: tableId };
        //if (prevValues.current.currentTableId !== currentTableId) changedValues.currentTableId = { from: prevValues.current.currentTableId, to: currentTableId };
        //if (prevValues.current.playerInfo !== playerInfo) changedValues.playerInfo = { from: prevValues.current.playerInfo, to: playerInfo };
    
        if (Object.keys(changedValues).length > 0) {
          //console.log('Changed values:', changedValues);
        }
    
        prevValues.current = { socketIo, tableId, currentTableId, playerInfo };
        
        
        //console.log('@@@@@@@@@@@@@@@@@OnGameBoot@@@@@@@@@@@@@@@@@@@@')
        if (socketIo) {
            const data = {
                playerId: playerData.playerId,
                roomId: tableId,
                productName: 'PokerHills'
            }
            //console.log('##############ON game Boot SOCKETIO START#################');
            socketIo.emit('OnGameBoot', { tableId });
            socketIo.on('OnGameBoot', OnGameBootReceived);

            socketIo.on('PlayerAction', OnPlayerActionReceived);
			
            socketIo.on('CardInfo', CardInfoReceived);
            socketIo.emit('RoundComplete', { tableId });
            socketIo.on('RoundComplete', RoundCompleteReceived);
            //console.log('##############ON game Boot SOCKETIO END#################');
        }
    /*
    SubscribeRoom을 보내면 서버에서 
    playerInfoList 를줘서 소스가 여러번수정됨
    */
    }, [socketIo, tableId, currentTableId, playerInfo]);

    useEffect(() => {
        CallSocket(socketIo)
    }, [socketIo, tableId, currentTableId]);

    useEffect(() => {
        if (socketIo) {
            socketIo.emit('OnTurnTimer', { tableId });
            socketIo.on('OnTurnTimer', OnTurnTimerReceived);
            // 카드안보인다고//
			if (currentTableId && (!(playerCards && playerCards.cards && playerCards.cards.length > 0) || (document.querySelector('.player_rank') && document.querySelector('.player_rank').innerText==''))) {
				const socketId = socketIo.id
				socketIo.emit('OnPlayerCards', { socketId });
				socketIo.on('OnPlayerCards', OnPlayerCardsReceived);
			}
        }

        return () => {
            if (socketIo) {
                socketIo.off('OnTurnTimer', OnTurnTimerReceived);
            }
        };
    }, [socketIo, tableId, currentTableId, isPanelOpen]);
    
    useEffect(() => {
        if (socketIo) {
            const RoomOutNextHandData = {
                playerId: playerData.playerId,
                roomId: tableId,
                actionValue: roomOut,
                latitude: 0,
                longitude: 0,
                productName: 'PokerHills'
            }
            socketIo.emit('RoomOutNextHand', RoomOutNextHandData, (res) => { });
        }
    }, [roomOut]);
    useEffect(() => {
        if(roomOut == true && isLogoutModalOpen == true)
        {
            setRoomOut(!roomOut);
            setIsLogoutModalOpen(false);
        }        
    }, [isLogoutModalOpen]);
    
    
    const handleChipsChange = (e) => {
        const value = parseInt(e.target.value, 10);
        //console.log('handleChipsChange'+value);
        // maxBuyIn을 넘지 않도록 값 제한
        if (value > maxBuyIn) {
            setStart(maxBuyIn);
        } else if (value < 0) {
            setStart(minBuyIn);
        } else {
            setStart(value);
        }
        setSliderPct(((value - minBuyIn) / (maxBuyIn - minBuyIn)) * 100);
    };
    
    const CallSocket = (socketIo) => {
        if (socketIo) {
            const socketId = socketIo.id

            socketIo.emit('GameStarted', { tableId });
            socketIo.on('GameStarted', OnGameStartReceived);

            socketIo.emit('OnPlayerCards', { socketId });
            socketIo.on('OnPlayerCards', OnPlayerCardsReceived);

            socketIo.emit('onIdealPlayer', { tableId });
            socketIo.on('onIdealPlayer', OnIdealPlayerReceived);

            socketIo.emit('OnOpenBuyInPanel', { tableId });
            socketIo.on('OnOpenBuyInPanel', OnOpenBuyInPanelReceived);
            
            socketIo.on('OnBreak', (data) => {
                setBreakTime(data.timer)
            
                //console.log('OnBreak',data)
            })
        }
    }
	
	const CardInfoReceived = (data) => {
        if (data && data.roomId == tableId) {
            //console.log('cardInfo' + data.hand);
            var cardHand = getCardTypeKorean(data.hand);
            
            //# 새로고침했을때 다이후 내 카드 처리
            //setPlayerCards({cards:data.cards})
            
	        if(data.boardcards.length == 3)
	        {
    	        setTimeout(function(){
    	            if(document.querySelector('.variable').getAttribute('data-tableid')==data.roomId)
    	            {
        	           //document.querySelector('.player_rank').innerText = data.hand;
        	           document.querySelector('.player_rank').innerText = cardHand;
        	            //console.log('111111111111111111111' + data.hand);
    	            }
    	        },2500)    
	        }
	        else if(data.boardcards.length >= 4)
	        {
	            setTimeout(function(){
	                if(document.querySelector('.variable').getAttribute('data-tableid')==data.roomId)
    	            {
        	            //document.querySelector('.player_rank').innerText = data.hand;
        	            document.querySelector('.player_rank').innerText = cardHand;
    	            }
    	        },1500)
	        }
	        else
	        {
	            //document.querySelector('.player_rank').innerText = data.hand;
	            document.querySelector('.player_rank').innerText = cardHand;
	        }
	       // setTimeout(function(){
	       //     document.querySelector('.player_rank').innerText = data.hand;
	       // },1500)
			
        }
    }

    const PlayerInfoListReceived = (data) => {
        if(data.dealerPlayerId)
        {
            setDealerPlayer(data.dealerPlayerId);
        }
        if (data && data.roomId == tableId) {
            handlePlayerInfo(data.playerInfo)
        }
    }

    const OnOpenBuyInPanelReceived = (data) => {
        if (data && data.roomId === currentTableId) {
            setIsOnOpenBuyInPanel(true);
        }
    }
    
    const OnAvatarChange = (data) =>{
        if(data && data.player && data.avatar)
        {
            let gamePlayer = playerInfo;
            gamePlayer.map((player, index) => {
                if(player.id==data.player)
                {
                    gamePlayer[index].avatar = data.avatar;
                }
            })
            handlePlayerInfo(gamePlayer);
        }
    }

    const OnGameBootReceived = (data) => {
        if (data && data.roomId === currentTableId) {
            
            let gamePlayer = playerInfo;
            if (gamePlayer.length > 0) {
                gamePlayer?.map((plr, i) => {
                    if (plr.id != null) {

                        //# 모든 사용자를 Playing으로 바꿔서 나가기가 안됨 주석처리
                        //# 서버에서 status로주는게 Playing,  ideal=휴식 Waiting 더있을수도있음 이런값들 주는데 
                        //# 전부 Playing으로 바꾸는 부분
                        //gamePlayer[i].status = 'Playing'
                        
                        if (plr.id == data.dealerPlayerId) {
                            setDealerPlayer(data.dealerPlayerId);
                            gamePlayer[i].isDealer = true;
                        } else {
                            gamePlayer[i].isDealer = false;
                        }
                        if (plr.id == data.smallBlindPlayerId) {
                            gamePlayer[i].BetAmount = data.smallBlindChips;
                            gamePlayer[i].BuyInAmount = data.smallBlindPlayerChips;
                        }
                        if (plr.id == data.bigBlindPlayerId) {
                            gamePlayer[i].BetAmount = data.bigBlindChips;
                            gamePlayer[i].BuyInAmount = data.bigBlindPlayerChips;
                        }
                        if (data.straddlePlayerId != null && plr.id == data.straddlePlayerId) {
                            gamePlayer[i].BetAmount = data.straddleChips;
                            gamePlayer[i].BuyInAmount = data.straddlePlayerChips;
                        }

                        data?.bigBlindPlayerList?.map((bigBlindPlr, k) => {
                            if (plr.id == bigBlindPlr.playerId) {
                                gamePlayer[k].BetAmount = bigBlindPlr.chips;
                                gamePlayer[k].BuyInAmount = bigBlindPlr.playerChips;
                            }
                        })
                    }
                });
                handlePlayerInfo(gamePlayer);
            }
            setGameFinishedPlayersCards({})
            setTablePot(data.totalTablePotAmount)
            setGameBootData(data);
            setSmallBlindChips(data.smallBlindChips)
        }
    }

    const OnIdealPlayerReceived = (data) => {
        if (data && data.roomId === currentTableId) {
            if (data.status == true) {
                setIdealPlayer(data);
            } else {
                setIdealPlayer({});
            }
        }
    }

    const RoundCompleteReceived = (data) => {
        if (data && data.roomId === currentTableId) {
            const sidePot = data.PlayerSidePot;
            setPlayerSidePot(sidePot.mainPot);
            //if (sidePot.mainPot > 0) {
                let gamePlayer = playerInfo;
                if (gamePlayer.length > 0) {
                    gamePlayer?.map((pyr, index) => {
                        if (gamePlayer[index].BetAmount > 0) {
                            // setTimeout(() => {
                            //     playVoice(7)
                            // }, 300)
                        }
                        gamePlayer[index].BetAmount = 0
                    });
                    setPlayerInfo(gamePlayer);
                    //handlePlayerInfo(gamePlayer)
                }
            //}
            /*
            setTimeout(() => {
                sethiddenCards({
                    card:data.cards,
                    playing:true
                });
            }, 1000)
            */
            
            sethiddenCards({
                card:data.cards,
                playing:true
            });
        }
    }

    const OnPlayerActionReceived = (data) => {
        if (data.roomId === currentTableId) {
            getPlayerTableList();
            setMaxTimer(1);
            setIsPanelOpen(false);
            /*
            필요없는 소스임
            */
            if (data.action.action == 6) {
                let gamePlayerOld = playerInfo;
                if (gamePlayerOld.length > 0) {
                    gamePlayerOld?.map((pyr, index) => {

                        if (data.action.playerId === pyr.id) {
                            gamePlayerOld[index].status = 'Fold';
                            
                            //# 카드FOLD되었을때 보이는부분
                            /*
                            20240910 카드 폴드되었을때 전체 카드가 덮어져버림림
                            */
                            //setPlayerCards({});
                        } else {
                            //gamePlayerOld[index].status = 'Playing'
                        }
                    });
                    //handlePlayerInfo(gamePlayerOld)
                }
            }
            if (data.action.playerId == playerData.playerId) {
                if (data.action.action == 6) {
                    // let playerCardsOld = playerCards;
                    // playerCardsOld.flipAnimation = false
                    // setPlayerCards(playerCardsOld)
                }
                setDefaultAction('');
            } 
            
            setPlayerActionType(data.action.playerId+"___"+data.action.action+"___"+data.action.betAmount)
            
            //setDefaultAction('');
            setPlayerActionData(data.action);
            
            setTimeout(() => {
                setPlayerActionData({});
            }, 2000);
            let gamePlayer = [...playerInfo];
            if (gamePlayer.length > 0) {
                let playerInfoData = gamePlayer.find((playerEle) => {
                    if (playerEle.id === data.action.playerId) {
                        return playerEle;
                    }
                });

                var index = gamePlayer.indexOf(playerInfoData);
                if (gamePlayer[index]) {
                    gamePlayer[index].BuyInAmount = data.playerBuyIn
                    gamePlayer[index].BetAmount = data.action.totalBetAmount
                }
            }
            setTablePot(data.totalTablePotAmount);
            setRaisePct(0);
        }
        
    }
    
	const OnTurnTimerReceived = (data) => {
        if (data.roomId === currentTableId) {
            
            if(gameFinishedPlayersCards.playersCards)
            {
                setGameFinishedPlayersCards({})
            }
            
            if(data.timer==0)
            {
                //document.querySelector('.timer').style.animation='initial';
                //document.querySelector('.timer').style='';
		        //document.querySelector('.timer').classList.remove('timer')
		        
                //const element = document.querySelector('.timer');
                //element.classList.remove('timer');
                //element.classList.add('timer');
            }
            
            /*
            게임 중간에 플레이어가 들어왔을때 시간바 처리리
            */

            if(isPanelOpen==false || true)  //# 
            {
                if(inProgressTimer!=null && data.timer==0 )
                {
                    setInProgressTimer(null)
                    data.maxTimer = 0;
                }
            }
            
            sethiddenCards({
                card:data.boardcards,
                playing:true
            })
            /*
            if(isPanelOpen==true && maxTimer != data.maxTimer && inProgressTimer!=null)
            {
                setInProgressTimer(null)    
            }
            */
            
            if(maxTimer==0)
            {
                setInProgressTimer(data.timer-1);
            }
            setGameStart(true);
            setPlayerActionType('')
            
            // 마이너스 테스트
            //data.buttonAction.minRaise = -20;
            setGamePlayData(data);
            setMaxTimer(data.maxTimer);
            setIsFooterPanel(true);
            setIsPanelOpen(true);
            if (!isPanelOpen) {
                setIsRaiseAmount(false);
                setRaiseAmount(data.buttonAction.minRaise);
            }
            if (data.playerId == playerData.playerId && data.timer == (data.maxTimer - 1)) {
                // playerturn
                playVoice(5);
                setTimerCountdown(data.timer);
            }
        }
        //--- 추가
        //getPlayerTableList();
    }

    const OnGameStartReceived = (data) => {
        if (data && data.roomId === currentTableId) {
            setGameId(data.gameId);

            //# 게임끝나고 시작 중간에 true가 활성화되서 카드가 없어졌다가 나왔다가 해서 주석처리
            //setGameStart(true);
            
            

            setGameFinishedPlayersCards({});
            setDefaultAction('');
            setSitOut({
                nextBlind: false,
                bigBlind: false
            });
            /*
            위너 여러명 처리
            setWinnerSeatIndex(-1);
            setWinningAmount(-1);
            setWinningType('');
            */
            setWinnerSeatIndex({});
            setWinningAmount({});
            setWinningType({});
            //-- 히든카드초기화
            sethiddenCards({});
            
            //setPlayerCards({});
            
            
        };
    }


    /**
    해당소스는 OnPlayerCards를 emit해서 받는게 아니고 
    subscribeRoom, reconnectGame, newRoundStarted, turnFinished
    했을때 받을수있음
    
    그래서 currentTableId 이값이 필요없는듯함
    **/
    const OnPlayerCardsReceived = (data) => {
        if (data/* && data.roomId === currentTableId*/) {
            setPlayerCards(data);
            if(data.hand && document.querySelector('.player_rank'))
            {
                document.querySelector('.player_rank').innerText = data.hand;   

            }
            
            if(data.isFold == true)
            {
                setDefaultAction("isFold")
            }
            else if(data.isCheck == true)
            {
                setDefaultAction("isCheck")
            }
            else if(data.isCall == true)
            {
                setDefaultAction("isCall")
            }
        }
    }

    const setSeatPositionCss = (seat) => {
        if (seat == 0) {
            setTableSeats([5, 6, 7, 8, 0, 1, 2, 3, 4])
        }
        if (seat == 1) {
            setTableSeats([6, 7, 8, 0, 1, 2, 3, 4, 5])
        }
        if (seat == 2) {
            setTableSeats([7, 8, 0, 1, 2, 3, 4, 5, 6])
        }
        if (seat == 3) {
            setTableSeats([8, 0, 1, 2, 3, 4, 5, 6, 7])
        }
        if (seat == 4) {
            setTableSeats([0, 1, 2, 3, 4, 5, 6, 7, 8])
        }
        if (seat == 5) {
            setTableSeats([1, 2, 3, 4, 5, 6, 7, 8, 0])
        }
        if (seat == 6) {
            setTableSeats([2, 3, 4, 5, 6, 7, 8, 0, 1])
        }
        if (seat == 7) {
            setTableSeats([3, 4, 5, 6, 7, 8, 0, 1, 2])
        }
        if (seat == 8) {
            setTableSeats([4, 5, 6, 7, 8, 0, 1, 2, 3])
        }
    }

    const getFillCss = (seat) => {
        return tableSeats.indexOf(seat) + 1;
    }
    
    
    const getCardTypeKorean = (Type) => {
        let convertkorean = "";
        const handType = Type.toLowerCase(); // Convert to lowercase once
    
        if (handType === "high card") {
            convertkorean = "하이카드";
        } else if (handType === "pair") {
            convertkorean = "원페어";
        } else if (handType === "two pair") {
            convertkorean = "투페어";
        } else if (handType === "three of a kind") {
            convertkorean = "트리플";
        } else if (handType === "straight") {
            convertkorean = "스트레이트";
        } else if (handType === "flush") {
            convertkorean = "플러쉬";
        } else if (handType === "full house") {
            convertkorean = "풀하우스";
        } else if (handType === "four of a kind") {
            convertkorean = "포카드";
        } else if (handType === "straight flush") {
            convertkorean = "스트레이트 플러쉬";
        } else if (handType === "royal flush") {
            convertkorean = "로얄 스트레이트 플러쉬";
        } else {
            convertkorean = "Unknown hand type"; // Optional: handle unknown values
        }
        return convertkorean;
    }
    
    const getWinningClassName = (wname) => {
      let wcname = "";
    
      if (wname === "하이카드") {
          wcname = "highcard";
      } else if (wname === "원페어") {
          wcname = "pair";
      } else if (wname === "투페어") {
          wcname = "twopair";
      } else if (wname === "트리플") {
          wcname = "threeofakind";
      } else if (wname === "스트레이트") {
          wcname = "straight";
      } else if (wname === "플러쉬") {
          wcname = "flush";
      } else if (wname === "풀하우스") {
          wcname = "fullhouse";
      } else if (wname === "포카드") {
          wcname = "fourofakind";
      } else if (wname === "스트레이트 플러쉬") {
          wcname = "straightflush";
      } else if (wname === "로얄 스트레이트 플러쉬") {
          wcname = "royalflush";
      } else {
          wcname = "unknown"; // Optional: handle unknown values
      }
      
      return wcname;
    };

    
	const checkPlayerSeat = (player, seat) => {
        let seatIndex = playerInfo.find((playerEle) => {
            return playerEle.seatIndex === (seat);
        });

        if (seatIndex != undefined) {
            if (seatIndex.id == player.id) {
                return true;
            }
        }
        return false;
    }
    const checkPlayerRank = () =>{
        if(gameStart == true && document.querySelector('.player_rank'))
        {
            const item = document.querySelector('.player_rank').innerText;
            if(item && item != '')
                return true;
        }
        return false;
    }
    const checkPlayerBetAmount = () => {
        const item = playerInfo.find(element => element.id === playerData.playerId);
        if(item && item.BetAmount > 0)
            return true;
        return false;
    }

    const checkPlayerIsSeated = () => {
        const player = playerInfo.find(p => p.id === playerData.playerId);
        if (player) {
          return true;
        } else {
          return false;
        }
    }
    const checkSeatAvailable = (seat) => {
        let seatIndex = playerInfo.find((playerEle) => {
            return playerEle.seatIndex === (seat);
        });
        if (seatIndex != undefined) {
            return false;
        } else {
            return true;
        }
    }

    const selectBuyIn = (seat) => {
        const mainSocket = io(URL,{
          transports: ['websocket','polling']
        });
        const data = {
            playerId: playerData.playerId,
            roomId: tableId,
            latitude: 0,
            longitude: 0,
            productName: 'PokerHills'
        }
        mainSocket.emit('GetBuyinsAndPlayerchips', data, (res) => {
            if (res.result) {
                setTableData(res.result);
                setMinBuyIn(res.result.minBuyIn)
                if (res.result.maxBuyIn > 0) {
                    setMaxBuyIn(res.result.maxBuyIn)
                } else {
                    setMaxBuyIn(playerData.chips)
                }
                setStart(res.result.minBuyIn);
                setIsRoomModalOpen(true);
                setSeatPosition(seat);
                setSliderPct(0);
            }
        });
    }

    const sitOnTable = (type) => {
        const data = {
            playerId: playerData.playerId,
            roomId: tableId,
            chips: start,
            seatIndex: seatPosition,
            latitude: 0,
            longitude: 0,
            productName: 'PokerHills'
        }
        if (type == 0) {
            socketIo.emit('JoinRoom', data, (res) => {
                if (res.result) {
                    setIsRoomModalOpen(false);
                    setEmptyTable(false);
                    setGameData(res.result);
                    getPlayerTableList();
                } else {
                        toast.error(res.message, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            });
        }
        if (type == 1) {
            socketIo.emit('PlayerAddChips', data, (res) => {
                if (res.result) {
                    setIsRoomModalOpen(false);
                    setBuyIn(false);
                } else {
                        toast.error(res.message, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            });
        }
        
        setIsOnOpenBuyInPanel(false);
    }

    const closeModal = () => {
        setIsOnOpenBuyInPanel(false);
        setIsRoomModalOpen(false);
        setIsLogoutModalOpen(false);
        setSendGiftModal(false);
    }

    function onStartChange(val) {
        setStart(val);
    }
    
    const leaveGameNow = () => {
        const data = {
            playerId: playerData.playerId,
            roomId: tableId,
            latitude: 0,
            longitude: 0,
            productName: 'PokerHills'
        }
        socketIo.emit('LeaveRoom', data, (res) => {
            if (socketIo) {
                socketIo.disconnect();
            }
            setSocketIo('');
            toast.success("Leaving the Game...", {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            navigate('/lobby');
        })
    }
    const leaveGame = () => {
        if (Object.keys(gamePlayData).length === 0) {
            // 홀덤게임003 이슈 혼자있을때(경기진행중이아닐때) 바로나가기
            leaveGameNow();
        } else {
            RoomOutNextHand();
        }
        return;
        const data = {
            playerId: playerData.playerId,
            roomId: tableId,
            latitude: 0,
            longitude: 0,
            productName: 'PokerHills'
        }
        socketIo.emit('LeaveRoom', data, (res) => {
            setSocketIo('')
            toast.success("Leaving the Game...", {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            navigate('/lobby');
        })
    }

    const defaultActionSelection = (e, type) => {
        let option = ''
        if (type == 0) {
            if (e.target.checked == true) {
                option = 'isFold'
            } else {
                option = 'removeIsFold'
            }
        }
        if (type == 1) {
            if (e.target.checked == true) {
                option = 'isCheck'
            } else {
                option = 'removeIsCheck'
            }
        }
        if (type == 2) {
            if (e.target.checked == true) {
                option = 'isCall'
            } else {
                option = 'removeIsCall'
            }
        }
        setDefaultAction(option)
        const data = {
            playerId: playerData.playerId,
            roomId: tableId,
            option: option,
            latitude: 0,
            longitude: 0,
            productName: 'PokerHills'
        }
        socketIo.emit('DefaultActionSelection', data, (res) => { });
    }
    
    const regSitOutNext = () => {
        let data = {
            nextHand: true,
            bigBlind: false
        }

        setSitOut(data)
        const SitOutNextHandData = {
            playerId: playerData.playerId,
            roomId: tableId,
            actionValue: data.nextHand,
            latitude: 0,
            longitude: 0,
            productName: 'PokerHills'
        }

        socketIo.emit('SitOutNextHand', SitOutNextHandData, (res) => { });
    }
    
	const sitOutCheckbox = (e, type) => {
        let data = {
            nextHand: e.target.checked,
            bigBlind: false
        }
        if (type == 1) {
            data = {
                nextHand: false,
                bigBlind: e.target.checked
            }
        }
        setSitOut(data)
        const SitOutNextHandData = {
            playerId: playerData.playerId,
            roomId: tableId,
            actionValue: data.nextHand,
            latitude: 0,
            longitude: 0,
            productName: 'PokerHills'
        }
        const SitOutNextBigBlindData = {
            playerId: playerData.playerId,
            roomId: tableId,
            actionValue: data.bigBlind,
            latitude: 0,
            longitude: 0,
            productName: 'PokerHills'
        }
        socketIo.emit('SitOutNextBigBlind', SitOutNextBigBlindData, (res) => { });
        socketIo.emit('SitOutNextHand', SitOutNextHandData, (res) => { });
    }
    const RoomOutNextHand = () => {
        setIsLogoutModalOpen(false)
        let setLogoutModal = true;
        if(playerInfo.length>=1)
        {
            playerInfo.map((player, index) => {
                if(player.id==playerData.playerId && (player.status=='Playing' || player.status=='Fold'))
                {
                    if( (gameStart==false && player.BetAmount > 0) || gameStart==true)
                    {
                        setRoomOut(!roomOut);
                        setLogoutModal = false;
                    }
                }
            })
        }

        if(setLogoutModal==true)
        {
            const data = {
                playerId: playerData.playerId,
                roomId: tableId,
                latitude: 0,
                longitude: 0,
                productName: 'PokerHills'
            }
            socketIo.emit('LeaveRoom', data, (res) => {
                setSocketIo('')
                toast.success("Leaving the Game...", {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                navigate('/lobby');
            })
        }
    }
        
    const countChips = (amount,type) => {
        const chipSetting = {
            "chip_1000000": 1000000,
            "chip_100000": 100000,
            "chip_10000": 10000,
            "chip_1000": 1000,
            "chip_100": 100
        };
    
        const result = {};
    
        for (const [key, value] of Object.entries(chipSetting)) {
            result[key] = Math.floor(amount / value);
            amount %= value;
        }
        
        const columns = [];
        
        for (const [key, count] of Object.entries(result)) {
            if (count > 0) {
                let chips = [];
        
                for (let i = 0; i < count; i++) {
                    chips.push(
                        <div className="chip c-5" key={`${key}_${i}`}>
                            <div
                                className="chip-image"
                                style={{ backgroundImage: `url(../Images/Chip/${key}.png?v=001)` }}
                            ></div>
                        </div>
                    );
        
                    // 5개마다 새로운 column을 생성
                    if (chips.length === 10) {
                        if(type=='total')
                        {
                            columns.push(
                                <div className="columns">
                                    <div className="column col" key={`${key}_group_final`}>
                                        {chips}
                                    </div>
                                </div>
                            );
                        }
                        else
                        {
                            columns.push(
                                <div className="column col" key={`${key}_group_${i / 5}`}>
                                    {chips}
                                </div>
                            );
                        }
                        chips = []; // chips 배열 초기화
                    }
                }
        
                // 남은 칩들이 5개 미만일 경우 마지막으로 column 추가
                if (chips.length > 0) {
                    
                    if(type=='total')
                    {
                        columns.push(
                            <div className="columns">
                                <div className="column col" key={`${key}_group_final`}>
                                    {chips}
                                </div>
                            </div>
                        );
                    }
                    else
                    {
                        columns.push(
                            <div className="column col" key={`${key}_group_final`}>
                                {chips}
                            </div>
                        );
                    }
                }
            }
        }
        
        return columns;
    }
      
    const renderChips = (playerSidePot) => {
        const chips = [];
        
        const chipImagePrimary = { backgroundImage: "url(../Images/chips-primary.png)" };
        const chipImageSecondary = { backgroundImage: "url(../Images/chips-secondary.png)" };
        
        const createChip = (imageStyle) => (
            <div className="chip u-5">
                <div className="chip-image" style={imageStyle}></div>
            </div>
        );
    
        let chipGroup = [];
    
        for (let i = 10000; i <= playerSidePot; i += 10000) {
    
            if (i <= 30000 || (i > 60000 && i <= 90000) || (i > 120000 && i <= 150000)) {
                // Primary chips rendering
                chipGroup.push(createChip(chipImagePrimary));
                if (i > 10000 && i % 20000 === 0) {
                    chipGroup.push(createChip(chipImagePrimary));
                }
            } else if (i > 30000 && i <= 60000 || (i > 90000 && i <= 120000) || i > 150000) {
                // Secondary chips rendering
                chipGroup.push(createChip(chipImageSecondary));
                if (i > 40000 && i % 20000 === 0) {
                    chipGroup.push(createChip(chipImageSecondary));
                }
            }
    
            // Only push a new column when i is a multiple of 30 (0, 30, 60, 90, etc.)
            if (i % 30000 === 0) {
                chips.push(
                    <div className="columns" key={`chips-${i}`}>
                        <div className="column col0">
                            {chipGroup}
                        </div>
                    </div>
                );
                chipGroup = [];  // Reset chip group for the next batch
            }
        }
    
        // Add any remaining chips that didn't fit into a 30 multiple
        if (chipGroup.length > 0) {
            chips.push(
                <div className="columns">
                    <div className="column col0">
                        {chipGroup}
                    </div>
                </div>
            );
        }
    
        return <>{chips}</>;
    };
    
	const SitOutNextHandCheckbox = (e, type) => {
        let data = {
            nextHand: e.target.checked,
            bigBlind: false
        }
        setSitOut(data)
        
        const SitOutNextHandData = {
            playerId: playerData.playerId,
            roomId: tableId,
            actionValue: data.nextHand,
            latitude: 0,
            longitude: 0,
            productName: 'PokerHills'
        }

        socketIo.emit('SitOutNextHand', SitOutNextHandData, (res) => { });
    }
    
	const SitOutNextBigBlindCheckbox = (e, type) => {
        let data = {
            nextHand: false,
            bigBlind: e.target.checked
        }
        setSitOut(data)

        const SitOutNextBigBlindData = {
            playerId: playerData.playerId,
            roomId: tableId,
            actionValue: data.bigBlind,
            latitude: 0,
            longitude: 0,
            productName: 'PokerHills'
        }
        socketIo.emit('SitOutNextBigBlind', SitOutNextBigBlindData, (res) => { });
    }
    
    const CardViewAction = (action) => {
        const data = {
            playerId: playerData.playerId,
            action: action,
            roomId: tableId,
            productName: 'PokerHills'
        }
        setIsCardView(false);
        setIsShowdownShown(false);
        socketIo.emit('CardViewAction', data, (res) => {
            setIsPanelOpen(false);
        });
    }

    const PlayerAction = (action, hasRaised, amount) => {
        if (action == 6) {
            // let playerCardsOld = playerCards;
            // playerCardsOld.flipAnimation = false
            // setPlayerCards(playerCardsOld)
            // playVoice(2)
        } else {
            // if (action == 2) {
            //     playVoice(4)
            // } else {
            //     playVoice(1);
            // }
        }
        const data = {
            playerId: playerData.playerId,
            betAmount: amount,
            action: action,
            roomId: tableId,
            hasRaised: hasRaised,
            productName: 'PokerHills'
        }

        socketIo.emit('PlayerAction', data, (res) => {
            setIsPanelOpen(false);
        });
        
        setTimerCountdown(0);
        // setRaisePct(0);
    }

    const getActionName = (actionData) => {
        const action = actionData.action;
        const hasRaised = actionData.hasRaised;
        if (action == 0) {
            /* SmallBlind */
            return 'SmallBlind';
        } else if (action == 1) {
            /* BigBlind */
            return 'BigBlind';
        } else if (action == 2) {
            /* Check */
            return 'Check';
        } else if (action == 3) {
            if (hasRaised == true) {
                /* Raise */
                return 'Raise';
            } else {
                /* Bet */
                return 'Bet';
            }
        } else if (action == 4) {
            /* Call */
            return 'Call';
        } else if (action == 6) {
            /* Fold */
            return 'Fold';
        } else if (action == 7) {
            /* Timeout */
            return 'Timeout';
        } else if (action == 8) {
            /* Allin */
            return 'Allin';
        } else {
            return ''
        }
    }
    
	const raiseAmountChange = (value) => {
	    const calcPct = ((value - gamePlayData.buttonAction.minRaise) / (gamePlayData.buttonAction.maxRaise - gamePlayData.buttonAction.minRaise)) * 100;
        /**
        마지막단위에서 소수점 추가
        **/	    
	    if(value > gamePlayData.buttonAction.maxRaise-1 && !Number.isInteger(gamePlayData.buttonAction.maxRaise))
	    {
	        document.querySelector('.raiseRangeSlide').setAttribute('step',0.01);
	    }
	    else
	    {
	        document.querySelector('.raiseRangeSlide').setAttribute('step',Number(smallBlindChips));
	    }
        setRaiseAmount(value);
        setRaisePct(Math.round(calcPct));
    }
    {/*
    const sliderPercent = (value) => {
        const min = gamePlayData.buttonAction.minRaise;
        const max = gamePlayData.buttonAction.maxRaise;
        return ((value - min) / (max - min)) * 100;
    };
    
    const sliderStyle = {
        "--percent": `${sliderPercent(raiseAmount)}%`,
    };
    */}
	const getCard = (position) => {
        if (position == 0) {
            return `../Images/Deck/Back/${tableThemeBackDeck}.png`
        }
        if (playerCards && playerCards.cards && playerCards.cards.length > 0) {
            const Card1 = playerCards.cards[0];
            const Card2 = playerCards.cards[1];
            const Card3 = playerCards.cards[2];
            const Card4 = playerCards.cards[3];
            if (position == 1) {
                return '../Images/Deck/Front/' + tableThemeFrontDeck + '/' + Card1 + '.png'
            }
            if (position == 2) {
                return '../Images/Deck/Front/' + tableThemeFrontDeck + '/' + Card2 + '.png'
            }
            if (position == 3) {
                return '../Images/Deck/Front/' + tableThemeFrontDeck + '/' + Card3 + '.png'
            }
            if (position == 4) {
                return '../Images/Deck/Front/' + tableThemeFrontDeck + '/' + Card4 + '.png'
            }
        } else {
            return `../Images/Deck/Back/${tableThemeBackDeck}.png`
        }
    }
    
    const getMultiCard = (card) => {
        if(card)
        {
            return '../Images/Deck/Front/' + tableThemeFrontDeck + '/' + card + '.png'
        }
        else
        {
            return `../Images/Deck/Back/${tableThemeBackDeck}.png`
        }
    }

    const sendPlayerOnline = () => {
        const data = {
            playerId: playerData.playerId,
            roomId: tableId,
            latitude: 0,
            longitude: 0,
            productName: 'PokerHills'
        }
        if(document.querySelector('.variable').getAttribute('data-isTournament')==="true" === true)
        {
        }
        else
        {
            setPlayerCards({});
        }
        socketIo.emit('PlayerOnline', data, (res) => {
        });
    }

    const openSideBar = () => {
        // // console.log("click");
        if (isMobile) {
            setSidebarWidth({ width: '100%' });
        } else {
            setSidebarWidth({ width: '20.5%' });
        }
    }

    const openInfoSideBar = () => {
        // // console.log("click");
        /*
        if (isMobile) {
            // setInfoSidebarWidth({ width: '100%', opacity: 1, padding: '8px' });
            setInfoSidebarWidth({ opacity: 1, padding: '8px', transform: 'translateX(0)' });
        } else {
            setInfoSidebarWidth({ opacity: 1, padding: '8px', transform: 'translateX(0)' });
        }
        */
        setInfoSidebarWidth({ padding: '8px', transform: 'translateX(0)' });
    }

    const closeSideBar = () => {
        setSidebarWidth({ width: '0%' });
    }

    const closeInfoSideBar = () => {
        setInfoSidebarWidth({ padding: '8px', transform: 'translateX(100%)' });
    }

    const sendMessage = () => {
        const messageData = {
            playerId: playerData.playerId,
            username: playerData.username,
            roomId: tableId,
            message: userMessage,
            productName: 'PokerHills'
        }
        socketIo.emit('Chat', messageData, (res) => {
            if (res.status) {
                setUserMessage('');
                setOpenEmojiBox(false);
            }
        });
    }
    
	const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
          e.preventDefault();
        sendMessage();
      }
    };

    const selectEmoji = (e) => {
        const oldUserMessage = userMessage + e.native;
        setUserMessage(oldUserMessage);
    }

    const cardFlipice = (time) => {
        if(hiddenCards.playing==false)
            return;
        if (time == 0) {
            setTimeout(() => {
                //console.log('allIn','sound','0')
                playVoice(3)
            }, 500)
        }
        if (time == 1) {
            setTimeout(() => {
                //console.log('allIn','sound','1')
                playVoice(3)
            }, 1000)
        }
        if (time == 2) {
            setTimeout(() => {
                //console.log('allIn','sound','2')
                playVoice(3)
            }, 1500)
        }
        if (time == 3) {
            setTimeout(() => {
                //console.log('allIn','sound','3')
                playVoice(3)
            }, 500)
        }
        if (time == 4) {
            setTimeout(() => {
                //console.log('allIn','sound','4')
                playVoice(3)
            }, 500)
        }
    }

    const hidddenCardClassName = (className) => {
        if (className == 'hiddenCard1') {
            setTimeout(() => {
                const hiddenCard1 = document.querySelector('.' + className);
                if (hiddenCard1) {
                    //console.log('allIn','hiddenCard','0')
                    hiddenCard1.classList.add('flipped');
                }
            }, (hiddenCards.playing==false?0:500))
        }
        if (className == 'hiddenCard2') {
            setTimeout(() => {
                const hiddenCard2 = document.querySelector('.' + className);
                if (hiddenCard2) {
                    //console.log('allIn','hiddenCard','1')
                    hiddenCard2.classList.add('flipped');
                }
            }, (hiddenCards.playing==false?0:1000))
        }
        if (className == 'hiddenCard3') {
            setTimeout(() => {
                const hiddenCard3 = document.querySelector('.' + className);
                if (hiddenCard3) {
                    //console.log('allIn','hiddenCard','2')
                    hiddenCard3.classList.add('flipped');
                }
            }, (hiddenCards.playing==false?0:1500))
        }
        if (className == 'hiddenCard4') {
            setTimeout(() => {
                const hiddenCard4 = document.querySelector('.' + className);
                if (hiddenCard4) {
                    //console.log('allIn','hiddenCard','3')
                    hiddenCard4.classList.add('flipped');
                }
            }, (hiddenCards.playing==false?0:500))
        }
        if (className == 'hiddenCard5') {
            setTimeout(() => {
                const hiddenCard5 = document.querySelector('.' + className);
                if (hiddenCard5) {
                    //console.log('allIn','hiddenCard','4')
                    hiddenCard5.classList.add('flipped');
                }
            }, (hiddenCards.playing==false?0:500))
        }
    }

    const addMoreChips = () => {
        setIsOnOpenBuyInPanel(false);
        getReBuyInChips();
    }
    const getReBuyInChips = () => {
        const data = {
            playerId: playerData.playerId,
            roomId: tableId,
            latitude: 0,
            longitude: 0,
            productName: 'PokerHills'
        }
        socketIo.emit('GetPlayerReBuyInChips', data, (res) => {

            if(res.status=='fail')
            {
                setIsBroadCastMessage(true);
                setBroadCastMessage({
                    heading:"",
                    message: res.message === "Can't add more chips" ? "칩을 추가할 수 없습니다" : res.message
                })
                setTimeout(() => {
                    setIsBroadCastMessage(false)
                    setBroadCastMessage({})
                }, 5000)
            }
            if (res.result) {
                setMinBuyIn(res.result.minBuyIn)
                if (res.result.maxBuyIn > 0) {
                    setMaxBuyIn(res.result.maxBuyIn)
                } else {
                    setMaxBuyIn(playerData.chips)
                }
                setStart(res.result.minBuyIn);
                setIsRoomModalOpen(true);
                setTableData(res.result);
                setBuyIn(true);
            }
        });
        setSliderPct(0);
    }

    const isWinningCard = (card) => {
        if (bestCard.length > 0) {
            if (bestCard.includes(card)) {
                return 'win';
            } else {
                return 'lose';
            }
        } else {
            return ''
        }
    }

    const sendGiftToPlayer = (player) => {
        if (player.id != playerData.playerId) {
            const socketUrl = io(URL,{
              transports: ['websocket','polling']
            });
            const data = {
                productName: 'PokerHills',
            }
            socketUrl.emit('GameGift', data, (res) => {
                if (res.status) {
                    setGameGiftPlayer(player);
                    setGameGifts(res.result);
                    setSendGiftModal(true);
                    setGiftForPlayer('');
                }
            });
        }
    }
    const sendGiftToOtherPlayer = () => {
        let mySeatIndex = 0;
        let receriverSeatIndex = gameGiftPlayer.seatIndex;
        playerInfo.map((plr) => {
            if (plr.id == playerData.playerId) {
                mySeatIndex = plr.seatIndex;
            }
        })
        if (playerData.chips > giftForPlayer.amount) {
            const messageData = {
                senderPlayerName: playerData.username,
                receiverPlayerName: gameGiftPlayer.username,
                senderPlayer: playerData.playerId,
                receiverPlayer: gameGiftPlayer.id,
                senderIndex: mySeatIndex,
                receriverSeatIndex: receriverSeatIndex,
                gift: giftForPlayer,
                giftId: giftForPlayer._id,
                roomId: tableId,
                productName: 'PokerHills'
            }

            socketIo.emit('Gift', messageData, (res) => {
                if (res.status) {
                    setSendGiftModal(false);
                }
            });
            // const socketUrl = io(URL);
            // const data = {
            //     senderPlayer: playerData.playerId,
            //     receiverPlayer: gameGiftPlayer.id,
            //     giftId: gift._id,
            //     productName: 'PokerHills',
            // }
            // socketUrl.emit('SendGiftToPlayer', data, (res) => {
            //     if (res.status == "success") {
            //         // console.log(res.result);
            //     } else {
            //         toast.error(res.message, {
            //             position: "top-center",
            //             autoClose: 2000,
            //             hideProgressBar: false,
            //             closeOnClick: true,
            //             pauseOnHover: true,
            //             draggable: true,
            //             progress: undefined,
            //             theme: "dark",
            //         });
            //     }
            // });
        } else {
            toast.error("Influence balance. Can't Send Gift! ", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }
    // const customStyles = {
    //     content: {
    //         top: '50%',
    //         left: '50%',
    //         right: 'auto',
    //         bottom: 'auto',
    //         marginRight: '-50%',
    //         transform: 'translate(-50%, -50%)',
    //         backgroundColor: '#242234',
    //         width: '40%',
    //         height: '50%',
    //         border: 'none'
    //     },
    // };

    const customStylesLogout = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#242234',
            width: '40%',
            height: '50%',
            border: 'none'
        },
    };


    let broadCastMessagePopup = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#242234',
            width: '40%',
            height: '40%',
            boxShadow: '2px 2px 15px #ffd05075, -2px -2px 15px #ffd05075',
            border: '1px solid #ffd050'
        },
    };

    if (isMobile) {
        broadCastMessagePopup = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#242234',
                width: '60%',
                height: '60%',
                boxShadow: '2px 2px 15px #ffd05075, -2px -2px 15px #ffd05075',
                border: '1px solid #ffd050'
            },
        }
    }
    const handleWheel = (event) => {
        const delta = event.deltaY || event.detail || event.wheelDelta;

        if (containerRef.current) {
            containerRef.current.scrollLeft += delta < 0 ? -30 : 30;
        }
    };

    const refreshData = () => {

    }
    const closePopup = () => {
        
        setSwitchLoader(true);

        setTimeout(() => {
            setSwitchLoader(false);
            setIsSettingOpen(false);
            setIsHandOpen(false);
        }, 100);
    }

    const closeMyProfile = () => {
        const socketUrl = io(URL,{
          transports: ['websocket','polling']
        });
        const data = {
            id: tableId,
            playerId: playerData.playerId,
            productName: 'PokerHills'
        }
        socketUrl.emit('TableThemeSettings', data, (res) => {
            if (res.status == 'success') {
                setTableThemeSkinColor(res.result.tableTheme)
                setTableThemeBackgroundColor(res.result.tableBackground)
                setTableThemeFrontDeck(res.result.frontDeck)
                setTableThemeBackDeck(res.result.backDeck)
            }
        });

        setSwitchLoader(true);

        setTimeout(() => {
            setSwitchLoader(false);
            setIsSettingOpen(false);
            setIsHandOpen(false);
        }, 1000);
    }
    
    /*
    함수추가
    */
    const safeToFixed = (value) =>  {
        // 숫자로 변환 가능한지 체크
        let num = parseFloat(value);
        
        // 변환된 값이 유효한 숫자인지 체크
        if (isNaN(num) || !isFinite(num)) {
            //return "0.00"; // 기본값으로 반환
            return "0"; // 기본값으로 반환
        }
        
        // toFixed 사용
        return Number(num).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
        //return num.toFixed(0);
    }
    const footerPanelPlayerStatus = ()=>{
        let playerDataInfo = playerInfo.find((playerEle) => {
            return playerEle.id === playerData.playerId;
        });
        
        if(playerDataInfo)
        {
            if(playerDataInfo.status=='Playing')
                return true;
        }
    }
    const statusText = (player) =>{
        if(player.folded==true && player.status=='Playing')
            return '다이';
            
        switch(player.status)
        {
            case 'Ideal':
                return '대기';
                break;
            case 'Waiting':
                // Wait
                return '대기';
                break;
            case 'Playing':
                return '놀기';
                break;
            case 'Fold':
                return '다이';
                break;
        }
    }
    
    const initFinish=()=>{
        if(document.querySelector('.variable') && document.querySelector('.variable').getAttribute('data-initfinishtimeout')=='Finished')
        {
            
            /*
            위너 여러명 처리
            setWinnerSeatIndex(-1);
            setWinningAmount(-1);
            setWinningType('');
            */
            setWinnerSeatIndex({});
            setWinningAmount({});
            setWinningType({});
            setBestCard([]);
            setGameStart(false);
            sethiddenCards({});
            
            setPlayerCards({})
            //console.log('gameFinishedPlayersCards,initFinish')
            setGameFinishedPlayersCards({});
            setIsRevertPointFolded(false) 
            setIsCardView(false);
            setIsCardViewPlayer({});
            setIsShowdownShown(false);
        }
        setInitFinishTimeout('');
    }
        
    
    const getCurrentTableId = ()=>{
        //console.log('getCurrentTableId',currentTableId,tableId);
    }
    const footerBtnDisabled = (type) =>{

        if(gamePlayData.buttonAction.callAmount <= (tablePot * type))
        {
            return false;
        }
        return "disabled";
    }
    
    const soundOnOff = () =>{
        const data = {
          playerId: playerData.playerId,
          sound:!voice
        }
        socketIoSetting.emit('SoundSettingsUpdate', data, (res) => {
        });
        setVoice(!voice);
    }
    
    const setToMinMaxBuyIn = (val) => {
        setStart(val);
    }
    
    useEffect(() => {

     }, [currentTableId]);
     
    useEffect(() => {
        
        const imageUrlsTemp = [];
        const RankCards = ['2','3','4','5','6','7','8','9','T','K','Q','A','J'];
        const SuitCard = ['S','D','H','C'];
        RankCards.forEach(rank => {
            SuitCard.forEach(suit => {
                imageUrlsTemp.push(`https://poker-hill.com/Images/Deck/Front/${tableThemeFrontDeck}/${rank}${suit}.png`); // 카드 조합 생성
            });
        });
        setImageUrls(imageUrlsTemp);
        setIsImageUrls(true)

        
    }, [tableThemeFrontDeck])
    const handleCacheComplete = () => {
        setIsImageUrls(false)
    };
    /************************/

    return (
        <div>
            {isImageUrls ? (
            <ImageLoader
                imageUrls={imageUrls}
                onComplete={handleCacheComplete}
                placeholder="캐시 저장 중..."
            />):""}
            {isBroadCastMessage ? (
                <div>
                    <Modal
                        isOpen={true}
                        style={broadCastMessagePopup}
                        contentLabel="Example Modal"
                        ariaHideApp={false}
                    >
                        <div className={isMobile ? "gameModel mobileView" : 'gameModel'}>
                            <div className='modalBody'>
                                <center>
                                    <h2 className='color-1'>{broadCastMessage.heading}</h2>
                                    <h4 className='color-1 mt-4'>{broadCastMessage.message}</h4>
                                    <div className='avtarBtnSection'>
                                        <button className='selectBuyInBtn' onClick={() => setIsBroadCastMessage(false)}>{lang.ko.close}</button>
                                    </div>
                                </center>
                            </div>
                        </div>
                    </Modal>
                </div>
            ) : (
                <div>
                </div>
            )}
            {isJackpotMessage ? <Jackpot {...jackpotMessage} /> : ''}

            {isLoader ?
                <div className='loader'>
                    <div className={isMobile ? 'mobileView' : ''}>
                        <img alt="poker" src={'../Images/Logo2.png'} className='loadingLogo'></img>
                    </div>
                    <div className='loading'>
                        <p>Loading...</p>
                    </div>
                    <div className="progress-6"></div>
                </div>
                
                : ''
            }
            
            {/*switchLoader ?
                <div className='switchloader'>
                    <div className='loading'>
                        <p>Loading...</p>
                    </div>
                    <div className="loading-progress-bar"></div>
                </div>
                : ''
            */}
            
            <ToastContainer />
            <div>
                {<div class='variable' data-playerCards={playerCards.cards} data-tableid={tableId} data-socketIoId={gameSocketId} data-initFinishTimeout={initFinishTimeout} data-isTournament={isTournament} data-isRevertPointFolded={isRevertPointFolded} data-gameFinishedCardGameId={gameFinishedCardGameId} data-gameFinishedPlayersCards={JSON.stringify(gameFinishedPlayersCards)} data-isCardViewPlayer={JSON.stringify(isCardViewPlayer)}></div>}
                <div className='fixed-screen poker-root'>
                    <div id="mySidenav" className="chatBar" style={sidebarWidth}>
                        <h4 className='chat-heading'> {lang.ko.table_chat}</h4>
                        <a href="javascript:void(0)" className="sideBarClosebtn" onClick={() => closeSideBar()}>&times;</a>
                        <hr></hr>

                        <section className="msger" style={openEmojiBox ? { height: '30%' } : {}}>
                            <main className="msger-chat" ref={messageEl}>
                                {gameChatMessages?.map((messageData, index) => {
                                    return (
                                        <div className={messageData.playerId == playerData.playerId ? 'msg right-msg' : 'msg left-msg'}>
                                            <div className="msg-bubble">
                                                <div className="msg-info">
                                                    <div className="msg-info-name">{messageData.username}</div>
                                                </div>
                                                <div className="msg-text">{messageData.message}</div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </main>
                        </section>

                        <form className="msger-inputarea">
                            <div className="msger-input-box">
                                {openEmojiBox ?
                                    <button type="button" className="plainBtn headerBtn msger-send-btn" onClick={() => setOpenEmojiBox(false)}>
                                        <img alt="poker" src={'../Images/Icons/SmileClose.png'} />
                                    </button> :
                                    <button type="button" className="plainBtn headerBtn msger-send-btn" onClick={() => setOpenEmojiBox(true)}>
                                        <img alt="poker" src={'../Images/Icons/Smile.png'} />
                                    </button>
                                }
                                <input type="text" className="msger-input" placeholder="메시지를 입력하세요" value={userMessage} onChange={(e) => setUserMessage(e.target.value)} onKeyPress={handleKeyPress}/>
                                {userMessage == '' ?
                                    <button type="button" className="plainBtn headerBtn msger-send-btn">
                                        <img alt="poker" src={'../Images/Icons/Send.png'} />
                                    </button>
                                    :
                                    <button type="button" className="plainBtn headerBtn msger-send-btn" onClick={() => sendMessage()}>
                                        <img alt="poker" src={'../Images/Icons/SendFill.png'} />
                                    </button>
                                }
                            </div>
                            {openEmojiBox ? <div className='msger-emojibox'>
                                <center>
                                    <Picker data={data} onEmojiSelect={selectEmoji} />
                                </center>
                                
                            </div>
                                : ""}
                        </form>

                        <div className='leftChatBox'>
                            <p></p>
                        </div>
                    </div>
                    {/*
                    <div id="mySidenav" className="tableInfoSideBar" style={infoSidebarWidth}>
                        <h4 className='chat-heading'> 테이블 정보</h4>
                        <a href="javascript:void(0)" className="sideBarClosebtn" onClick={() => closeInfoSideBar()}>&times;</a>
                        <hr></hr>
                        <div className="p-3 pt-0">
                            <p><span className='tableInfoTitle'>테이블 이름 : </span> {tableInfo.roomName}</p>
                            <p><span className='tableInfoTitle'>게임타입 : </span> <span style={{ textTransform: "capitalize" }}>{tableInfo.pokerGameType}</span></p>
                            <p><span className='tableInfoTitle'>바이인 : </span> {tableInfo.minBuyIn}</p>
                            <p><span className='tableInfoTitle'>팟 타입 : </span> {tableInfo.gameLimit === "no_limit" ? "무제한" : tableInfo.gameLimit === "limit" ? "리밋" : tableInfo.gameLimit === "pot_limit" ? "팟리밋" : tableInfo.gameLimit}</p>
                            <p><span className='tableInfoTitle'>테이블 스테이크 : </span> {tableInfo.smallBlind}/{tableInfo.bigBlind}</p>
                        </div>
                    </div>
                    */}
                    <div id="mySidenav" className={`tableInfoSideBar ${isMobile ? 'mobile' : ''}`} style={infoSidebarWidth}>
                        <div className="side_header">
                            <img className="side_logo" src={'../Images/Logo2.png'} />
                            <a href="javascript:void(0)" className="sidebar_close" onClick={() => closeInfoSideBar()}>
                                <img src={'../Images/menubtn/x.png'} />
                            </a>
                        </div>
                        <div className="side_menu_btns">
                            <div className="menu-btn setting-btn" onClick={() => {setIsSettingOpen(true);closeInfoSideBar();}}>
                                <img src={'../Images/menubtn/setting.png'} className="menubtn-img inv-col" />
                            </div>
                            {!emptyTable ?
                            <div className="menu-btn addchip-btn" onClick={() => {getReBuyInChips();closeInfoSideBar();}}>
                                <img src={'../Images/menubtn/chip_add.png'} className="menubtn-img" />
                            </div>
                            : ''}
                            <div className="menu-btn handhistory-btn" onClick={() => {setIsHandOpen(true);closeInfoSideBar();}}>
                                <img src={'../Images/menubtn/hnd_history.png'} className="menubtn-img" />
                            </div>
                            <div className="menu-btn sound-btn" onClick={() => {soundOnOff()}}>
                                <img src={`../Images/menubtn/snd_${voice === true ? "on" : "off"}.png`} className="menubtn-img" />
                            </div>
                            <div className="menu-btn exit-btn" onClick={() => {setIsLogoutModalOpen(true);closeInfoSideBar();}}>
                                <img src={'../Images/menubtn/exit.png'} className="menubtn-img inv-col" />
                                {roomOut === true ? (
                                    <div className="exitGame-txt">
                                        <span>나가기<br />예약</span>
                                    </div>
                                 ) : null}
                            </div>
                        </div>
                        <div className="side_table_name">{tableInfo.roomName}</div>
                    	<div className="side_table_info">
                    		<div className="side_info_item">
                    			<div className="tableInfoTitle">{lang.ko.type}</div> 
                    			<div className="tableInfoVal">{tableInfo.pokerGameType}</div>
                    		</div>
                    		<div className="side_info_item">
                    			<div className="tableInfoTitle">{lang.ko.buyin}</div>
                    			<div className="tableInfoVal">{tableInfo.minBuyIn}</div>
                    		</div>
                    		<div className="side_info_item">
                    			<div className="tableInfoTitle">{lang.ko.pot_type}</div>
                    			<div className="tableInfoVal">{tableInfo.gameLimit === "no_limit" ? "무제한" : tableInfo.gameLimit === "limit" ? "리밋" : tableInfo.gameLimit === "pot_limit" ? "팟리밋" : tableInfo.gameLimit}</div>
                    		</div>
                    		<div className="side_info_item">
                    			<div className="tableInfoTitle">{lang.ko.blinds}</div>
                    			<div className="tableInfoVal">{tableInfo.smallBlind}/{tableInfo.bigBlind}</div>
                    		</div>
                    	</div>
                    	{/*
                    	<div className="side_table_history">
                    		<div className="table_hist_title">핸드 히스토리</div>
                    		<table className="shistory_table">
                    			<thead>
                    				<tr>
                    					<th>날짜와 시간</th>
                    					<th>승리금액</th>
                    					<th>핸드</th>
                    				</tr>
                    			</thead>
                    			<tbody>
                    			    <tr>
                    			        <td></td>
                    			        <td></td>
                    			        <td></td>
                    			    </tr>
                    			</tbody>
                    		</table>
                    	</div>
                    	*/}
                    </div>

                    {isSettingOpen ?
                        <TableSetting refreshData={refreshData} closeMyProfile={closeMyProfile}></TableSetting> :
                        ''}
                        
                    {isHandOpen ?
                        <HandHistory refreshData={refreshData} closePopup={closePopup}></HandHistory> :
                        ''}

                    {isRotateScreen ?
                        <div className="page orientation-error-container OrientationErrorContainer">
                            <div className="orientation-error-container-content">
                                <div className="orientation-error-message">Please rotate</div>
                            </div>
                        </div>
                        : ''}
                    {/*
                    <div className={`game-page background-${tableThemeBackgroundColor} poker-alpha ${isMobile ? 'mobile portrait' : 'desktop'}`} >
                    */}
                    <div className={`game-page background-default poker-alpha ${isMobile ? 'mobile portrait' : 'desktop'}`} >
                        <div className='game-container' style={isMobile ? mobileView : {}}>
                            <div className='game-container-elements'>
                                { isShowdownShown && (gameFinishedPlayersCards.playersCards && gameFinishedPlayersCards.playersCards.length > 0)  ?
                                    <div className="showdown">
                                    	<div className="sd_text">
                                            <div className="shw_txt"><img src={'../Images/showdown/sd1.png'} alt="show"/></div>
                                            <div className="dwn_txt"><img src={'../Images/showdown/sd2.png'} alt="down"/></div>    
                                        </div>
                                        
                                        <div className="lighteffect1"><img src={'../Images/showdown/light_02.png'} alt=""/></div>
                                        <div className="lighteffect4"><img src={'../Images/showdown/oval.png'} alt=""/></div>
                                    </div>
                                : '' }
            
                                {isMobile ?
                                    <div className='r-scene-container'>
                                        <div className='scene-container-content'>
                                            <div className="scene-background-table">
                                                <div className="panel table-shadow skin-section" tabindex="-1"></div>
                                                <div className="background-table-border skin-section theme-default"></div>
                                                {/*
                                                <div className={`background-table-felt skin-section felt-${tableThemeSkinColor}`}></div>
                                                */}
                                                <div className="background-table-felt skin-section felt-default"></div>
                                            </div>
                                        </div>
                                    </div>
                                    : ''}
                                {/*    
                                <div className="bank-container">
                                    {
                                    tableSeats?.some(seat => 
                                        playerInfo.length > 0 
                                          ? (!checkPlayerIsSeated() && checkSeatAvailable(seat)) 
                                          : true
                                    ) ? (
                                        <div className="sitdown-pls">
                                          <div className="sitdown-txt">자리를 선택하세요</div>
                                        </div>
                                    ) : (
                                        <div className="bank-container-content">
                                            판돈: {gameStart == true && gamePlayData.roomId? safeToFixed(tablePot) : '0'}
                                        </div>
                                    )
                                    
                                    }

                                </div>
                                */}
                                <div className="TableHeaderContainer TableHeaderContainer_v_default TableHeaderContainer_c_dark">
                                    <div className={`TableHeaderContainer__content ${playerMultiTable.length > 3 ? "max-multitbl" : ""}`}>
                                        {/*
                                        <div className="TableHeaderContainer__header_buttons TableHeaderContainer__left_buttons">
                                            <div className="panel button SimpleButton SimpleButton_v_transparent SimpleButton_c_light SimpleButton_use_icon TableHeaderContainer__button TableHeaderContainer__exit_button SimpleButton_interactive" tabindex="-1" onClick={() => setIsLogoutModalOpen(true)} >
                                                <div className="SimpleButton__content">
                                                    <div className="panel Icon Icon_v_default Icon_c_white Icon_val_table-menu-leave SimpleButton__icon" tabindex="-1">
                                                        <div className="Icon__content">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        */}
                                        <div className="TableHeaderContainer__table_router_holder">
                                            <div className="table-router-container">
                                                <div className="scroll-panel" style={{ position: "relative", height: "100%", width: "100%", touchAction: "none" }}>
                                                    
                                                    
                                                    <div className="scroll-wrapper" onWheel={handleWheel} ref={containerRef}>
                                                        {playerMultiTable.length < 4 ? (
                                                        <div className="add-table-button" tabindex="-1" onClick={() => addNewTable()}></div>
                                                        ) : ''
                                                        }
                                                        {playerMultiTable?.map((table, key) => {
                                                            if (table.roomId == tableId) {
                                                                //roomSettingInit(table);
                                                                return (
                                                                    <div className="panel TableIndicator TableIndicator_v_transparent TableIndicator_c_gray TableIndicatorContainer TableIndicatorContainer_v_default TableIndicatorContainer_c_dark table-indicator active" tabindex="-1">
                                                                        <div className="prv-wrapper">
                                                                            <div className="TableIndicator__content">
                                                                                
                                                                            {table.room.players?.map((playerC, index) => {
                                                                                if(playerC.id == playerData.playerId)
                                                                                {
                                                                                    if(playerC.status!='Playing' || playerC.folded==true)
                                                                                    {
                                                                                        return (
    																						
                                                                                              <div className="player-status">{statusText(playerC)} </div>
                                                                                              
    																					)
                                                                                    }
                                                                                    else if(checkPlayerRank() && playerC.status=='Playing')
                                                                                    {
                                                                                        if(playerC.cards.length > 0 && playerCards && playerCards.cards && playerCards.cards.length>0 && playerCards.cards[0]==playerC.cards[0]  && playerCards.cards[1]==playerC.cards[1]) {
        																				    //currentTableCardMultitable(playerC)
        																					return (
        																						<div className={`playcards-prv bb ${index === table.room.currentPlayer ? '' : ''}`}>
        																							<div key={index} className="cardsprv-wrap">
        																								<div className="card-face" style={{ backgroundImage: `url('../Images/Deck/cardprv/darkbg/${playerC.cards[0]}.png?v=002')` }}></div>
        																								<div className="card-face" style={{ backgroundImage: `url('../Images/Deck/cardprv/darkbg/${playerC.cards[1]}.png?v=002')` }}></div>
        																							</div>
        																						</div>
        																					)
        																				} 
                                                                                    }
                                                                                    else if (playerC.status =='Playing' && playerC.cards.length == 0){
    																				    return (
                                                                                          <div className="player-status pl">{statusText(playerC)} </div>
																					    )
    																				}
                                                                                }
    																		})}
    																		{table.room.waitingPlayers?.map((playerC,index)=>{
    																		    if(playerC.id == playerData.playerId)
                                                                                {
                                                                                    return (
                                                                                    
																						<div className="player-status">{lang.ko.wait}</div>
    																				
																					)
                                                                                }
    																		})}
    																		</div>
    																		<div className="tbl-total-user">{table.playerCount}</div>
    																		<div className="prv-tblname" data-start={gameStart}>
    																		    { truncateRoomName(table.roomName) }
                                                                            </div>
    																	</div>
                                                                    </div>
                                                                )
                                                            } else {
                                                                return (
                                                                    <div className="panel TableIndicator TableIndicator_v_transparent TableIndicator_c_gray TableIndicatorContainer TableIndicatorContainer_v_default TableIndicatorContainer_c_dark table-indicator inactive" tabindex="-1" onClick={() => changeTableData(table.roomId)}>
                                                                        <div className="prv-wrapper">
                                                                            <div className={`TableIndicator__content`}>
                                                                            {table.room.players?.map((playerC, index) => {
    																			if(playerC.id == playerData.playerId) {
    																			    if(playerC.status!='Playing' || playerC.folded==true)
    																			    {
    																			        return (
    																						<div className="player-status">{statusText(playerC)}</div>
    																					)
    																			    }
    																				else if(playerC.cards.length > 0) {
    																					return (
    																						<div className={`playcards-prv aa ${gameStart} ${index === table.room.currentPlayer ? 'playerTurn' : ''}`}>
    																							<div key={index} className="cardsprv-wrap">
    																								<div className="card-face" style={{ backgroundImage: `url('../Images/Deck/cardprv/darkbg/${playerC.cards[0]}.png?v=002')` }}></div>
    																								<div className="card-face" style={{ backgroundImage: `url('../Images/Deck/cardprv/darkbg/${playerC.cards[1]}.png?v=002')` }}></div>
    																							</div>
    																						</div>
    																					)
    																				} else {
    																				    return (
    																						
                                                                                              <div className="player-status">{statusText(playerC)}</div>
                                                                                              
    																					)
    																				}
    																			}
    																		})}
    																		{table.room.waitingPlayers?.map((playerC,index)=>{
    																		    if(playerC.id == playerData.playerId)
                                                                                {
                                                                                    return (
																						<div className="player-status">{lang.ko.wait}</div>
																					)
                                                                                }
    																		})}
    																		</div>
    																		<div className="tbl-total-user">{table.playerCount}</div>
    																		<div className="prv-tblname">
                                                                                {/* table.roomName.length > 10 ? table.roomName.slice(0, 8) + '...' : table.roomName */}
                                                                                { truncateRoomName(table.roomName) }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        })}

                                                    </div>
                                                
                                                    
                                                    {/*
                                                    
                                                    <div className="panel button add-table-button" tabindex="-1" onClick={() => addNewTable()}>
                                                        <div className="button-content"></div>
                                                    </div>
                                                    
                                                    */}
                                                </div>
                                            </div>
                                        </div>
                                        {/*
                                        <div className="TableHeaderContainer__header_buttons TableHeaderContainer__right_buttons">
                                            <div className="menuItem" onClick={() => soundOnOff()}>
                                                
                                                {voice==true?"SOUND O":"SOUND X"}
                                            </div>
                                        </div>
                                        <div className="TableHeaderContainer__header_buttons TableHeaderContainer__right_buttons">
                                            <div className="menuItem" onClick={() => setIsLogoutModalOpen(true)}>
                                                <div className="menu_top_close-icon"></div>
                                            </div>
                                        </div>
                                        */}
                                        <div className="TableHeaderContainer__header_buttons TableHeaderContainer__right_buttons" ref={dropdownRef}>
                                            {/*
                                            <div className="menuItem menu_top_close" style={{ zIndex: 1 }} onClick={() => setIsLogoutModalOpen(true)}>
                                                <div className={`menu_top_close-icon ${roomOut === true ? "exit-on" : "exit-off"}`}></div>
                                                {roomOut === true ? (
                                                    <div className="exitGame-txt">
                                                        <span>나가기<br />예약되었습니다.</span>
                                                    </div>
                                                ) : null}
                                            </div>
                                            */}
                                            {hourSubscription ?
                                                <div className='playerLeftHours'>
                                                    {/* <button type='button' className='plainBtn' data-title='Buy Hours' ><img alt="poker" src={'../Images/Icons/PlusBox.png'} /></button> */}
                                                    <div className='d-flex align-item-center'>
                                                        <img alt="poker" className="timeIcon" src={'../Images/Icons/Timer.png'} />
                                                        <span>{playerData ? playerData.hour_balance : '00.00'}</span>
                                                    </div>
                                                </div>
                                                : ''}
                                            
                                            <div className={`panel button SimpleButton SimpleButton_v_transparent SimpleButton_c_light SimpleButton_use_icon TableHeaderContainer__button TableHeaderContainer__menu_button SimpleButton_interactive ${isMenuOpen ? 'ddOpened' : 'ddClosed' }`} tabindex="-1" onClick={() => openInfoSideBar()}>
                                            {/*
                                            isMenuOpen && (
                                                <div className="sideDropdownMenu">
                                                    <div className="menuItem" onClick={() => openInfoSideBar()}>
                                                        <div className="panel button SimpleButton SimpleButton_v_transparent SimpleButton_c_light SimpleButton_use_icon TableHeaderContainer__button TableHeaderContainer__info_button SimpleButton_interactive" tabindex="-1" >
                                                            <div className="SimpleButton__content">
                                                                <div className="panel Icon Icon_v_default Icon_c_white Icon_val_table_info SimpleButton__icon" tabindex="-1">
                                                                    <div className="Icon__content">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="sideBtn-text">테이블</div>
                                                    </div>

                                                    {!emptyTable ?
                                                    <div className="menuItem" onClick={() => getReBuyInChips()}>
                                                        <div className="panel button SimpleButton SimpleButton_v_transparent SimpleButton_c_light SimpleButton_use_icon TableHeaderContainer__button TableHeaderContainer__add_money_button SimpleButton_interactive" tabindex="-1">
                                                            <div className="SimpleButton__content">
                                                                <div className="panel Icon Icon_v_default Icon_c_white Icon_val_add_money SimpleButton__icon" tabindex="-1">
                                                                    <div className="Icon__content"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="sideBtn-text">칩 추가</div>
                                                    </div> : ''}
                                                    
                                                    <div className="menuItem" onClick={() => setIsSettingOpen(true)}>
                                                        <div className="panel button SimpleButton SimpleButton_v_transparent SimpleButton_c_light SimpleButton_use_icon TableHeaderContainer__button TableHeaderContainer__settings_button SimpleButton_interactive" tabindex="-1">
                                                            <div className="SimpleButton__content">
                                                                <div className="panel Icon Icon_v_default Icon_c_white Icon_val_table_settings SimpleButton__icon" tabindex="-1">
                                                                    <div className="Icon__content">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="sideBtn-text">설정</div>
                                                    </div>
                                                    
                                                    <div className="menuItem" onClick={() => setIsLogoutModalOpen(true)}>
                                                        <div className="panel button SimpleButton SimpleButton_v_transparent SimpleButton_c_light SimpleButton_use_icon TableHeaderContainer__button TableHeaderContainer__exit_button SimpleButton_interactive" tabindex="-1">
                                                            <div className="SimpleButton__content">
                                                                <div className="panel Icon Icon_v_default Icon_c_white Icon_val_table-menu-leave SimpleButton__icon" tabindex="-1">
                                                                    <div className="Icon__content">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="sideBtn-text">나가기{roomOut==true?" 취소":""}</div>
                                                    </div>
                                                </div>
                                            )
                                            */
                                            }
                                            </div>

                                        </div>
                                        <div className="Sidebar Sidebar_v_table Sidebar_c_blue TableSidebarsContainer TableSidebarsContainer_v_default TableSidebarsContainer_c_dark swiper-no-swiping right">
                                            <div className="panel Sidebar__outer" tabindex="-1">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {playerGiftData && playerGiftData.receriverSeatIndex ?
                                    <div className='text-center'>
                                        <div className='gifting-msg'>
                                            <h4><img src={BackendURL + 'gameGift/' + playerGiftData.gift.image}></img> {playerGiftData.senderPlayerName} Send {playerGiftData.gift.name} To {playerGiftData.receiverPlayerName} </h4>
                                        </div>
                                    </div>
                                    : ''}
                                <div className={isMobile ? 'poker-game game-container' : 'p-game-wrapper  game-container poker-game max-cards-5 scale-cards'} >
                                    {
                                        currentTableId == '' ?(
                                        <div className="table-loading" style={{ width: "100%", height: "120%", top: "-10%", position:"absolute", zIndex:"100",background:"black",display:"flex",justifyContent:"center",alignItems:"center",color:"white",fontSize:"2rem",fontWeight:"bold",opacity:"0.3" }}>
                                            테이블 정보를 받아오는 중입니다..
                                        </div>)
                                        :socketIoConnect == false ?(
                                        <div className="table-loading" style={{ width: "100%", height: "120%", top: "-10%", position:"absolute", zIndex:"100",background:"black",display:"flex",justifyContent:"center",alignItems:"center",color:"white",fontSize:"2rem",fontWeight:"bold",opacity:"0.3" }}>
                                            소켓 재연결 중입니다
                                        </div>)
                                        :""
                                    }
                                    <div className={isMobile ? 'r-scene-game-wrapper panel seats-count-8 theme-default background-5 felt-1 card-back-default cs-normal' : 'r-scene-container panel seats-count-8 theme-default background-5 felt-1 card-back-default cs-normal'}>
                                        <div className='p-scene-game-table' >
                                            <div className='p-scene-game-table-cell' >
                                                <div className='p-game-area' style={tableImage}>
                                                    <div className='background-table'>
                                                        {isMobile ? '' :
                                                            <div>
                                                                <div className="panel table-shadow skin-section" tabindex="-1"></div>
                                                                <div className='background-table-border felt-1'>
                                                                </div>
                                                                {/*
                                                                <div className={`background-table-felt felt-1 felt-${tableThemeSkinColor}`}>
                                                                */}
                                                                <div className="background-table-felt felt-1 felt-default">
                                                                </div>
                                                            </div>
                                                        }

                                                        <div className="background-table-logo"></div>
                                                        { (tableInfo.smallBlind || tableInfo.bigBlind || tableInfo.roomName || tableInfo.pokerGameType) ?
                                                        (
                                                        <>
                                                            {/*
                                                            <div className="table-blind">
                                                                <div className="blindtitle">블라인드</div>
                                                                <div className="blindval">{tableInfo.smallBlind}/{tableInfo.bigBlind}</div>
                                                            </div>
                                                            */}
                                                            <div className="table-name">
                                                                <div className="tbl-blind">블라인드 : {tableInfo.smallBlind}/{tableInfo.bigBlind}</div>
                                                                <div className="tbl-room">{tableInfo.roomName}</div>
                                                                {/* <div className="tbl-type">{tableInfo.pokerGameType}</div> */}
                                                            </div>
                                                        </>
                                                        ) : '' }
                                                        {/*<div className="log-container" style={{ width: "100%"}}>start : {inProgressTimer}<br/>maxTimer : {maxTimer}<br/>timer : {gamePlayData.timer}<br/>isTournament : {isTournament}<br/>{gamePlayData.titleText}<br/>tableid : {tableId}<br/>currenttableId : {currentTableId}<br/>hiddenCards : {JSON.stringify(hiddenCards)}<br/>playerCards : {JSON.stringify(playerCards)}<br/>playerData : {JSON.stringify(playerData)}</div>
                                                        <div className="gameid-container">
                                                            {gameId}<br/>BreakTime:{breakTime}
                                                        </div>*/}
                                                        {/*
                                                        <div className="bank-container">
                                                            <div className="bank-container-content">
                                                                Pot: ${gameStart == true && gamePlayData.roomId? safeToFixed(tablePot) : '0'}
                                                            </div>

                                                        </div>
                                                        */}
                                                    </div>
                                                    <div hiddenCards = {JSON.stringify(hiddenCards)} tableid={tableId} currentTableId= {currentTableId}>
                                                        {(!emptyTable || true) &&tableId == currentTableId ?
                                                        //* 관전자도 볼수있게
                                                            <div>
                                                                <div className="r-table-cards max-cards-5" rel-data={hiddenCards.playing}>
                                                                    <div className="card-holder">
                                                                        {hiddenCards.card && hiddenCards.card.length > 0 ?
                                                                            <div className={`r-card line-0 card-0  hiddenCard slide-in hiddenCard1`} onLoad={() => hidddenCardClassName('hiddenCard1')} style={{ left: "0%", opacity: 1, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIindex: "auto" }} >
                                                                                <div className={`flip-card-inner ${isWinningCard(hiddenCards.card[0])}`}>
                                                                                    <div className="flip-card-front">

                                                                                        <img alt="poker" src={`../Images/Deck/Back/${tableThemeBackDeck}.png`} ></img>
                                                                                    </div>
                                                                                    <div className="flip-card-back" onLoad={() => cardFlipice(0)}>
                                                                                        <img alt="poker" src={'../Images/Deck/Front/' + tableThemeFrontDeck + '/' + hiddenCards.card[0] + '.png'}></img>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            : ''}
                                                                        {hiddenCards.card && hiddenCards.card.length > 1 ?
                                                                            <div className={`r-card line-1 card-1  hiddenCard slide-in hiddenCard2`} onLoad={() => hidddenCardClassName('hiddenCard2')} style={{ left: "20%", opacity: 1, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIindex: "auto" }} >
                                                                                <div className={`flip-card-inner ${isWinningCard(hiddenCards.card[1])}`}>
                                                                                    <div className="flip-card-front">
                                                                                        <img alt="poker" src={`../Images/Deck/Back/${tableThemeBackDeck}.png`} ></img>
                                                                                    </div>
                                                                                    <div className="flip-card-back" onLoad={() => cardFlipice(1)}>
                                                                                        <img alt="poker" src={'../Images/Deck/Front/' + tableThemeFrontDeck + '/' + hiddenCards.card[1] + '.png'}></img>
                                                                                    </div>
                                                                                </div>
                                                                            </div> : ''}
                                                                        {hiddenCards.card && hiddenCards.card.length > 2 ?
                                                                            <div className={`r-card line-2 card-2  hiddenCard slide-in hiddenCard3`} onLoad={() => hidddenCardClassName('hiddenCard3')} style={{ left: "40%", opacity: 1, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIindex: "auto" }} >
                                                                                <div className={`flip-card-inner ${isWinningCard(hiddenCards.card[2])}`}>
                                                                                    <div className="flip-card-front">
                                                                                        <img alt="poker" src={`../Images/Deck/Back/${tableThemeBackDeck}.png`} ></img>
                                                                                    </div>
                                                                                    <div className="flip-card-back" onLoad={() => cardFlipice(2)}>
                                                                                        <img alt="poker" src={'../Images/Deck/Front/' + tableThemeFrontDeck + '/' + hiddenCards.card[2] + '.png'}></img>
                                                                                    </div>
                                                                                </div>
                                                                            </div> : ''}

                                                                        {hiddenCards.card && hiddenCards.card.length > 3 ?
                                                                            <div className={`r-card line-3 card-3  hiddenCard slide-in hiddenCard4`} onLoad={() => hidddenCardClassName('hiddenCard4')} style={{ left: "60%", opacity: 1, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIindex: "auto" }} >
                                                                                <div className={`flip-card-inner ${isWinningCard(hiddenCards.card[3])}`}>
                                                                                    <div className="flip-card-front">
                                                                                        <img alt="poker" src={`../Images/Deck/Back/${tableThemeBackDeck}.png`} ></img>
                                                                                    </div>
                                                                                    <div className="flip-card-back" onLoad={() => cardFlipice(3)}>
                                                                                        <img alt="poker" src={'../Images/Deck/Front/' + tableThemeFrontDeck + '/' + hiddenCards.card[3] + '.png'}></img>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            : ''}
                                                                        {hiddenCards.card && hiddenCards.card.length > 4 ?
                                                                            <div className={`r-card line-4 card-4  hiddenCard slide-in hiddenCard5`} onLoad={() => hidddenCardClassName('hiddenCard5')} style={{ left: "80%", opacity: 1, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIindex: "auto" }} >
                                                                                <div className={`flip-card-inner ${isWinningCard(hiddenCards.card[4])}`}>
                                                                                    <div className="flip-card-front">
                                                                                        <img alt="poker" src={`../Images/Deck/Back/${tableThemeBackDeck}.png`} ></img>
                                                                                    </div>
                                                                                    <div className="flip-card-back" onLoad={() => cardFlipice(4)}>
                                                                                        <img alt="poker" src={'../Images/Deck/Front/' + tableThemeFrontDeck + '/' + hiddenCards.card[4] + '.png'}></img>
                                                                                    </div>
                                                                                </div>
                                                                            </div> : ''}
                                                                    </div>
                                                                </div>
                                                                <div className="r-dealer-layer">
                                                                    <div >
                                                                        {tableSeats?.map((seat, key) => {
                                                                            if (playerInfo.length > 0) {
                                                                                return (
                                                                                    playerInfo?.map((player, index) => {
                                                                                        if (checkPlayerSeat(player, seat) && (player.isDealer == true || dealerPlayer==player.id)) {
                                                                                            
                                                                                            return (
                                                                                                <div key={key}>
                                                                                                    <div className={`dealer-target seat-template-${getFillCss(seat)}`}>
                                                                                                        <div className="image dealer-button" style={{ backgroundImage: "url(../Images/DealerIcon.png?v=001)", zIndex: "auto" }}></div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    })
                                                                                )
                                                                            }
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ''}

                                                        <div className="r-table-chips-layer with-pot-component">
                                                            {tablePot > 0 ?
                                                                <div className="ReactPokerPotsContainer ReactPokerPotsContainer_v_default ReactPokerPotsContainer_c_dark ReactPokerPotsContainer_horizontal pots">
                                                                    <div className="ReactPokerPotsContainer__content">
                                                                        <div className="ReactPokerPotsContainer__chips">
                                                                            <div className="r-chip direction-right chip-columns ReactPokerPotsContainer__chip">
                                                                                <div className="text">
                                                                                    <span className="val-lbl">Total</span>
                                                                                    <span className="value">{safeToFixed(tablePot)}</span>
                                                                                </div>
                                                                                <div className="r-chip-stack">
                                                                                    {countChips(tablePot,'total')}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : ''}
                                                                
                                                            {!emptyTable || true?
                                                            //* 관전자도 볼수있게
                                                                <div >
                                                                    {tableSeats?.map((seat, key) => {
                                                                        if (playerInfo.length > 0) {
                                                                            
                                                                            return (
                                                                                
                                                                                playerInfo?.map((player, index) => {
                                                                                    if (checkPlayerSeat(player, seat) && tableId == currentTableId) {
                                                                                        return (
                                                                                            <div key={index} >
                                                                                                {gameStart == true && player.BetAmount > 0 && gamePlayData.roomId?
                                                                                                    <div className={`r-player-bet s-2 seat-template-${getFillCss(seat)} right-direction`}>
                                                                                                        <div className="r-player-bet-content">
                                                                                                            <div className="r-chip direction-right chip-columns wait-remove" style={{ zIndex: "auto" }}>
                                                                                                                <div className="text">
                                                                                                                    <span className="value">Pot {safeToFixed(player.BetAmount)}</span>
                                                                                                                </div>
                                                                                                                <div className="r-chip-stack">
                                                                                                                    <div className="columns">
                                                                                                                        {countChips(player.BetAmount,'player')}
                                                                                                                        {/*
                                                                                                                        <div className="column col0">
                                                                                                                            <div className="chip c-1"><div className="chip-image" style={{ backgroundImage: "url(../Images/chips-primary.png)" }}></div></div>
                                                                                                                        {player.BetAmount > 1000 ?
                                                                                                                                <div className="chip c-1"><div className="chip-image" style={{ backgroundImage: "url(../Images/chips-primary.png)" }}></div></div>
                                                                                                                                : ''}
                                                                                                                        </div>
                                                                                                                        {player.BetAmount > 10000 ?
                                                                                                                            <div className="column col">
                                                                                                                                <div className="chip c-5"><div className="chip-image" style={{ backgroundImage: "url(../Images/chips-secondary.png)" }}></div></div>
                                                                                                                                <div className="chip c-5"><div className="chip-image" style={{ backgroundImage: "url(../Images/chips-secondary.png)" }}></div></div>
                                                                                                                            </div>
                                                                                                                            : ''
                                                                                                                        }
                                                                                                                        {player.BetAmount > 20000 ?
                                                                                                                            <div className="column col">
                                                                                                                                <div className="chip c-5"><div className="chip-image" style={{ backgroundImage: "url(../Images/chips-primary.png)" }}></div></div>
                                                                                                                                <div className="chip c-5"><div className="chip-image" style={{ backgroundImage: "url(../Images/chips-primary.png)" }}></div></div>
                                                                                                                            </div>
                                                                                                                            : ''
                                                                                                                        }
                                                                                                                        {player.BetAmount > 30000 ?
                                                                                                                            <div className="column col">
                                                                                                                                <div className="chip c-5"><div className="chip-image" style={{ backgroundImage: "url(../Images/chips-secondary.png)" }}></div></div>
                                                                                                                                <div className="chip c-5"><div className="chip-image" style={{ backgroundImage: "url(../Images/chips-secondary.png)" }}></div></div>
                                                                                                                            </div>
                                                                                                                            : ''
                                                                                                                        }
                                                                                                                        {player.BetAmount > 40000 ?
                                                                                                                            <div className="column col">
                                                                                                                                <div className="chip c-5"><div className="chip-image" style={{ backgroundImage: "url(../Images/chips-primary.png)" }}></div></div>
                                                                                                                                <div className="chip c-5"><div className="chip-image" style={{ backgroundImage: "url(../Images/chips-primary.png)" }}></div></div>
                                                                                                                            </div>
                                                                                                                            : ''
                                                                                                                        }
                                                                                                                        {player.BetAmount > 50000 ?
                                                                                                                            <div className="column col">
                                                                                                                                <div className="chip c-5"><div className="chip-image" style={{ backgroundImage: "url(../Images/chips-secondary.png)" }}></div></div>
                                                                                                                                <div className="chip c-5"><div className="chip-image" style={{ backgroundImage: "url(../Images/chips-secondary.png)" }}></div></div>
                                                                                                                            </div>
                                                                                                                            : ''
                                                                                                                        }
                                                                                                                        {player.BetAmount > 60000 ?
                                                                                                                            <div className="column col">
                                                                                                                                <div className="chip c-5"><div className="chip-image" style={{ backgroundImage: "url(../Images/chips-primary.png)" }}></div></div>
                                                                                                                                <div className="chip c-5"><div className="chip-image" style={{ backgroundImage: "url(../Images/chips-primary.png)" }}></div></div>
                                                                                                                            </div>
                                                                                                                            : ''
                                                                                                                        }
                                                                                                                        {player.BetAmount > 70000 ?
                                                                                                                            <div className="column col">
                                                                                                                                <div className="chip c-5"><div className="chip-image" style={{ backgroundImage: "url(../Images/chips-secondary.png)" }}></div></div>
                                                                                                                                <div className="chip c-5"><div className="chip-image" style={{ backgroundImage: "url(../Images/chips-secondary.png)" }}></div></div>
                                                                                                                            </div>
                                                                                                                            : ''
                                                                                                                        }
                                                                                                                        */}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    : ''}
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                })
                                                                            )
                                                                        }
                                                                    })}
                                                                </div>
                                                                : ''}
                                                        </div>
                                                        {/* roomOut==true?
                                                            <div className={`extOnGame ${winnerSeatIndex >= 0 ? 'win' : ''}`}>
                                                                <span>나가기 예약 되었습니다</span>
                                                            </div>
                                                            :''
                                                        */}
                                                        <div className='r-seats'>
                                                            {emptyTable ?
                                                                <div >
                                                                    {tableSeats?.map((seat, key) => {
                                                                        if (playerInfo.length > 0) {
                                                                            
                                                                            return (
                                                                                playerInfo?.map((player, index) => {
                                                                                    if (checkPlayerSeat(player, seat)) {
                                                                                        return (
                                                                                            <div key={index}>
                                                                                                <div className={`r-seat ${/*winnerSeatIndex == seat*/ winnerSeatIndex[seat]==true ? 'winner' : ''} s-5 seat-template-${getFillCss(seat)} ${key < 4 ? 'left-direction' : 'right-direction'} default`}>
                                                                                                    <div className={`panel r-player ${player.status == 'Fold' || player.status === "Ideal" || player.status === "Waiting" || (player.idealPlayer === true) || (idealPlayer && idealPlayer.playerId === player.id && player.idealPlayer === true) ? 'player-obscured' : ''}`} tabindex="-1" data-sort="top" data-player-status={player.status} data-player-idealPlayer={player.idealPlayer} data-idealPlayer-playerId={idealPlayer.playerId} data-player-id={player.id}>
                                                                                                        <div className="panel r-player-active-area" tabindex="-1"></div>
                                                                                                        
                                                                                                        <div className="winning-sign">
                                                                                                            <div className="wn winner"></div>
                                                                                                        </div>
                                                                                                        
                                                                                                        <div className="r-player-avatar v_default">
                                                                                                            
                                                                                                            
                                                                                                            <div className="panel Avatar avatar gender-male" tabindex="-1" style={{ backgroundImage: "url(../Images/Avtar/" + (player.avatar + 1) + ".png?v=001)" }}></div>
                                                                                                            
                                                                                                            {playerActionData.playerId == player.id && getActionName(playerActionData) != '' ?
                                                                                                            <div className={`bar-text player-action pa-fold ${getActionName(playerActionData)}-action-group`}>
                                                                                                                <div className="text">{getActionName(playerActionData)}!</div>
                                                                                                            </div>
                                                                                                            : ''}
                                                                                                            {player.status == 'Fold' ?
                                                                                                            <div className={`bar-text player-action pa-fold Fold-action-group fold`}>
                                                                                                                <div className="text">Fold!</div>
                                                                                                            </div>
                                                                                                            : ''}
                                                                                                            {player.status == 'Ideal' || (idealPlayer && idealPlayer.playerId === player.id) ?
                                                                                                            <div className={`bar-text player-action pa-fold Fold-action-group away`}>
                                                                                                                <div className="text">Away</div>
                                                                                                            </div>
                                                                                                            : ''}
                                                                                                            {player.status == 'Waiting' ?
                                                                                                            <div className={`bar-text player-action pa-fold Fold-action-group wait`}>
                                                                                                                <div className="text">Wait</div>
                                                                                                            </div>
                                                                                                            : ''}
                                                                                                        </div>
                                                                                                        <div className="r-player-avatar-back-light no-color useBorder">
                                                                                                        </div>
                                                                                                        <div className="r-player-cards max-cards-5">
                                                                                                            {(gameStart == true || bestCard.length>=1) && player.idealPlayer == false && player.status == "Playing" ?
                                                                                                                gameType == 'omaha' ?
                                                                                                                    <div>
                                                                                                                        {player.id != playerData.playerId ?
                                                                                                                            <div>
                                                                                                                                {gameFinishedPlayersCards.playersCards && gameFinishedPlayersCards.playersCards.length > 0 ?
                                                                                                                                    <div>
                                                                                                                                        {gameFinishedPlayersCards.playersCards.map((playersCards, pkey) => {
                                                                                                                                            if (playersCards.playerId == player.id) {
                                                                                                                                                return (
                                                                                                                                                    <div className="card-holder open-card-holder ___a">
                                                                                                                                                        <div className="r-card-wrapper" >
                                                                                                                                                            <div className={`r-card card-0 hole-card close-card ${isWinningCard(playersCards.cards[0])}`} style={{ transform: "matrix3d(0.994522, -0.104528, 0, 0, 0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, -17, 0, 1)", }}>
                                                                                                                                                                <div className="face" style={{ backgroundImage: "url(../Images/Deck/Front/" + tableThemeFrontDeck + "/" + playersCards.cards[0] + ".png)", transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                        <div className="r-card-wrapper" >
                                                                                                                                                            <div className={`r-card card-0 hole-card close-card ${isWinningCard(playersCards.cards[1])}`} style={{ transform: "matrix3d(0.99863, -0.0523359, 0, 0, 0.0523359, 0.99863, 0, 0, 0, 0, 1, 0, 0, -17, 0, 1)" }}>
                                                                                                                                                                <div className="face" style={{ backgroundImage: "url(../Images/Deck/Front/" + tableThemeFrontDeck + "/" + playersCards.cards[1] + ".png)", transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                        <div className="r-card-wrapper" >
                                                                                                                                                            <div className={`r-card card-0 hole-card close-card ${isWinningCard(playersCards.cards[2])}`} style={{ transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -17, 0, 1)", }}>
                                                                                                                                                                <div className="face" style={{ backgroundImage: "url(../Images/Deck/Front/" + tableThemeFrontDeck + "/" + playersCards.cards[2] + ".png)", transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                        <div className="r-card-wrapper" >
                                                                                                                                                            <div className={`r-card card-0 hole-card close-card ${isWinningCard(playersCards.cards[3])}`} style={{ transform: "matrix3d(0.994522, 0.104528, 0, 0, -0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, -17, 0, 1)", }}>
                                                                                                                                                                <div className="face" style={{ backgroundImage: "url(../Images/Deck/Front/" + tableThemeFrontDeck + "/" + playersCards.cards[3] + ".png)", transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                )
                                                                                                                                            } else {
                                                                                                                                                <div className="card-holder close-card-holder ___a">
                                                                                                                                                    <div className="r-card-wrapper wrapper-close-card">
                                                                                                                                                        <div className="r-card card-0 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.994522, -0.104528, 0, 0, 0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                            <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                    <div className="r-card-wrapper wrapper-close-card">
                                                                                                                                                        <div className="r-card card-1 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.99863, -0.0523359, 0, 0, 0.0523359, 0.99863, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                            <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                    <div className="r-card-wrapper wrapper-close-card">
                                                                                                                                                        <div className="r-card card-2 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.99863, 0.0523359, 0, 0, -0.0523359, 0.99863, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                            <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                    <div className="r-card-wrapper wrapper-close-card">
                                                                                                                                                        <div className="r-card card-3 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.994522, 0.104528, 0, 0, -0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                            <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            }
                                                                                                                                        })}
                                                                                                                                    </div>
                                                                                                                                    :
                                                                                                                                    <div className="card-holder close-card-holder ___ab">
                                                                                                                                        <div className="r-card-wrapper wrapper-close-card">
                                                                                                                                            <div className="r-card card-0 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.994522, -0.104528, 0, 0, 0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="r-card-wrapper wrapper-close-card">
                                                                                                                                            <div className="r-card card-1 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.99863, -0.0523359, 0, 0, 0.0523359, 0.99863, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="r-card-wrapper wrapper-close-card">
                                                                                                                                            <div className="r-card card-2 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.99863, 0.0523359, 0, 0, -0.0523359, 0.99863, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="r-card-wrapper wrapper-close-card">
                                                                                                                                            <div className="r-card card-3 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.994522, 0.104528, 0, 0, -0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                            :
                                                                                                                            <div>
                                                                                                                                {playerCards && playerCards.cards ?
                                                                                                                                    <div className="card-holder open-card-holder ___b">
                                                                                                                                        <div className="r-card-wrapper" >
                                                                                                                                            <div className="r-card card-0 hole-card close-card" style={{ transform: "matrix3d(0.994522, -0.104528, 0, 0, 0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, -17, 0, 1)", }}>
                                                                                                                                                <div className="face" style={{ backgroundImage: "url(" + getCard(1) + ")", transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="r-card-wrapper" >
                                                                                                                                            <div className="r-card card-0 hole-card close-card" style={{ transform: "matrix3d(0.99863, -0.0523359, 0, 0, 0.0523359, 0.99863, 0, 0, 0, 0, 1, 0, 0, -17, 0, 1)" }}>
                                                                                                                                                <div className="face" style={{ backgroundImage: "url(" + getCard(2) + ")", transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="r-card-wrapper" >
                                                                                                                                            <div className="r-card card-0 hole-card close-card" style={{ transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -17, 0, 1)", }}>
                                                                                                                                                <div className="face" style={{ backgroundImage: "url(" + getCard(3) + ")", transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}> </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="r-card-wrapper" >
                                                                                                                                            <div className="r-card card-0 hole-card close-card" style={{ transform: "matrix3d(0.994522, 0.104528, 0, 0, -0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, -17, 0, 1)", }}>
                                                                                                                                                <div className="face" style={{ backgroundImage: "url(" + getCard(4) + ")", transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}> </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    :
                                                                                                                                    <div className="card-holder close-card-holder ___b">
                                                                                                                                        <div className="r-card-wrapper wrapper-close-card">
                                                                                                                                            <div className="r-card card-0 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.994522, -0.104528, 0, 0, 0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>

                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="r-card-wrapper wrapper-close-card">
                                                                                                                                            <div className="r-card card-1 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.99863, -0.0523359, 0, 0, 0.0523359, 0.99863, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="r-card-wrapper wrapper-close-card">
                                                                                                                                            <div className="r-card card-2 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.99863, 0.0523359, 0, 0, -0.0523359, 0.99863, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="r-card-wrapper wrapper-close-card">
                                                                                                                                            <div className="r-card card-3 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.994522, 0.104528, 0, 0, -0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                }
                                                                                                                            </div>}
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    <div>
                                                                                                                        {player.id != playerData.playerId ?
                                                                                                                            <div>
                                                                                                                                {gameFinishedPlayersCards.playersCards && gameFinishedPlayersCards.playersCards.length > 0 ?
                                                                                                                                    <div>
                                                                                                                                        {gameFinishedPlayersCards.playersCards.map((playersCards, pkey) => {
                                                                                                                                            if (playersCards.playerId == player.id) {
                                                                                                                                                return (
                                                                                                                                                    <div className="card-holder open-card-holder ___c">
                                                                                                                                                        <div className="r-card-wrapper">
                                                                                                                                                            <div className={`r-card card-0 hole-card close-card ${isWinningCard(playersCards.cards[0])}`} style={{ transform: "matrix3d(0.997564, -0.0697564, 0, 0, 0.0697564, 0.997564, 0, 0, 0, 0, 1, 0, 0, -17, 0, 1)", }}>
                                                                                                                                                                <div className="face" style={{ backgroundImage: "url(../Images/Deck/Front/" + tableThemeFrontDeck + "/" + playersCards.cards[0] + ".png)", transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                        <div className="r-card-wrapper">
                                                                                                                                                            <div className={`r-card card-0 hole-card close-card ${isWinningCard(playersCards.cards[1])}`} style={{ transform: "matrix3d(0.997564, 0.0697564, 0, 0, -0.0697564, 0.997564, 0, 0, 0, 0, 1, 0, 0, -17, 0, 1)", }}>
                                                                                                                                                                <div className="face" style={{ backgroundImage: "url(../Images/Deck/Front/" + tableThemeFrontDeck + "/" + playersCards.cards[1] + ".png)", transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                )
                                                                                                                                            } else {
                                                                                                                                                <div className="card-holder close-card-holder ___c">
                                                                                                                                                    <div className="r-card-wrapper wrapper-close-card" style={{ transform: "matrix3d(0.994522, -0.104528, 0, 0, 0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}>
                                                                                                                                                        <div className="r-card card-1 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.994522, -0.104528, 0, 0, 0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                            <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                    <div className="r-card-wrapper wrapper-close-card">
                                                                                                                                                        <div className="r-card card-2 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.99863, -0.0523359, 0, 0, 0.0523359, 0.99863, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                            <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(-1, 0, 0, 0, 0, 1, 0, 0, 0, 0, -1, 0, 0, 0, 0, 1);" }}></div>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            }
                                                                                                                                        })}
                                                                                                                                    </div>
                                                                                                                                    :
                                                                                                                                    <div className="card-holder close-card-holder ___ff">
                                                                                                                                        <div className="r-card-wrapper wrapper-close-card" style={{ transform: "matrix3d(0.994522, -0.104528, 0, 0, 0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}>
                                                                                                                                            <div className="r-card card-1 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.994522, -0.104528, 0, 0, 0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="r-card-wrapper wrapper-close-card">
                                                                                                                                            <div className="r-card card-2 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.99863, -0.0523359, 0, 0, 0.0523359, 0.99863, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(-1, 0, 0, 0, 0, 1, 0, 0, 0, 0, -1, 0, 0, 0, 0, 1);" }}></div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                            :
                                                                                                                            <div>
                                                                                                                                {playerCards && playerCards.cards ?
                                                                                                                                    <div className="card-holder open-card-holder ___d">
                                                                                                                                        <div className="r-card-wrapper" >
                                                                                                                                            <div className="r-card card-0 hole-card close-card" style={{ transform: "matrix3d(0.99863, -0.0523359, 0, 0, 0.0523359, 0.99863, 0, 0, 0, 0, 1, 0, 0, -17, 0, 1)" }}>
                                                                                                                                                <div className="face" style={{ backgroundImage: "url(" + getCard(1) + ")", transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="r-card-wrapper" >
                                                                                                                                            <div className="r-card card-0 hole-card close-card" style={{ transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -17, 0, 1)", }}>
                                                                                                                                                <div className="face" style={{ backgroundImage: "url(" + getCard(2) + ")", transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    :
                                                                                                                                    <div className="card-holder close-card-holder ___d">
                                                                                                                                        <div className="r-card-wrapper wrapper-close-card" style={{ transform: "matrix3d(0.994522, -0.104528, 0, 0, 0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}>
                                                                                                                                            <div className="r-card card-1 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.994522, -0.104528, 0, 0, 0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="r-card-wrapper wrapper-close-card">
                                                                                                                                            <div className="r-card card-2 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.99863, -0.0523359, 0, 0, 0.0523359, 0.99863, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(-1, 0, 0, 0, 0, 1, 0, 0, 0, 0, -1, 0, 0, 0, 0, 1);" }}></div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                }
                                                                                                                            </div>}
                                                                                                                    </div>
                                                                                                                :
                                                                                                                ''}
                                                                                                        </div>
                                                                                                        <div className="image r-player-bar gauge-on">
                                                                                                            
                                                                                                            <div className="r-player-bar-content">
                                                                                                                {gamePlayData.roomId === currentTableId && gamePlayData.playerId == player.id ? 
                                                                                                                    (inProgressTimer !== null && maxTimer!=1? 
                                                                                                                    <div className="progressTimer inProgressTimer" style={{ '--duration': inProgressTimer }}>
                                                                                                                        <div className="countdown-tr" style={{ 'animation-duration': inProgressTimer }}></div>
                                                                                                                        <div className="countdown-bl" style={{ 'animation-duration': inProgressTimer }}></div>
                                                                                                                    </div> 
                                                                                                                    : (maxTimer>1?
                                                                                                                    <div className="progressTimer maxTimer" style={{ '--duration': maxTimer }}>
                                                                                                                        <div className="countdown-tr" style={{ 'animation-duration': maxTimer }}></div>
                                                                                                                        <div className="countdown-bl" style={{ 'animation-duration': maxTimer }}></div>
                                                                                                                    </div>
                                                                                                                    :"")
                                                                                                                    ) : ''}
                                                                                                                {/* gamePlayData.roomId === currentTableId && gamePlayData.playerId == player.id ? (inProgressTimer !== null && maxTimer!=1? <div className="timer inProgressTimer" style={{ '--duration': inProgressTimer }}></div>:(maxTimer>1?<div className="timer maxTimer" style={{ '--duration': maxTimer }}></div>:"")) : '' */}
                                                                                                                {/* <div className="bar-text top-line player-name">{player.username.split('_')[0]}</div> */}
                                                                                                                <div className="bar-text top-line player-name">
                                                                                                                    <div className="pname-txt">{player.username.split('_')[0]}</div>
                                                                                                                    {/*
                                                                                                                    {gamePlayData.roomId === currentTableId && gamePlayData.playerId == player.id ? (inProgressTimer !== null && maxTimer!=1? <div className="timer inProgressTimer" style={{ '--duration': inProgressTimer }}></div> : (maxTimer>1?<div className="timer maxTimer" style={{ '--duration': maxTimer }}></div>:"")) : ''}
                                                                                                                    */}
                                                                                                                </div>
                                                                                                                <div className="bar-text bottom-line player-cash noCash">{safeToFixed(player.BuyInAmount)}</div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        {/*winnerSeatIndex == seat && winningAmount */ winnerSeatIndex && winnerSeatIndex[seat] && winningAmount[seat] > 0 ?
                                                                                                            <div className="PlayerWonContainer PlayerWonContainer_v_default PlayerWonContainer_c_dark r-seat__won_message">
                                                                                                                <div className="PlayerWonContainer__content">
                                                                                                                    <div>
                                                                                                                        <div className="PlayerWonContainer__message winner default_player_won_animation-enter-done">
                                                                                                                            <div className="PlayerWonContainer__funds"><div className="PlayerWonContainer__funds__text">+ Pot {winningAmount[seat]}</div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div> : ''}
                                                                                                        {/*winnerSeatIndex == seat && winningType != ''*/ winnerSeatIndex && winnerSeatIndex[seat] && winningType && winningType[seat]!='' ?
                                                                                                            <div className="seat-combination-names winner">
                                                                                                                <div className="panel seat-combination-names-content" tabindex="-1">
                                                                                                                    <div className="names">
                                                                                                                        <div className={`name ${getWinningClassName(winningType[seat])}`}>
                                                                                                                            <img src={require(`../Images/winimg/${getWinningClassName(winningType[seat])}.png?v=01`)} alt="Winning Image" />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            : ''}
                                                                                                        {/*    

                                                                                                        {playerActionData.playerId == player.id && getActionName(playerActionData) != '' ?
                                                                                                            <div className={`bar-text player-action pa-fold ${getActionName(playerActionData)}-action-group`}>
                                                                                                                <div className="text">{getActionName(playerActionData)}</div>
                                                                                                            </div>
                                                                                                            : ''}
                                                                                                        {player.status == 'Fold' ?
                                                                                                            <div className={`bar-text player-action pa-fold Fold-action-group`}>
                                                                                                                <div className="text">Fold!</div>
                                                                                                            </div>
                                                                                                            : ''}
                                                                                                        {player.status == 'Ideal' || (idealPlayer && idealPlayer.playerId === player.id) ?
                                                                                                            <div className={`bar-text player-action pa-fold Fold-action-group`}>
                                                                                                                <div className="text">Away!</div>
                                                                                                            </div>
                                                                                                            : ''}
                                                                                                        {player.status == 'Waiting' ?
                                                                                                            <div className={`bar-text player-action pa-fold Fold-action-group`}>
                                                                                                                <div className="text">Wait!</div>
                                                                                                            </div>
                                                                                                            : ''}
                                                                                                        */}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    } else if (checkSeatAvailable(seat)) {
                                                                                        return (
                                                                                            <div>
                                                                                                <div key={key}>
                                                                                                    <div className={`r-seat s-5 seat-template-${getFillCss(seat)} left-direction default`}>
                                                                                                        <div className="panel button free-seat" tabIndex="-1" onClick={() => selectBuyIn(seat)}></div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                })
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <div key={key}>
                                                                                    <div className={`r-seat s-5 seat-template-${getFillCss(seat)} left-direction default`}>
                                                                                        <div className="panel button free-seat" tabIndex="-1" onClick={() => selectBuyIn(seat)}></div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })}
                                                                </div>
                                                                :
                                                                <div>
                                                                    <div >
                                                                        {tableSeats.map((seat, key) => {
                                                                            if (playerInfo.length > 0) {

                                                                                return (
                                                                                    <div key={key} className='emptyTable'>
                                                                                        {playerInfo.map((player, index) => {
                                                                                            if (checkPlayerSeat(player, seat)) {
                                                                                                return (
                                                                                                    <div className={`r-seat ${/*winnerSeatIndex == seat*/ winnerSeatIndex[seat]==true ? 'winner' : ''} s-5 seat-template-${getFillCss(seat)} ${key < 4 ? 'left-direction' : 'right-direction'} default`}>
                                                                                                        <div className={`panel r-player ${player.status == 'Fold' || player.status === "Ideal" || player.status === "Waiting" || (player.idealPlayer === true) || (idealPlayer && idealPlayer.playerId === player.id && player.idealPlayer === true) ? 'player-obscured' : ''}`} tabindex="-1" data-sort="bot" data-player-status={player.status} data-player-idealPlayer={player.idealPlayer} data-idealPlayer-playerId={idealPlayer.playerId} data-player-id={player.id}>
                                                                                                            <div className="panel r-player-active-area" tabindex="-1"></div>
                                                                                                            
                                                                                                            
                                                                                                            {player.id == playerData.playerId && (
																											<div className='player_rank'  player_rank = {JSON.stringify(gamePlayData)} style={{ display: gamePlayData.roomId ? 'block' : 'none' }}></div>
                                                                                                            )}
                                                                                                            
                                                                                                            <div className="winning-sign">
                                                                                                                <div className="wn winner sparkling"></div>
                                                                                                                <div class="star s1"><div class="si"></div></div>
                                                                                                                <div class="star s2"><div class="si"></div></div>
                                                                                                                <div class="star s3"><div class="si"></div></div>
                                                                                                                <div class="star s4"><div class="si"></div></div>
                                                                                                                <div class="star s5"><div class="si"></div></div>                
                                                                                                                <div class="star s6"><div class="si"></div></div>
                                                                                                                <div class="star s7"><div class="si"></div></div>
                                                                                                            </div>
                                                                                                            
                                                                                                            <div className="r-player-avatar v_default">
                                                                                                                
                                                                                                                
                                                                                                                
                                                                                                                {playerActionData.playerId == player.id && getActionName(playerActionData) != '' ?
                                                                                                                <div className={`bar-text player-action pa-fold ${getActionName(playerActionData)}-action-group`}>
                                                                                                                    <div className="text">{getActionName(playerActionData)}.</div>
                                                                                                                </div>:''}
                                                                                                                {player.status == 'Fold' ?
                                                                                                                <div className={`bar-text player-action pa-fold Fold-action-group fold 22`}>
                                                                                                                    <div className="text">Fold.</div>
                                                                                                                </div>
                                                                                                                :player.status == 'Ideal' || (idealPlayer && idealPlayer.playerId === player.id) || (isTournament==true && player.idealPlayer==true)?
                                                                                                                <div className={`bar-text player-action pa-fold Fold-action-group away 22`}>
                                                                                                                    <div className="text">Away.</div>
                                                                                                                </div>
                                                                                                                : player.status=='Waiting'?
                                                                                                                <div className={`bar-text player-action pa-fold Fold-action-group wait 22`}>
                                                                                                                    <div className="text">Wait.</div>
                                                                                                                </div>
                                                                                                                : ''
                                                                                                                }
                                                                                                                
                                                                                                                {playerGiftData && playerGiftData.receriverSeatIndex == seat ?
                                                                                                                    <div className={'p-relative playergame-sender-gift playergame-sender-gift-' + playerGiftData.senderIndex + ' playergame-receiver-gift-' + playerGiftData.receriverSeatIndex}>
                                                                                                                        <img src={BackendURL + 'gameGift/' + playerGiftData.gift.image} className='player-gift-img'></img>
                                                                                                                    </div>
                                                                                                                    :''
                                                                                                                    /*
                                                                                                                    player.id != playerData.playerId ?
                                                                                                                        <div className={'p-relative player-send-gift-button playergame-sender-gift-' + seat + ' playergame-receiver-gift-' + seat} onClick={() => sendGiftToPlayer(player, seat)}>
                                                                                                                            <img src={'../Images/giftIcon.png'} ></img>
                                                                                                                        </div>
                                                                                                                        : ''
                                                                                                                    */
                                                                                                                }
                                                                                                                <div className="panel Avatar avatar gender-male" tabindex="-1" style={{ backgroundImage: "url(../Images/Avtar/" + (player.avatar + 1) + ".png?v=001)" }}></div>
                                                                                                            </div>
                                                                                                            <div className="r-player-avatar-back-light no-color useBorder">
                                                                                                            </div>
                                                                                                            <div className="r-player-cards max-cards-5" gamestart ={gameStart ? 'game-start-true' : 'game-start-false'}  idealPlayer = {player.idealPlayer ? 'idealPlayer-true' : 'idealPlayer-false'}  status = {player.status} playerid={playerData.playerId} carddistributionLen = {onPlayersCardsDistribution.playersCards} carddistribution = {JSON.stringify(onPlayersCardsDistribution.playersCards)}  playerid22={player.id}>
                                                                                                                { isCardViewPlayer[player.id]==true || ((((gameStart == true || bestCard.length>=1) && player.idealPlayer == false) || isTournament==true) && (player.status == "Playing" || player.id == playerData.playerId) && (onPlayersCardsDistribution.length <= 0 || (onPlayersCardsDistribution && onPlayersCardsDistribution.playersCards && onPlayersCardsDistribution.playersCards.length > 0 && onPlayersCardsDistribution.playersCards.some(element => element.playerId === player.id))))?
                                                                                                                    gameType == 'omaha' ?
                                                                                                                        <div>
                                                                                                                            {player.id != playerData.playerId ?
                                                                                                                                <div>
                                                                                                                                    {gameFinishedPlayersCards.playersCards && gameFinishedPlayersCards.playersCards.length > 0 ?
                                                                                                                                        <div>
                                                                                                                                            {gameFinishedPlayersCards.playersCards.map((playersCards, pkey) => {
                                                                                                                                                if (playersCards.playerId == player.id) {
                                                                                                                                                    return (
                                                                                                                                                        <div className="card-holder open-card-holder ___e">
                                                                                                                                                            <div className="r-card-wrapper" >
                                                                                                                                                                <div className={`r-card card-0 hole-card close-card ${isWinningCard(playersCards.cards[0])}`} style={{ transform: "matrix3d(0.994522, -0.104528, 0, 0, 0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, -17, 0, 1)", }}>
                                                                                                                                                                    <div className="face" style={{ backgroundImage: "url(../Images/Deck/Front/" + tableThemeFrontDeck + "/" + playersCards.cards[0] + ".png)", transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                            <div className="r-card-wrapper" >
                                                                                                                                                                <div className={`r-card card-0 hole-card close-card ${isWinningCard(playersCards.cards[1])}`} style={{ transform: "matrix3d(0.99863, -0.0523359, 0, 0, 0.0523359, 0.99863, 0, 0, 0, 0, 1, 0, 0, -17, 0, 1)" }}>
                                                                                                                                                                    <div className="face" style={{ backgroundImage: "url(../Images/Deck/Front/" + tableThemeFrontDeck + "/" + playersCards.cards[1] + ".png)", transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                            <div className="r-card-wrapper" >
                                                                                                                                                                <div className={`r-card card-0 hole-card close-card ${isWinningCard(playersCards.cards[2])}`} style={{ transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -17, 0, 1)", }}>
                                                                                                                                                                    <div className="face" style={{ backgroundImage: "url(../Images/Deck/Front/" + tableThemeFrontDeck + "/" + playersCards.cards[2] + ".png)", transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                            <div className="r-card-wrapper" >
                                                                                                                                                                <div className={`r-card card-0 hole-card close-card ${isWinningCard(playersCards.cards[3])}`} style={{ transform: "matrix3d(0.994522, 0.104528, 0, 0, -0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, -17, 0, 1)", }}>
                                                                                                                                                                    <div className="face" style={{ backgroundImage: "url(../Images/Deck/Front/" + tableThemeFrontDeck + "/" + playersCards.cards[3] + ".png)", transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                    )
                                                                                                                                                } else {
                                                                                                                                                    <div className="card-holder close-card-holder ___e">
                                                                                                                                                        <div className="r-card-wrapper wrapper-close-card">
                                                                                                                                                            <div className="r-card card-0 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.994522, -0.104528, 0, 0, 0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                                <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                        <div className="r-card-wrapper wrapper-close-card">
                                                                                                                                                            <div className="r-card card-1 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.99863, -0.0523359, 0, 0, 0.0523359, 0.99863, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                                <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                        <div className="r-card-wrapper wrapper-close-card">
                                                                                                                                                            <div className="r-card card-2 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.99863, 0.0523359, 0, 0, -0.0523359, 0.99863, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                                <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                        <div className="r-card-wrapper wrapper-close-card">
                                                                                                                                                            <div className="r-card card-3 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.994522, 0.104528, 0, 0, -0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                                <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                }
                                                                                                                                            })}
                                                                                                                                        </div>
                                                                                                                                        :
                                                                                                                                        <div className="card-holder close-card-holder ___ee">
                                                                                                                                            <div className="r-card-wrapper wrapper-close-card">
                                                                                                                                                <div className="r-card card-0 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.994522, -0.104528, 0, 0, 0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                    <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div className="r-card-wrapper wrapper-close-card">
                                                                                                                                                <div className="r-card card-1 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.99863, -0.0523359, 0, 0, 0.0523359, 0.99863, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                    <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div className="r-card-wrapper wrapper-close-card">
                                                                                                                                                <div className="r-card card-2 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.99863, 0.0523359, 0, 0, -0.0523359, 0.99863, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                    <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div className="r-card-wrapper wrapper-close-card">
                                                                                                                                                <div className="r-card card-3 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.994522, 0.104528, 0, 0, -0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                    <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    }
                                                                                                                                </div> :
                                                                                                                                <div>
                                                                                                                                    {playerCards && playerCards.cards ?
                                                                                                                                        <div className="card-holder open-card-holder ___f">
                                                                                                                                            <div className="r-card-wrapper" >
                                                                                                                                                <div className={`r-card card-0 hole-card close-card ${isWinningCard(playerCards.cards[0])}`} style={{ transform: "matrix3d(0.994522, -0.104528, 0, 0, 0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, -17, 0, 1)", }}>
                                                                                                                                                    <div className="face" style={{ backgroundImage: "url(" + getCard(1) + ")", transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div className="r-card-wrapper" >
                                                                                                                                                <div className={`r-card card-0 hole-card close-card ${isWinningCard(playerCards.cards[1])}`} style={{ transform: "matrix3d(0.99863, -0.0523359, 0, 0, 0.0523359, 0.99863, 0, 0, 0, 0, 1, 0, 0, -17, 0, 1)" }}>
                                                                                                                                                    <div className="face" style={{ backgroundImage: "url(" + getCard(2) + ")", transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div className="r-card-wrapper" >
                                                                                                                                                <div className={`r-card card-0 hole-card close-card ${isWinningCard(playerCards.cards[2])}`} style={{ transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -17, 0, 1)", }}>
                                                                                                                                                    <div className="face" style={{ backgroundImage: "url(" + getCard(3) + ")", transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div className="r-card-wrapper" >
                                                                                                                                                <div className={`r-card card-0 hole-card close-card ${isWinningCard(playerCards.cards[3])}`} style={{ transform: "matrix3d(0.994522, 0.104528, 0, 0, -0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, -17, 0, 1)", }}>
                                                                                                                                                    <div className="face" style={{ backgroundImage: "url(" + getCard(4) + ")", transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        :
                                                                                                                                        <div className="card-holder close-card-holder ___f">
                                                                                                                                            <div className="r-card-wrapper wrapper-close-card">
                                                                                                                                                <div className="r-card card-0 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.994522, -0.104528, 0, 0, 0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                    <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div className="r-card-wrapper wrapper-close-card">
                                                                                                                                                <div className="r-card card-1 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.99863, -0.0523359, 0, 0, 0.0523359, 0.99863, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                    <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div className="r-card-wrapper wrapper-close-card">
                                                                                                                                                <div className="r-card card-2 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.99863, 0.0523359, 0, 0, -0.0523359, 0.99863, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                    <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div className="r-card-wrapper wrapper-close-card">
                                                                                                                                                <div className="r-card card-3 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.994522, 0.104528, 0, 0, -0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                    <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    }
                                                                                                                                </div>}
                                                                                                                        </div> :
                                                                                                                        <div>
                                                                                                                            {player.id != playerData.playerId ?
                                                                                                                                <div>
                                                                                                                                    {gameFinishedPlayersCards.playersCards && gameFinishedPlayersCards.playersCards.length > 0 ?
                                                                                                                                        <div>
                                                                                                                                            {gameFinishedPlayersCards.playersCards.map((playersCards, pkey) => {
                                                                                                                                                if (playersCards.playerId == player.id) {
                                                                                                                                                    return (
                                                                                                                                                        <div className="card-holder open-card-holder ___g">
                                                                                                                                                            <div className="r-card-wrapper">
                                                                                                                                                                <div className={`r-card card-0 hole-card close-card ${isWinningCard(playersCards.cards[0])}`} style={{ transform: "matrix3d(0.997564, -0.0697564, 0, 0, 0.0697564, 0.997564, 0, 0, 0, 0, 1, 0, 0, -17, 0, 1)", }}>
                                                                                                                                                                    <div className="face" style={{ backgroundImage: "url(../Images/Deck/Front/" + tableThemeFrontDeck + "/" + playersCards.cards[0] + ".png)", transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                            <div className="r-card-wrapper">
                                                                                                                                                                <div className={`r-card card-0 hole-card close-card ${isWinningCard(playersCards.cards[1])}`} style={{ transform: "matrix3d(0.997564, 0.0697564, 0, 0, -0.0697564, 0.997564, 0, 0, 0, 0, 1, 0, 0, -17, 0, 1)", }}>
                                                                                                                                                                    <div className="face" style={{ backgroundImage: "url(../Images/Deck/Front/" + tableThemeFrontDeck + "/" + playersCards.cards[1] + ".png)", transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                    )
                                                                                                                                                } else {
                                                                                                                                                    <div className="card-holder close-card-holder ___g">
                                                                                                                                                        <div className="r-card-wrapper wrapper-close-card" style={{ transform: "matrix3d(0.994522, -0.104528, 0, 0, 0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}>
                                                                                                                                                            <div className="r-card card-1 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.994522, -0.104528, 0, 0, 0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                                <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                        <div className="r-card-wrapper wrapper-close-card">
                                                                                                                                                            <div className="r-card card-2 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.99863, -0.0523359, 0, 0, 0.0523359, 0.99863, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                                <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(-1, 0, 0, 0, 0, 1, 0, 0, 0, 0, -1, 0, 0, 0, 0, 1);" }}></div>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                }
                                                                                                                                            })}
                                                                                                                                        </div>
                                                                                                                                        :
                                                                                                                                        <div className="card-holder close-card-holder ___gf"  playerid ={player.id} plyerdataid ={playerData.playerId}>
                                                                                                                                            <div className="r-card-wrapper wrapper-close-card" style={{ transform: "matrix3d(0.994522, -0.104528, 0, 0, 0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}>
                                                                                                                                                <div className="r-card card-1 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.994522, -0.104528, 0, 0, 0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                    <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div className="r-card-wrapper wrapper-close-card">
                                                                                                                                                <div className="r-card card-2 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.99863, -0.0523359, 0, 0, 0.0523359, 0.99863, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                    <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(-1, 0, 0, 0, 0, 1, 0, 0, 0, 0, -1, 0, 0, 0, 0, 1);" }}></div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    }
                                                                                                                                </div> :
                                                                                                                                <div>
                                                                                                                                    {playerCards && playerCards.cards?(
                                                                                                                                        <div className="card-holder open-card-holder ___h" playerid ={player.id} plyerdataid ={playerData.playerId} >
                                                                                                                                            <div className="r-card-wrapper">
                                                                                                                                                <div className={`r-card card-0 hole-card close-card ${isWinningCard(playerCards.cards[0])}`} style={{ transform: "matrix3d(0.997564, -0.0697564, 0, 0, 0.0697564, 0.997564, 0, 0, 0, 0, 1, 0, 0, -17, 0, 1)", }}>
                                                                                                                                                    <div className="face" style={{ backgroundImage: "url(" + getCard(1) + ")", transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div className="r-card-wrapper">
                                                                                                                                                <div className={`r-card card-0 hole-card close-card ${isWinningCard(playerCards.cards[1])}`} style={{ transform: "matrix3d(0.997564, 0.0697564, 0, 0, -0.0697564, 0.997564, 0, 0, 0, 0, 1, 0, 0, -17, 0, 1)", }}>
                                                                                                                                                    <div className="face" style={{ backgroundImage: "url(" + getCard(2) + ")", transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        )
                                                                                                                                        :
                                                                                                                                        multiPlayerCards && multiPlayerCards[currentTableId] && multiPlayerCards[currentTableId].length > 0 && false?(
                                                                                                                                            <div className="card-holder open-card-holder ___h getCard">
                                                                                                                                                {/*<div className="r-card-wrapper">
                                                                                                                                                    <div className={`r-card card-0 hole-card close-card ${isWinningCard(multiPlayerCards[currentTableId][0])}`} style={{ transform: "matrix3d(0.997564, -0.0697564, 0, 0, 0.0697564, 0.997564, 0, 0, 0, 0, 1, 0, 0, -17, 0, 1)", }}>
                                                                                                                                                        <div className="face" style={{ backgroundImage: "url(" + getMultiCard(multiPlayerCards[currentTableId][0]) + ")", transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                                */}
                                                                                                                                                <div className="r-card-wrapper">
                                                                                                                                                    <div className={`r-card card-0 hole-card close-card ${isWinningCard(multiPlayerCards[currentTableId][1])}`} style={{ transform: "matrix3d(0.997564, 0.0697564, 0, 0, -0.0697564, 0.997564, 0, 0, 0, 0, 1, 0, 0, -17, 0, 1)", }}>
                                                                                                                                                        <div className="face" style={{ backgroundImage: "url(" + getMultiCard(multiPlayerCards[currentTableId][1]) + ")", transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        ): gameStart == true ? (
                                                                                                                                            <div className={`card-holder close-card-holder ___h dd ${player.betAmoun}`} >
                                                                                                                                                <div className="r-card-wrapper wrapper-close-card" style={{ transform: "matrix3d(0.994522, -0.104528, 0, 0, 0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}>
                                                                                                                                                    <div className="r-card card-1 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.994522, -0.104528, 0, 0, 0.104528, 0.994522, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                        <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)" }}></div>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                                <div className="r-card-wrapper wrapper-close-card">
                                                                                                                                                    <div className="r-card card-2 hole-card close-card" style={{ opacity: "1", transform: "matrix3d(0.997564, 0.0697564, 0, 0, -0.0697564, 0.997564, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", zIndex: "auto" }}>
                                                                                                                                                        <div className="face" style={{ backgroundImage: `url(../Images/Deck/Back/${tableThemeBackDeck}.png)`, transform: "matrix3d(-1, 0, 0, 0, 0, 1, 0, 0, 0, 0, -1, 0, 0, 0, 0, 1);" }}></div>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        ) : null
                                                                                                                                        
                                                                                                                                    }
                                                                                                                                </div>}
                                                                                                                        </div>
                                                                                                                    : ''}
                                                                                                            </div>
                                                                                                            <div className={`image r-player-bar ${(inProgressTimer !== null && maxTimer!=1) || maxTimer>1 ? 'gauge-on' : ''}`}>
                                                                                                                <div className="r-player-bar-content">
                                                                                                                    {gamePlayData.roomId === currentTableId && gamePlayData.playerId == player.id ? 
                                                                                                                        (inProgressTimer !== null && maxTimer!=1? 
                                                                                                                        <div className="progressTimer inProgressTimer" style={{ '--duration': inProgressTimer }}>
                                                                                                                            <div className="countdown-tr" style={{ 'animation-duration': inProgressTimer }}></div>
                                                                                                                            <div className="countdown-bl" style={{ 'animation-duration': inProgressTimer }}></div>
                                                                                                                        </div>
                                                                                                                        :(maxTimer>1?
                                                                                                                        <div className="progressTimer maxTimer" style={{ '--duration': maxTimer }}>
                                                                                                                            <div className="countdown-tr" style={{ 'animation-duration': maxTimer }}></div>
                                                                                                                            <div className="countdown-bl" style={{ 'animation-duration': maxTimer }}></div>
                                                                                                                        </div>
                                                                                                                        :"")
                                                                                                                        ) : ''}
                                                                                                                    <div className="bar-text top-line player-name">
                                                                                                                        {/*<div className="pname-txt">{player.username.length > 10 ? player.username.slice(0, 10) + '...' : player.username}</div>*/}
                                                                                                                        <div className="pname-txt">{player.username.split('_')[0]}</div>
                                                                                                                        {/*
                                                                                                                        {gamePlayData.roomId === currentTableId && gamePlayData.playerId == player.id ? (inProgressTimer !== null && maxTimer!=1? <div className="timer inProgressTimer" style={{ '--duration': inProgressTimer }}></div>:(maxTimer>1?<div className="timer maxTimer" style={{ '--duration': maxTimer }}></div>:"")) : ''}
                                                                                                                        */}
                                                                                                                    </div>
                                                                                                                    <div className="bar-text bottom-line player-cash noCash">{safeToFixed(player.BuyInAmount)}</div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            {/*winnerSeatIndex == seat && winningAmount > 0 */  winnerSeatIndex && winnerSeatIndex[seat] && winningAmount[seat] > 0 ?
                                                                                                                <div className="PlayerWonContainer PlayerWonContainer_v_default PlayerWonContainer_c_dark r-seat__won_message">
                                                                                                                    <div className="PlayerWonContainer__content">
                                                                                                                        <div>
                                                                                                                            <div className="PlayerWonContainer__message winner default_player_won_animation-enter-done">
                                                                                                                                <div className="PlayerWonContainer__funds"><div className="PlayerWonContainer__funds__text">+ {winningAmount[seat]}</div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div> : ''}
                                                                                                            {/*winnerSeatIndex == seat && winningType != ''*/ winnerSeatIndex && winnerSeatIndex[seat] && winningType && winningType[seat]!=''?
                                                                                                                <div className="seat-combination-names winner">
                                                                                                                    <div className="panel seat-combination-names-content" tabindex="-1">
                                                                                                                        <div className="names">
                                                                                                                            <div className={`name ${getWinningClassName(winningType[seat])}`}>
                                                                                                                                <img src={require(`../Images/winimg/${getWinningClassName(winningType[seat])}.png?v=01`)} alt="Winning Image" />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                : ''}
                                                                                                                
                                                                                                            {/*    

                                                                                                            {playerActionData.playerId == player.id && getActionName(playerActionData) != '' ?
                                                                                                                <div className={`bar-text player-action pa-fold ${getActionName(playerActionData)}-action-group`}>
                                                                                                                    <div className="text">{getActionName(playerActionData)}</div>
                                                                                                                </div>:''}
                                                                                                            {player.status == 'Fold' ?
                                                                                                                <div className={`bar-text player-action pa-fold Fold-action-group`}>
                                                                                                                    <div className="text">Fold</div>
                                                                                                                </div>
                                                                                                            :player.status == 'Ideal' || (idealPlayer && idealPlayer.playerId === player.id) || (isTournament==true && player.idealPlayer==true)?
                                                                                                                <div className={`bar-text player-action pa-fold Fold-action-group`}>
                                                                                                                    <div className="text">Away</div>
                                                                                                                </div>
                                                                                                            : player.status=='Waiting'?
                                                                                                                <div className={`bar-text player-action pa-fold Fold-action-group`}>
                                                                                                                    <div className="text">Wait</div>
                                                                                                                </div>
                                                                                                            : ''
                                                                                                            }
                                                                                                            
                                                                                                            */}
                                                                                                            
                                                                                                            {/* 토너먼트에서 처리하려고 상단껄로 수정함
                                                                                                            <div>
                                                                                                                {playerActionData.playerId == player.id && getActionName(playerActionData) != '' ?
                                                                                                                    <div className={`bar-text player-action pa-fold ${getActionName(playerActionData)}-action-group`}>
                                                                                                                        <div className="text">{getActionName(playerActionData)}@</div>
                                                                                                                    </div>
                                                                                                                    : ''}
                                                                                                                {player.status == 'Fold' ?
                                                                                                                    <div className={`bar-text player-action pa-fold Fold-action-group`}>
                                                                                                                        <div className="text">Fold@</div>
                                                                                                                    </div>
                                                                                                                    : ''}
                                                                                                                {player.status == 'Ideal' || (idealPlayer && idealPlayer.playerId === player.id) ?
                                                                                                                    <div className={`bar-text player-action pa-fold Fold-action-group`}>
                                                                                                                        <div className="text">Away@</div>
                                                                                                                    </div>
                                                                                                                    : ''}
                                                                                                                {player.status == 'Waiting' ?
                                                                                                                    <div className={`bar-text player-action pa-fold Fold-action-group`}>
                                                                                                                        <div className="text">Wait@</div>
                                                                                                                    </div>
                                                                                                                    : ''}
                                                                                                            </div>
                                                                                                            */}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            } else if (checkSeatAvailable(seat)) {
                                                                                                return (
                                                                                                    <div>
                                                                                                        {/* {gameStart == false ?
                                                                                                                <div key={key}>
                                                                                                                    <div className={`r-seat s-5 seat-template-${getFillCss(seat)} left-direction default`}>
                                                                                                                        <div className="panel button free-seat" tabIndex="-1" onClick={() => selectBuyIn(seat)}></div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                : ''
                                                                                                            } */}
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        })}
                                                                                    </div>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <div key={key}>
                                                                                        <div className={`r-seat s-5 seat-template-${getFillCss(seat)} left-direction default`}>
                                                                                            <div className="panel button free-seat" tabIndex="-1" onClick={() => selectBuyIn(seat)}></div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    {/* 자리를 선택하세요 */}
                                                    
                                                    {
                                                    tableSeats?.some(seat => 
                                                        playerInfo.length > 0 
                                                          ? (!checkPlayerIsSeated() && checkSeatAvailable(seat)) 
                                                          : true
                                                      ) && (
                                                        <div className="sitdown-pls">
                                                          <img src={'../Images/sitdown.png'} className="sitdown-img" alt="Sit down" />
                                                        </div>
                                                    )
                                                        
                                                    }
                                                    
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/* Sound Btn */}
                                {/*
                                <div className={`sound-btn-wrap ${chatOpen ? 'chat-on' : 'chat-off'}`} onClick={() => soundOnOff()}>
                                    <div className={`sound-btn-${voice === true ? "on" : "off"}`}></div>
                                </div>
                                */}
                                <div className="table-footer-container">
                                    <div className="panel permanent-auto-actions-container permanent-auto-actions-container_v_default permanent-auto-actions-container_c_blue count_0 expanded" tabindex="-1">
                                        <div className="permanent-auto-actions-container-content">
                                            <div className="panel button expand-button" tabindex="-1">
                                                <div className="button-content"></div>
                                            </div>
                                            <div className="permanent-auto-actions-block"></div>
                                        </div>
                                    </div>
                                    {chatOpen ?
                                    <div className="chat-open-button-wrapper">
                                        <div className="panel button SimpleButton SimpleButton_v_panel_table SimpleButton_c_blue SimpleButton_use_icon chat-open-button SimpleButton_interactive" tabindex="-1" onClick={() => openSideBar()}>
                                            <div className="SimpleButton__content">
                                                <div className="panel Icon Icon_v_default Icon_c_white Icon_val_open-table-chat SimpleButton__icon" tabindex="-1">
                                                    <div className="Icon__content"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ''}
                                    
                                    
                                    <div className="table-footer-container-content">
                                        <div className="table-actions-container table-actions-container_v_default table-actions-container_c_blue">
                                            <div className="table-actions-container-content">
                                                <div className="player-actions">
                                                    <div className="actions">
                                                        {/**/}
                                                        {isCardView == true && Array.isArray(playerCards.cards) && playerCards.cards[0] && playerCards.cards[1] ?<div className="d-flex s-wrapper">
                                                            <div className="shw-wrap-title">패 보여주기</div>
                                                            <div className="shw-wrap" onClick={() => CardViewAction(1)}>
                                                                <div className="card-face showcard" style={{ backgroundImage: `url('../Images/Deck/cardprv/darkbg/${playerCards.cards[0]}.png?v=002')` }}></div>
                                                                {/*<button className='footerBtn btnDefault'>보이기</button>*/}
                                                            </div>
                                                            <div className="shw-wrap" onClick={() => CardViewAction(2)}>
                                                                <div className="card-face showcard" style={{ backgroundImage: `url('../Images/Deck/cardprv/darkbg/${playerCards.cards[1]}.png?v=002')` }}></div>
                                                                {/*<button className='footerBtn btnDefault'>보이기</button>*/}
                                                            </div>
                                                            <div className="shw-wrap" onClick={() => CardViewAction(3)}>
                                                                <div className="card-face showcard" style={{ backgroundImage: `url('../Images/Deck/cardprv/darkbg/${playerCards.cards[0]}.png?v=002')` }}></div>
                                                                <div className="card-face showcard" style={{ backgroundImage: `url('../Images/Deck/cardprv/darkbg/${playerCards.cards[1]}.png?v=002')` }}></div>
                                                                {/*<button className='footerBtn btnDefault'>보이기</button>*/}
                                                            </div>
                                                        </div>
                                                        :""}
                                                        {/**/}
                                                        
                                                        {gameStart && gamePlayData.roomId === currentTableId ?
                                                            isPanelOpen == true && gamePlayData.playerId == playerData.playerId ?
                                                                <div className='actionsWrapper'>
                                                                    <div className='footerAction'>
                                                                        {/*isRaiseAmount ?
                                                                            <div className='footerActionBtn' >
                                                                                <button className='footerBtn btnDefault' onClick={() => gamePlayData.buttonAction.raise ? PlayerAction(3, true, gamePlayData.buttonAction.minRaise * 2) : PlayerAction(3, false, gamePlayData.buttonAction.minRaise * 2)}>2X</button>
                                                                                <button className='footerBtn btnDefault' onClick={() => gamePlayData.buttonAction.raise ? PlayerAction(3, true, gamePlayData.buttonAction.minRaise * 3) : PlayerAction(3, false, gamePlayData.buttonAction.minRaise * 3)}>3X</button>
                                                                                <button className='footerBtn btnDefault' onClick={() => gamePlayData.buttonAction.raise ? PlayerAction(3, true, gamePlayData.buttonAction.minRaise * 4) : PlayerAction(3, false, gamePlayData.buttonAction.minRaise * 4)}>4X</button>
                                                                            </div>
                                                                            :
                                                                            <div className='footerActionBtn'>
                                                                                {gamePlayData.buttonAction.raise || gamePlayData.buttonAction.bet ? <button className='footerBtn btnDefault' onClick={() => gamePlayData.buttonAction.raise ? PlayerAction(3, true, gamePlayData.buttonAction.minRaise * 2) : PlayerAction(3, false, gamePlayData.buttonAction.minRaise * 2)}>&nbsp;&nbsp;2X&nbsp;&nbsp;<span class='br'>({safeToFixed(gamePlayData.buttonAction.minRaise * 2)})</span></button> : ''}
                                                                                {gamePlayData.buttonAction.raise || gamePlayData.buttonAction.bet ? <button className='footerBtn btnDefault' onClick={() => gamePlayData.buttonAction.raise ? PlayerAction(3, true, gamePlayData.buttonAction.minRaise * 3) : PlayerAction(3, false, gamePlayData.buttonAction.minRaise * 3)}>&nbsp;&nbsp;3X&nbsp;&nbsp;<span class='br'>({safeToFixed(gamePlayData.buttonAction.minRaise * 3)})</span></button> : ''}
                                                                                {gamePlayData.buttonAction.raise || gamePlayData.buttonAction.bet ? <button className='footerBtn btnDefault' onClick={() => gamePlayData.buttonAction.raise ? PlayerAction(3, true, gamePlayData.buttonAction.minRaise * 4) : PlayerAction(3, false, gamePlayData.buttonAction.minRaise * 4)}>&nbsp;&nbsp;4X&nbsp;&nbsp;<span class='br'>({safeToFixed(gamePlayData.buttonAction.minRaise * 4)})</span></button> : ''}
                                                                            </div>
                                                                        */}
                                                                        { /*
                                                                        
                                                                            isMobile ? '' : 
                                                                            (
                                                                            <div className='footerActionBtn'>
                                                                                {gamePlayData.buttonAction.raise || gamePlayData.buttonAction.bet ? <button className='footerBtn btnDefault' disabled={footerBtnDisabled(0.25)} onClick={() => gamePlayData.buttonAction.callAmount <= (tablePot * 0.25)?(gamePlayData.buttonAction.raise ? PlayerAction(3, true, tablePot * 0.25) : PlayerAction(3, false, tablePot * 0.25)):""}><span className="small-val">({safeToFixed(tablePot * 0.25)})</span><span className='br'>쿼터</span></button> : ''}
                                                                                {gamePlayData.buttonAction.raise || gamePlayData.buttonAction.bet ? <button className='footerBtn btnDefault' disabled={footerBtnDisabled(0.5)} onClick={() => gamePlayData.buttonAction.callAmount <= (tablePot * 0.5)?(gamePlayData.buttonAction.raise ? PlayerAction(3, true, tablePot * 0.5) : PlayerAction(3, false, tablePot * 0.5)):""}><span className="small-val">({safeToFixed(tablePot * 0.5)})</span><span className='br'>하프</span></button> : ''}
                                                                                {gamePlayData.buttonAction.raise || gamePlayData.buttonAction.bet ? <button className='footerBtn btnDefault' onClick={() => gamePlayData.buttonAction.raise ? PlayerAction(3, true, tablePot * 1) : PlayerAction(3, false, tablePot * 1)}><span className="small-val">({safeToFixed(tablePot * 1)})</span><span className='br'>풀</span></button> : ''}
                                                                            </div>
                                                                            )
                                                                            */
                                                                        }
                                                                        
                                                                        
                                                                        {/* <div className='footerActionBtn'>
                                                                            <button className='footerBtn btnDanger' onClick={() => PlayerAction(6, false, 0)}>FOLD</button>
                                                                            {gamePlayData.buttonAction.call ? <button className='footerBtn btnDefault' onClick={() => PlayerAction(4, false, 2)}>CALL <span>({gamePlayData.buttonAction.callAmount.toFixed(2)})</span></button> : ''}
                                                                            {gamePlayData.buttonAction.check ? <button className='footerBtn btnDefault' onClick={() => PlayerAction(2, false, 0)}>CHECK</button> : ''}
                                                                            {gamePlayData.buttonAction.raise ? <button className='footerBtn btnSuccess' onClick={() => PlayerAction(3, true, raiseAmount)}>RAISE <span>( {Number(raiseAmount).toFixed(2)})</span></button> : ''}
                                                                            {gamePlayData.buttonAction.bet ? <button className='footerBtn btnSuccess' onClick={() => PlayerAction(3, false, raiseAmount)}>BET <span>({Number(raiseAmount).toFixed(2)})</span></button> : ''}
                                                                            {gamePlayData.buttonAction.allIn ? <button className='footerBtn btnSuccess' onClick={() => PlayerAction(8, true, gamePlayData.buttonAction.allInAmount)}>ALL IN <span>({gamePlayData.buttonAction.allInAmount.toFixed(2)})</span></button> : ''}
                                                                        </div> */}
                                                                        {isRaiseAmount ?
                                                                            <div className='footerActionBtn btActions'>
                                                                                <button className='footerBtn btnDanger' onClick={() => setIsRaiseAmount(false)}>BACK</button>
                                                                                {gamePlayData.buttonAction.raise ? <button className='footerBtn btAction btnSuccess btRaiseBet' onClick={() => toggleBetRaise() }>레이스</button> : ''}
                                                                                {gamePlayData.buttonAction.bet ? <button className='footerBtn btAction btnSuccess btRaiseBet' onClick={() => toggleBetRaise() }>벳</button> : ''}
                                                                            </div>
                                                                            :
                                                                            <div className='footerActionBtn btActions'>
                                                                                
                                                                                <button className='footerBtn btnDanger btAction btFold' onClick={() => PlayerAction(6, false, 0)}>다이</button>
                                                                                
                                                                                {gamePlayData.buttonAction.call ? <button className='footerBtn btnDefault btAction btCheck call' onClick={() => PlayerAction(4, false, 2) }>콜<span style={{fontSize : "14px"}}>({gamePlayData.buttonAction.callAmount.toFixed(0)})</span></button> : ''}
                                                                                {gamePlayData.buttonAction.check ? <button className='footerBtn btnDefault btAction btCheck' onClick={() => PlayerAction(2, false, 0) }>체크</button> : ''}
                                                                                
                                                                                {gamePlayData.buttonAction.raise || gamePlayData.buttonAction.bet ?
                                                                                <div className='btAction raisebet-wrap'>
                                                                                    {/*
                                                                                    {gamePlayData.buttonAction.raise ? <button className='footerBtn btAction btnSuccess btRaiseBet' onClick={() => toggleBetRaise() }>레이스</button> : ''}
                                                                                    {gamePlayData.buttonAction.bet ? <button className='footerBtn btAction btnSuccess btRaiseBet' onClick={() => toggleBetRaise() }>벳</button> : ''}
                                                                                    */}
                                                                                    {gamePlayData.buttonAction.raise ? <button className='footerBtn btAction btnSuccess btRaiseBet' onClick={() =>  {PlayerAction(3, true, raiseAmount);playVoice(19); }}>레이스</button> : ''}
                                                                                    {gamePlayData.buttonAction.bet ? <button className='footerBtn btAction btnSuccess btRaiseBet' onClick={() => {PlayerAction(3, false, raiseAmount);playVoice(14); }}>벳</button> : ''}
                                                                                    
                                                                                        {isBetRaiseOpen && (gamePlayData.buttonAction.raise || gamePlayData.buttonAction.bet) ?
                                                                                            <div className="mid-wrap">
                                                                                                <div className="mwr-left">
                                                                                                    <button className='footerBtn btnSuccess btAllIn' onClick={() => PlayerAction(8, true, gamePlayData.buttonAction.allInAmount) }><span className="br">올인</span></button>
                                                                                                    {gamePlayData.buttonAction.raise || gamePlayData.buttonAction.bet ? (
                                                                                                      <button
                                                                                                        className='footerBtn btnDefault'
                                                                                                        disabled={footerBtnDisabled(0.25)}
                                                                                                        onClick={() => {
                                                                                                          if (gamePlayData.buttonAction.callAmount <= tablePot * 0.25) {
                                                                                                            gamePlayData.buttonAction.raise
                                                                                                              ? PlayerAction(3, true, tablePot * 0.25)
                                                                                                              : PlayerAction(3, false, tablePot * 0.25);
                                                                                                          }
                                                                                                          playVoice(18);
                                                                                                        }}
                                                                                                      >
                                                                                                        <span className='br'>쿼터</span>
                                                                                                      </button>
                                                                                                    ) : null}
                                                                                                    {gamePlayData.buttonAction.raise || gamePlayData.buttonAction.bet ? (
                                                                                                        <button 
                                                                                                            className='footerBtn btnDefault' 
                                                                                                            disabled={footerBtnDisabled(0.5)} 
                                                                                                            onClick={() => {
                                                                                                                if (gamePlayData.buttonAction.callAmount <= tablePot * 0.5) { 
                                                                                                                    gamePlayData.buttonAction.raise 
                                                                                                                        ? PlayerAction(3, true, tablePot * 0.5) 
                                                                                                                        : PlayerAction(3, false, tablePot * 0.5);
                                                                                                                }
                                                                                                                playVoice(17);
                                                                                                            }}
                                                                                                            >
                                                                                                            <span className='br'>하프</span>
                                                                                                        </button>
                                                                                                    ) : null}
                                                                                                    {gamePlayData.buttonAction.raise || gamePlayData.buttonAction.bet ? (
                                                                                                        <button 
                                                                                                            className='footerBtn btnDefault' 
                                                                                                            onClick={() => {gamePlayData.buttonAction.raise ? 
                                                                                                                PlayerAction(3, true, tablePot * 1) 
                                                                                                                : 
                                                                                                                PlayerAction(3, false, tablePot * 1);
                                                                                                                playVoice(16); }}>
                                                                                                            <span className='br'>풀</span>
                                                                                                        </button>
                                                                                                    ) : null}
                                                                                                </div>
                                                                                                <div className="mwr-right">
                                                                                                    <div className='footerSliderInput '>
                                                                                                        <div className='footerSliderBox'>
                                                                                                            <div className="rangeVal">
                                                                                                                <span>{safeToFixed(Number(raiseAmount))}</span>
                                                                                                            </div>
                                                                                                            <div className="vslidecontainer">
                                                                                                                <div className="vslider-wrap">  
                                                                                                                    <div class="vsbtn-wrap">
                                                                                                                    {raiseAmount > gamePlayData.buttonAction.minRaise ?
                                                                                                                        <div className='vsbtn minus-vsbtn' onClick={(e) => raiseAmountChange(Number(raiseAmount) - Number(smallBlindChips))}></div> :
                                                                                                                        <div className='vsbtn minus-vsbtn disabled' disabled></div>
                                                                                                                    }
                                                                                                                    </div>
                                                                                                                    
                                                                                                                    
                                                                                                                    <div class="range-wrap">
                                                                                                                    
                                                                                                                        <input
                                                                                                                            className='vslider raiseRangeSlide'
                                                                                                                            value={raiseAmount}
                                                                                                                            step={Number(smallBlindChips)}
                                                                                                                            min={gamePlayData.buttonAction.minRaise}
                                                                                                                            max={gamePlayData.buttonAction.maxRaise}
                                                                                                                            type="range"
                                                                                                                            onChange={(e) => raiseAmountChange(e.target.value)}
                                                                                                                            style={{ "--raisepct": `${raisePct}%` }}
                                                                                                                         />
                                                                                                                    </div>
                                                                                                                    
                                                                                                                    
                                                                                                                    <div className="vsbtn-wrap">
                                                                                                                    {raiseAmount < gamePlayData.buttonAction.maxRaise ?
                                                                                                                        <div className='vsbtn add-vsbtn' onClick={(e) => raiseAmountChange(Number(raiseAmount) + Number(smallBlindChips))}></div> :
                                                                                                                        <div className='vsbtn add-vsbtn disabled' disabled></div>
                                                                                                                    }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        : ''}
                                                                                
                                                                                </div>
                                                                                : ''}
                                                                                {gamePlayData.buttonAction.allIn ? <button className='footerBtn btAction btnSuccess btAllIn' onClick={() => PlayerAction(8, true, gamePlayData.buttonAction.allInAmount) }>올인</button> : ''}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                :
                                                                
                                                                isFooterPanel && footerPanelPlayerStatus()?
                                                                    <div className='footerAction' >
                                                                        
                                                                        <div className='footerActionCheckBoxRight game-checkbox'>
                                                                            <div className='d-flex'>
                                                                                <div className='chk-btn'>
                                                                                    <label className="form-control">
                                                                                        <input type="checkbox" name="checkbox" checked={defaultAction == 'isFold' ? true : false} onChange={(e) => defaultActionSelection(e, 0)} />
                                                                                        <span>다이 예약</span>
                                                                                    </label>
                                                                                </div>
                                                                                <div className='chk-btn'>
                                                                                    <label className="form-control">
                                                                                        <input type="checkbox" name="checkbox" checked={defaultAction == 'isCall' ? true : false} onChange={(e) => defaultActionSelection(e, 2)} />
                                                                                        <span>콜 예약</span>
                                                                                    </label>
                                                                                </div>
                                                                                <div className='chk-btn'>
                                                                                    <label className="form-control">
                                                                                        <input type="checkbox" name="checkbox" checked={defaultAction == 'isCheck' ? true : false} onChange={(e) => defaultActionSelection(e, 1)} />
                                                                                        <span>체크 / 다이</span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                : ''
                                                            : ''
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {playerInfo.length > 0 &&
                                        playerInfo.map((player, index) => {
                                            if (player.id === playerData.playerId && ((player.idealPlayer === true) || (idealPlayer && idealPlayer.playerId === playerData.playerId))) {
                                                return (
                                                    <div className="am-back-button-wrapper">
                                                        <div className="panel button SimpleButton SimpleButton_v_panel_table SimpleButton_c_blue SimpleButton_use_icon chat-open-button SimpleButton_interactive" tabindex="-1" onClick={() => sendPlayerOnline()}>
                                                            <div className="SimpleButton__content">
                                                                <div className="panel Icon Icon_v_default Icon_c_white SimpleButton__icon" tabindex="-1">
                                                                    <div className="Icon__content">
                                                                        <button className='' >
                                                                            게임참여
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            } else if(player.id === playerData.playerId && player.status=='Ideal' && player.BuyInAmount == 0){
                                                return (
                                                    <div className="am-back-button-wrapper">
                                                        <div className="panel button SimpleButton SimpleButton_v_panel_table SimpleButton_c_blue SimpleButton_use_icon chat-open-button SimpleButton_interactive" tabindex="-1" onClick={() => addMoreChips()}>
                                                            <div className="SimpleButton__content">
                                                                <div className="panel Icon Icon_v_default Icon_c_white SimpleButton__icon" tabindex="-1">
                                                                    <div className="Icon__content">
                                                                        <button className='' >
                                                                            게임참여
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            } else {
                                                return null; // or an alternative JSX element or fragment
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Models */}

                {/* {tableData ?
                    <Modal
                        isOpen={isRoomModalOpen}
                        onRequestClose={closeModal}
                        style={customStyles}    
                        contentLabel="Example Modal"
                        ariaHideApp={false}
                    >
                        <div className="buyInModal gameModel">
                            <h2>Available Chips : {tableData.playerChips.toFixed(2)}</h2>
                            <hr></hr>
                            <div className='chipsDetails mb-4'>
                                <div className='d-flex gap-3'>
                                    <div className='minBuyIn p-col-3'>
                                        <p>Min</p>
                                        <span>{minBuyIn.toFixed(2)}</span>
                                    </div>
                                    <div className='chipsBuyIn p-col-6'>
                                        <input
                                            value={start}
                                            type="number"
                                            onChange={(e) => setStart(e.target.value)}
                                            min={minBuyIn}
                                            max={maxBuyIn}
                                            readOnly
                                        ></input>
                                    </div>
                                    <div className='maxBuyIn p-col-3'>
                                        <p>Max</p>
                                        <span>{maxBuyIn.toFixed(2)}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='sliderInput center'>
                                <input
                                    value={start}
                                    step="0.1"
                                    min={minBuyIn}
                                    max={maxBuyIn}
                                    type="range"
                                    onChange={(e) => onStartChange(e.target.value)}
                                    style={{ width: "80%" }}
                                ></input>
                            </div>
                            {buyIn ?
                                <button className=' btn selectBuyInBtn' onClick={() => sitOnTable(1)}>Add Chips</button>
                                :
                                <button className=' btn selectBuyInBtn' onClick={() => sitOnTable(0)}>{lang.ko.select}</button>
                            }

                        </div>
                    </Modal> : ''} */}

                <Modal
                    isOpen={sendGiftModal}
                    onRequestClose={closeModal}
                    style={customStylesLogout}
                    contentLabel="Example Modal"
                    ariaHideApp={false}
                >
                    <div className='plyerProfile'>
                        <div className=''>
                            <div className="cashier-modal">
                                <div className='d-flex min-h-100'>
                                    <div className='p-col-12 min-h-100 theme-bg'>
                                        <div className='cashier-modal-card'>
                                            <div className='cashier-modal-heading gift-list-heading'>
                                                <h2>Buy Gift</h2>
                                                <img src={'../Images/close.png'} onClick={() => closeModal(false)}></img>
                                            </div>
                                            <div className='cashier-modal-body'>
                                                <div className="changeavatar ">
                                                    <div className='text-center'>
                                                        <Row>
                                                            {gameGifts.length > 0 ?
                                                                gameGifts.map((gift, index) => {
                                                                    return (
                                                                        <Col lg={2} onClick={() => setGiftForPlayer(gift)}>
                                                                            <div className={giftForPlayer._id != '' && giftForPlayer._id == gift._id ? 'giftImg activeGift' : 'giftImg'}>
                                                                                <div className='img-box'>
                                                                                    <img key={index} src={BackendURL + 'gameGift/' + gift.image} className=''></img>
                                                                                </div>
                                                                                <p className='giftname'>{gift.name}</p>
                                                                                <p className='giftamount'>{gift.amount}</p>
                                                                            </div>
                                                                        </Col>
                                                                    )
                                                                })
                                                                : ''
                                                            }
                                                        </Row>
                                                        {giftForPlayer != '' ?
                                                            <button className='selectBuyInBtn mt-4 ' onClick={() => sendGiftToOtherPlayer()}>Send Gift</button> :
                                                            <button className='selectBuyInBtn  mt-4 disabled' disabled>Send Gift</button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal >
                {/* <Modal
                    isOpen={sendGiftModal}
                    onRequestClose={closeModal}
                    style={customStylesLogout}
                    contentLabel="Example Modal"
                    ariaHideApp={false}
                >
                    <div className='giftModal   '>
                        <div className='modalBody'>
                            <h2 className=''>Gifts</h2>
                            <center>
                                <Row>
                                    {gameGifts.length > 0 ?
                                        gameGifts.map((gift, index) => {
                                            return <Col lg={2} className='giftImg'>
                                                <img src={BackendURL + 'gameGift/' + gift.image} ></img>
                                                <p className='giftname'>{gift.name}</p>
                                                <p className='giftamount'>{gift.amount}</p>
                                            </Col>
                                        })
                                        : ''
                                    }

                                </Row>
                            </center>
                        </div>
                    </div>
                </Modal> */}
                {/* {sendGiftModal ?
                    <div className='poker-root'>
                        <div className="panel Popup Popup_v_default Popup_c_blue Dialog OutOfMoneyContainer OutOfMoneyContainer_v_default OutOfMoneyContainer_c_dark Dialog_v_default Dialog_c_blue Popup_modal Popup_isModal" tabindex="0" style={{ zIndex: "105" }}>
                            <div className="Popup__overlay Dialog__popup_overlay show-dialog-overlay-animation"></div>
                            <div className="Popup__wrapper">
                                <div className="panel Popup__content Dialog__popup_content Dialog__popup_content_Warning show-dialog-animation" tabindex="-1">
                                    <div className="Dialog__header emptyTitle">
                                        <div className="Dialog__title">
                                            <div className="Dialog__title_content">Gifts</div>
                                        </div>
                                        <div className="Dialog__top_actions">
                                            <div className="panel button SimpleButton SimpleButton_v_transparent SimpleButton_c_light SimpleButton_use_icon Dialog__top_action close_button SimpleButton_interactive" tabindex="-1" onClick={(e) => closeModal()}>
                                                <div className="SimpleButton__content">
                                                    <div className="panel Icon Icon_v_default Icon_c_white Icon_val_close_popup SimpleButton__icon Dialog__top_action_icon" tabindex="-1">
                                                        <div className="Icon__content"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="Dialog__content">
                                        <div className="OutOfMoneyContainer__content">
                                            <div className="scroll-panel OutOfMoneyContainer__scroll poker-popup-body">
                                                <div className="scroll-wrapper poker-popup-body-inner-first d-flex">

                                                </div>
                                                <div className="iScrollVerticalScrollbar iScrollLoneScrollbar show-hide-scrollbar-animation poker-popup">
                                                    <div className="iScrollIndicator poker-popup-body-inner-sec">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''} */}

                {isLogoutModalOpen ?
                    <div className='poker-root'>
                        <div className={`panel Popup Popup_v_default Popup_c_blue Dialog OutOfMoneyContainer OutOfMoneyContainer_v_default OutOfMoneyContainer_c_dark Dialog_v_default Dialog_c_blue Popup_modal Popup_isModal logoutPopup ${isMobile && 'popmobile'}`} tabindex="0" style={{ zIndex: "105" }}>
                            <div className="Popup__overlay Dialog__popup_overlay show-dialog-overlay-animation"></div>
                            <div className="Popup__wrapper">
                                <div className="panel Popup__content Dialog__popup_content Dialog__popup_content_Warning show-dialog-animation glowing-border" tabindex="-1">
                                    <div className="Dialog__top_actions">
                                        <div className="Dialog__header emptyTitle">
                                            <div className="Dialog__title">
                                                <div className="Dialog__title_content">{lang.ko.warning}</div>
                                            </div>
                                            
                                        </div>
                                        <div className="panel button SimpleButton SimpleButton_v_transparent SimpleButton_c_light SimpleButton_use_icon Dialog__top_action close_button SimpleButton_interactive" tabindex="-1" onClick={(e) => closeModal()}>
                                            <div className="SimpleButton__content">
                                                <div className="panel Icon Icon_v_default Icon_c_white Icon_val_close_popup SimpleButton__icon Dialog__top_action_icon" tabindex="-1">
                                                    <div className="Icon__content"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="Dialog__content">
                                        <div className="OutOfMoneyContainer__content">
                                            <div className="scroll-panel OutOfMoneyContainer__scroll poker-popup-body">
                                                <div className="scroll-wrapper poker-popup-body-inner-first">
                                                    <div className="OutOfMoneyContainer__text">
                                                        { gameStart == true || (gameStart==false && checkPlayerBetAmount())?
                                                            lang.ko.alert_msg_leave_game_next_hand
                                                            :lang.ko.alert_msg_leave_game
                                                        }
                                                    </div>
                                                    <div className='avtarBtnSection'>
                                                        <button className='selectBuyInBtn confirm-btn rounded-btn' onClick={() => leaveGame()}>{lang.ko.yes}</button>
                                                        <button className='selectBuyInBtn cancel-btn rounded-btn' onClick={() => closeModal()}>{lang.ko.no}</button>
                                                    </div>
                                                </div>
                                                <div className="iScrollVerticalScrollbar iScrollLoneScrollbar show-hide-scrollbar-animation poker-popup">
                                                    <div className="iScrollIndicator poker-popup-body-inner-sec">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="Dialog__actions">
                                    <div className="panel button SimpleButton SimpleButton_v_flat SimpleButton_c_success_blue SimpleButton_use_text Dialog__action OutOfMoneyContainer__deposit_action SimpleButton_interactive" tabindex="0">
                                        <div className="SimpleButton__content">
                                            <div className="SimpleButton__text">{lang.ko.cashier}</div>
                                        </div>
                                    </div>
                                    <div className="panel button SimpleButton SimpleButton_v_flat SimpleButton_c_secondary_blue SimpleButton_use_text Dialog__action OutOfMoneyContainer__close_action SimpleButton_interactive" tabindex="0">
                                        <div className="SimpleButton__content">
                                            <div className="SimpleButton__text">Close</div>
                                        </div>
                                    </div>
                                </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}

                {isRoomModalOpen && tableData ?
                    <div className='poker-root'>
                        <div className={`panel Popup Popup_v_default Popup_c_blue Dialog OutOfMoneyContainer OutOfMoneyContainer_v_default OutOfMoneyContainer_c_dark Dialog_v_default Dialog_c_blue Popup_modal Popup_isModal addChipPopup ${isMobile && 'popmobile'}` } tabindex="0" style={{ zIndex: "105" }}>
                            <div className="Popup__overlay Dialog__popup_overlay show-dialog-overlay-animation"></div>
                            <div className="Popup__wrapper">
                                <div className="panel Popup__content Dialog__popup_content show-dialog-animation glowing-border" tabindex="-1">
                                    <div className="Dialog__top_actions">
                                        <div className="Dialog__header emptyTitle addChipPanel">
                                            <div className="Dialog__title">
                                                <div className="Dialog__title_content">{lang.ko.available_chips} : {safeToFixed(tableData.playerChips)}</div>
                                            </div>
                                        </div>
                                        <div className="panel button SimpleButton SimpleButton_v_transparent SimpleButton_c_light SimpleButton_use_icon Dialog__top_action close_button SimpleButton_interactive" tabindex="-1" onClick={(e) => closeModal()}>
                                            <div className="SimpleButton__content">
                                                <div className="panel Icon Icon_v_default Icon_c_white Icon_val_close_popup SimpleButton__icon Dialog__top_action_icon" tabindex="-1">
                                                    <div className="Icon__content"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="Dialog__content">
                                        <div className="OutOfMoneyContainer__content">
                                            <div className="scroll-panel OutOfMoneyContainer__scroll poker-popup-body">
                                                <div className="scroll-wrapper poker-popup-body-inner-first">
                                                    <div className="OutOfMoneyContainer__text">
                                                        <div className="buyInModal gameModel">
                                                            
                                                            <div className='chipsDetails m-2'>
                                                                <div className='d-flex gap-3'>
                                                                    <div className='minBuyIn p-col-3'>
                                                                        <button className="buyin-btn" onClick={() => setToMinMaxBuyIn(minBuyIn)}>Min</button>
                                                                        <span>{safeToFixed(minBuyIn)}</span>
                                                                    </div>
                                                                    <div className='chipsBuyIn p-col-6'>
                                                                        <input
                                                                            className= 'chipsBuyIn_input'
                                                                            value={start}
                                                                            type="number"
                                                                            onChange={handleChipsChange}
                                                                            min={minBuyIn}
                                                                            max={maxBuyIn}
                                                                        ></input>
                                                                    </div>
                                                                    <div className='maxBuyIn p-col-3'>
                                                                        <button className="buyin-btn" onClick={() => setToMinMaxBuyIn(maxBuyIn)}>Max</button>
                                                                        <span>{safeToFixed(maxBuyIn)}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='sliderInput center'>
                                                                <input
                                                                    className="buyinSlider"
                                                                    value={start}
                                                                    step="0.1"
                                                                    min={minBuyIn}
                                                                    max={maxBuyIn}
                                                                    type="range"
                                                                    onChange={handleChipsChange}
                                                                    style={{ width: "100%", "--sliderpct": `${sliderPct}%` }}
                                                                ></input>
                                                            </div>
                                                            {buyIn ?
                                                                <button className=' btn selectBuyInBtn confirm-btn rounded-btn' onClick={() => sitOnTable(1)}>{lang.ko.add_chips}</button>
                                                                :
                                                                <button className=' btn selectBuyInBtn confirm-btn rounded-btn' onClick={() => sitOnTable(0)}>{lang.ko.select}</button>
                                                            }

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="iScrollVerticalScrollbar iScrollLoneScrollbar show-hide-scrollbar-animation poker-popup">
                                                    <div className="iScrollIndicator poker-popup-body-inner-sec">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="Dialog__actions">
                                    <div className="panel button SimpleButton SimpleButton_v_flat SimpleButton_c_success_blue SimpleButton_use_text Dialog__action OutOfMoneyContainer__deposit_action SimpleButton_interactive" tabindex="0">
                                        <div className="SimpleButton__content">
                                            <div className="SimpleButton__text">{lang.ko.cashier}</div>
                                        </div>
                                    </div>
                                    <div className="panel button SimpleButton SimpleButton_v_flat SimpleButton_c_secondary_blue SimpleButton_use_text Dialog__action OutOfMoneyContainer__close_action SimpleButton_interactive" tabindex="0">
                                        <div className="SimpleButton__content">
                                            <div className="SimpleButton__text">Close</div>
                                        </div>
                                    </div>
                                </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}

                {isOnOpenBuyInPanel && isRoomModalOpen==false?
                    <div className='poker-root'>
                        <div className="panel Popup Popup_v_default Popup_c_blue Dialog OutOfMoneyContainer OutOfMoneyContainer_v_default OutOfMoneyContainer_c_dark Dialog_v_default Dialog_c_blue Popup_modal Popup_isModal pop_oom" tabindex="0" style={{ zIndex: "105" }}>
                            <div className="Popup__overlay Dialog__popup_overlay show-dialog-overlay-animation"></div>
                            <div className="Popup__wrapper">
                                <div className="panel Popup__content Dialog__popup_content show-dialog-animation" tabindex="-1">
                                    
                                    <div className="Dialog__top_actions">
                                        <div className="Dialog__header emptyTitle">
                                            <div className="Dialog__title">
                                                <div className="Dialog__title_content">칩추가</div>
                                            </div>
                                        
                                            <div className="panel button SimpleButton SimpleButton_v_transparent SimpleButton_c_light SimpleButton_use_icon Dialog__top_action close_button SimpleButton_interactive" tabindex="-1" onClick={(e) => closeModal()}>
                                                <div className="SimpleButton__content">
                                                    <div className="panel Icon Icon_v_default Icon_c_white Icon_val_close_popup SimpleButton__icon Dialog__top_action_icon" tabindex="-1">
                                                        <div className="Icon__content"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="Dialog__content">
                                        <div className="OutOfMoneyContainer__content">
                                            <div className="scroll-panel OutOfMoneyContainer__scroll poker-popup-body">
                                                <div className="scroll-wrapper poker-popup-body-inner-first">
                                                    <div className="OutOfMoneyContainer__text">
                                                        <div className="buyInModal gameModel">
                                                            <div className='chipsDetails mb-4'>
                                                                게임 플레이에 필요한 칩이 부족합니다. <br/>계속하려면 칩을 더 추가하세요.
                                                            </div>
                                                            <button className='confirm-btn rounded-btn btn selectBuyInBtn' onClick={() => addMoreChips()}>칩추가</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="iScrollVerticalScrollbar iScrollLoneScrollbar show-hide-scrollbar-animation poker-popup">
                                                    <div className="iScrollIndicator poker-popup-body-inner-sec">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}
            </div>
        </div >
    );
}

export default GameScreen