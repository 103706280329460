import React from 'react'
import { useState, useEffect } from 'react';
import './index.css'
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import socket from '../../socket';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import { PaginationControl } from 'react-bootstrap-pagination-control';

const domainName = window.location.hostname;
  
const isIncluded = domainName.includes("testholdem.smgame77.com");

let BackendURL; 
let FrontURL;

if(isIncluded) {
    BackendURL = "http://ec2-43-207-107-237.ap-northeast-1.compute.amazonaws.com:8080/";
    FrontURL = "http://ec2-43-207-107-237.ap-northeast-1.compute.amazonaws.com/";
} else {
    BackendURL = "http://ec2-13-231-89-219.ap-northeast-1.compute.amazonaws.com:8080/";
    FrontURL = "http://ec2-13-231-89-219.ap-northeast-1.compute.amazonaws.com/";
}

//const BackendURL = "http://ec2-13-231-89-219.ap-northeast-1.compute.amazonaws.com:8080/";
//const FrontURL = "http://ec2-13-231-89-219.ap-northeast-1.compute.amazonaws.com/";

const lang = require('../../Config/lang.js');

const INITIAL_STATE = {
  errorMsg: '',
  oldPassword: '',
  newPassword: '',
  verifyNewPassword: ''
};

const HandHistory = (props) => {
  
  const [gameHistoryList, setGameHistoryList] = useState({});
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(true)
  
  //const [form, setForm] = useState(INITIAL_STATE);
  const userData = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();
  
  //const [userProfilePhoto, setUserProfilePhoto] = useState(userData ? userData.avatar : 0);
  //const [userProfilePicOption, setUserProfilePicOption] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34]);
  //const [userProfileForm, setUserProfileForm] = useState(userData);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)
  
  //const [jackpotHistoryList, setJackpotHistoryList] = useState({});
  //const [affiliatesData, setAffiliatesData] = useState({});
  const [gameHistory, setGameHistory] = useState({})
  const [page, setPage] = useState(1);
  const [isGameHistory, setIsGameHistory] = useState(false);
  //const [isEditProfile, setIsEditProfile] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [preFlop, setPreFlop] = useState({});
  const [flop, setFlop] = useState({});
  const [turn, setTurn] = useState({});
  const [river, setRiver] = useState({});
  const [showDown, setShowDown] = useState({});
  //const [isCopyToClipboard, setIsCopyToClipboard] = useState(false);
  //const [tableThemeSkinColor, setTableThemeSkinColor] = useState('default');
  //const [tableThemeBackgroundColor, setTableThemeBackgroundColor] = useState('default');
  //const [tableThemeFrontDeck, setTableThemeFrontDeck] = useState('default');
  //const [tableThemeBackDeck, setTableThemeBackDeck] = useState('default');
  //const [tableSettingTab, seTableSettingTab] = useState('frontDeck');
  
  const [activeHistoryList, setActiveHistoryList] = useState({});
  const [isActiveHistory, setIsActiveHistory] = useState({
    table: 'DepositHistory'
  })

  const [isActive, setIsActive] = useState({
    table: 'handHistory'
  })

  const [isActiveHand, setIsActiveHand] = useState({
    table: 'game'
  })

  useEffect(() => {
    const mobile = window.innerWidth <= 1024;
    setIsMobile(mobile);
  }, [window.innerWidth]);

  useEffect(() => {
    hideShowDiv('handHistory');
  }, []);

  /*
  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.id]: event.target.value,
    });
  };
  */
  /*
  const replaceFirstThreeChars = (text) => {
      if (text.length <= 3) {
          return "***";
      }
      return "***" + text.slice(3);
  };
  */
  /*
  const handleProfileChange = (event) => {
    setUserProfileForm({
      ...userProfileForm,
      [event.target.id]: event.target.value,
    });
  };
  */
  /*
  const playerProfilePhoto = (img) => {
    return '../Images/Avtar/' + (img + 1) + '.png'
  }
  */

  const changePage = (value) => {
    setPage(value)
    
    var id = isActiveHistory.table;
    //console.log(id);
    /*
    if (id == 'CashHistory') {
      const data = {
        playerId: userData.playerId,
        pageNo: value,
        productName: 'PokerHills'
      }
      socket.emit('getWithdrawHistory', data, (res) => {
        //console.log(res)
        if (res.status == 'success') {
          //console.log(res.result.WithdrawHistoryList);
          setActiveHistoryList(res.result.WithdrawHistoryList);
        } else {
          setActiveHistoryList({});
        }
      });
    } else if (id == 'jackpotHistory') {
      const data = {
        playerId: userData.playerId,
        pageNo: value,
        productName: 'PokerHills'
      }
      socket.emit('jackpotHistory', data, (res) => {
        //console.log(res)
        if (res.status == 'success') {
          //console.log(res.result.jackpotHistory);
          setJackpotHistoryList(res.result);
        } else {
          setActiveHistoryList({});
        }
      });
    } else if (id == 'DepositHistory') {
      const data = {
        playerId: userData.playerId,
        pageNo: value,
        productName: 'PokerHills'
      }
      socket.emit('getDepositHistory', data, (res) => {
        //console.log(res)
        if (res.status == 'success') {
          //console.log(res.result.DepositHistoryList);
          setActiveHistoryList(res.result.DepositHistoryList);
        } else {
          setActiveHistoryList({});
        }
      });
    } else {
    
      const data = {
        playerId: userData.playerId,
        pageNo: value,
        productName: 'PokerHills'
      }
      socket.emit('playerGameHistory', data, (res) => {
        //console.log(res.result);
        setGameHistoryList(res.result);
      });
    }
    */
    
    
    const data = {
        playerId: userData.playerId,
        pageNo: value,
        productName: 'PokerHills'
      }
      socket.emit('playerGameHistory', data, (res) => {
        //console.log(res.result);
        setGameHistoryList(res.result);
      });
  }
  /*
  const changePlayerAvtar = (type) => {
    if (type == 0) {
      const data = {
        playerId: userData.playerId,
        profilePic: userProfilePhoto,
      }
      socket.emit('playerProfilePic', data, (res) => {
        if (res.status == 'success') {
          let gamePlayer = userData;
          gamePlayer.profilePic = userProfilePhoto
          localStorage.setItem('user', JSON.stringify(gamePlayer));
          props.refreshData();
          closeModal();
        }
      });
    } else {
      setUserProfilePhoto(userData.avatar)
      closeModal();
    }
  }
  */
  /*
  const changePlayerProfile = (type) => {
    const data = {
      playerId: userData.playerId,
      firstname: userProfileForm.firstname,
      lastname: userProfileForm.lastname,
      email: userProfileForm.email,
      mobile: userProfileForm.mobile,
    }
    socket.emit('changePlayerProfile', data, (res) => {
      if (res.status == 'success') {
        toast.success(res.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        socket.emit('Playerprofile', {
          playerId: userData.playerId,
          productName: 'PokerHills'
        }, (res) => {
          localStorage.setItem('user', JSON.stringify(res.result));
          props.refreshData();
          setIsEditProfile(false);
        });
      } else {
        toast.error(res.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    });
  }
  */
  /*
  const changePassword = (type) => {
    form.errorMsg = '';
    if (type == 0) {
      const data = {
        playerId: userData.playerId,
        oldPassword: form.oldPassword,
        newPassword: form.newPassword,
        verifyNewPassword: form.verifyNewPassword,
      }
      socket.emit('playerChangePassword', data, (res) => {
        if (res.status == 'success') {
          toast.success(res.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setForm({
            oldPassword: '',
            newPassword: '',
            verifyNewPassword: '',
          });
          closeModal();
        } else {
          toast.error(res.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      });
    } else {
      closeModal();
    }
  }
  */
  /*
  const saveTableThemeSetting = (e) => {
    const data = {
      playerId: userData.playerId,
      tableTheme: tableThemeSkinColor,
      tableBackground: tableThemeBackgroundColor,
      backDeck: tableThemeBackDeck,
      frontDeck: tableThemeFrontDeck,
      productName: 'PokerHills'
    }
    socket.emit('TableThemeSettingsUpdate', data, (res) => {
      closeModal();
    });
  }
  */
  
  const logout = (e) => {
    localStorage.removeItem("user");
    navigate('/')
  }

  const closeModal = () => {
    props.closePopup()
  }
  /*
  const openModal = () => {
    setIsLogoutModalOpen(true);
  }
  */
  const closeLogoutModal = () => {
    setIsLogoutModalOpen(false);
  }

  const hideShowDiv = (id) => {

    setIsActiveHistory({
      table: id,
    })

    setPage(1);

    if (id == 'handHistory') {
      setIsGameHistory(false);
      const data = {
        playerId: userData.playerId,
        pageNo: 1,
        productName: 'PokerHills'
      }
      socket.emit('playerGameHistory', data, (res) => {
        //console.log(res.result);
        setGameHistoryList(res.result);
      });
      setIsActive({
        table: 'gameplayer',
      })
    }

    setIsActive({
      table: id,
    })
  }

  const hideShowHandTab = (id) => {
    setPage(1)
    if (id == 'CashHistory') {
      const data = {
        playerId: userData.playerId,
        pageNo: 1,
        productName: 'PokerHills'
      }
      socket.emit('getWithdrawHistory', data, (res) => {
        //console.log(res)
        if (res.status == 'success') {
          //console.log(res.result.WithdrawHistoryList);
          setActiveHistoryList(res.result.WithdrawHistoryList);
        } else {
          setActiveHistoryList({});
        }
      });
      
      setIsActiveHistory({
        table: id,
      })
    }
    if (id == 'DepositHistory') {
      const data = {
        playerId: userData.playerId,
        pageNo: 1,
        productName: 'PokerHills'
      }
      socket.emit('getDepositHistory', data, (res) => {
        //console.log(res)
        if (res.status == 'success') {
          //console.log(res.result.DepositHistoryList);
          setActiveHistoryList(res.result.DepositHistoryList);
        } else {
          setActiveHistoryList({});
        }
      });
      
      setIsActiveHistory({
        table: id,
      })
    }
    
    setIsActiveHand({
      table: id,
    });
    
    
  }
  /*
  const createeNewAffiliaates = (code) => {
    const data = {
      playerId: userData.playerId,
      productName: 'PokerHills'
    }
    socket.emit('CeateMyAffiliates', data, (res) => {
      hideShowDiv('myAffiliates');
    });
  }
  */
  /*
  const copyToClipboard = (code) => {
    navigator.clipboard.writeText(FrontURL + '#/signup/' + code);
    setIsCopyToClipboard(true);
    setTimeout(() => {
      setIsCopyToClipboard(false);
    }, 1000);
  }
  */

  const openGameHistory = (gameId) => {
    const data = {
      playerId: userData.playerId,
      gameId: gameId,
      productName: 'PokerHills'
    }
    socket.emit('GameHistory', data, (res) => {
      if (res.result) {
        setIsGameHistory(true);
        hideShowHandTab('gameplayer')
        setGameHistory(res.result.gameHistory);
        const data = res.result.gameHistory;
        let preFlopArr = [];
        let flopArr = [];
        let turnArr = [];
        let riverArr = [];
        let showDownArr = [];
        data.handsEvents.map(((evnt, index) => {
          if (evnt.gameRound == 'Preflop') {
            preFlopArr.push(evnt);
          }
          if (evnt.gameRound == "Flop") {
            flopArr.push(evnt);
          }
          if (evnt.gameRound == 'Turn') {
            turnArr.push(evnt);
          }
          if (evnt.gameRound == 'River') {
            riverArr.push(evnt);
          }
          if (evnt.gameRound == 'ShowDown') {
            showDownArr.push(evnt);
          }
        }));
        setPreFlop(preFlopArr);
        setFlop(flopArr);
        setTurn(turnArr);
        setRiver(riverArr);
        setShowDown(showDownArr);
      }
    });
  }

  const playerActionArr = (action) => {
    if (action == 0) { return 'Small Blind' }
    else if (action == 1) { return 'Big Blind' }
    else if (action == 2) { return 'Check' }
    else if (action == 3) { return 'Bet' }
    else if (action == 4) { return 'Call' }
    else if (action == 5) { return '' }
    else if (action == 6) { return 'Fold' }
    else if (action == 7) { return 'Timeout' }
    else if (action == 8) { return 'All-in' }
    else if (action == 9) { return 'Straddle' }
    else { return 'Straddle' }
  }

  const mobile = window.innerWidth <= 1024;
  let customStylesLogout = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#242234',
      width: '30%',
      height: '35%',
      border: '1px solid #ffd050'
    },
  }
  let customStyles = {
    content: {
      inset: '44% auto auto 49.5%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#474B6B00',
      width: "60.625em",
      border: '1px solid #ffd0506e',
      boxShadow: '4px 4px 18px #ffd05075, -4px -4px 18px #ffd05075',
      padding: '0px',
      borderRadius: '15px',
    },
  }
  if (mobile) {
    customStylesLogout = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#242234',
        width: '60%',
        height: '30%',
        border: 'none'
      },
    }
    customStyles = {
      content: {
        inset: '50% auto auto 50%',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#474B6B00',
        width: '100%',
        padding: '0px',
        border: 'none'
      },
    }
  }
  /*
    const historyTabStatus = function(text){
      switch(text)
      {
        case 'Pending': case 'pending':
          return '보류';
          break;
        case 'Approved': case 'approved':
          return '승인';
          break;
        case 'Denied': case 'denied':
          return '실패';
          break;
        default:
          return text;
          break;
      }

    }
    */
    
  return (
    <div >
      <ToastContainer />
      <Modal
        isOpen={isAccountModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className={ mobile ? 'mobileView plyerProfile' : 'plyerProfile'}>
          <div className="cashier-modal myacc">
            <div className='d-flex h-100'>
              <div className='p-col-12 min-h-100 theme-bg'>
                <div className='cashier-modal-card h-100'>
                  <div className='cashier-modal-heading pb-4'>
                    <h2 >{lang.ko.hands_history}</h2>
                      <img src={'../Images/close.png'} onClick={() => closeModal(false)}></img>
                  </div>
                  <div className='cashier-modal-body h-100 pb-5'>
 
                    <div className={isActive.table === 'handHistory' ? `handHistory` : 'handHistory d-none'}>
                      {isGameHistory ?
                        <div className='gameHistoryData'>
                          <div className='playerProfileInfo pt-0'>
                            <Row>
                              <Col lg={12}>
                                <div className='d-flex gap-2 login_header no_border m-0 mb-2'>
                                  <h6 className='active goback-btn confirm-btn rounded-btn' onClick={() => setIsGameHistory(false)}>뒤로가기</h6>
                                </div>
                                <div className='cashier-box'>
                                  <Row>
                                    <Col lg={6}>
                                      <div className='mb-1'>
                                        <label>{lang.ko.game_name}</label>
                                        <p className='profileDetails'>{gameHistory.game?.tableName}</p>
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className='mb-1'>
                                        <label>Game ID</label>
                                        <p className='profileDetails'>{gameHistory.gameName}</p>
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className='mb-1'>
                                        <label>{lang.ko.stakes}</label>
                                        <p className='profileDetails'>{gameHistory.stack}</p>
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className='mb-1'>
                                        <label>{lang.ko.date_time}</label>
                                        <p className='profileDetails'>{moment(gameHistory.dataTime).format("DD MMM, YYYY hh:mm A")}</p>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              <Col lg={12}>
                                <div className={mobile ? "mobileView" : ''}>
                                  <div className='d-flex gap-2 login_header'>
                                    <h6 className={isActiveHand.table === 'gameplayer' ? 'active' : ''} id="gameplayer" onClick={(e) => { hideShowHandTab(e.target.id) }}>{lang.ko.players}</h6>
                                    {gameHistory?.winners?.length > 0 ? <h6 className={isActiveHand.table === 'winner' ? 'active' : ''} id="winner" onClick={(e) => { hideShowHandTab(e.target.id) }}>Winners</h6> : ''}
                                    {preFlop?.length > 0 ? <h6 className={isActiveHand.table === 'preflop' ? 'active' : ''} id="preflop" onClick={(e) => { hideShowHandTab(e.target.id) }}>Pre Flop</h6> : ''}
                                    {flop?.length > 0 ? <h6 className={isActiveHand.table === 'flop' ? 'active' : ''} id="flop" onClick={(e) => { hideShowHandTab(e.target.id) }}>Flop</h6> : ''}
                                    {turn?.length > 0 ? <h6 className={isActiveHand.table === 'turn' ? 'active' : ''} id="turn" onClick={(e) => { hideShowHandTab(e.target.id) }}>Turn</h6> : ''}
                                    {river?.length > 0 ? <h6 className={isActiveHand.table === 'river' ? 'active' : ''} id="river" onClick={(e) => { hideShowHandTab(e.target.id) }}>River</h6> : ''}
                                    {showDown?.length > 0 ? <h6 className={isActiveHand.table === 'showdown' ? 'active' : ''} id="showdown" onClick={(e) => { hideShowHandTab(e.target.id) }}>Showdown</h6> : ''}
                                  </div>
                                  <div className='cashier-box'>
                                    <div className='theme-bg'>
                                      <div className={isActiveHand.table === 'winner' ? `winner` : 'winner d-none'}>
                                        {gameHistory?.winners?.length > 0 ?
                                          <div>
                                            <h4 className='gameNameTitle gameDateilsTitle'>Winners</h4>
                                            <table className='table p-lb-table table-borderless'>
                                              <thead>
                                                <tr>
                                                  <th>{lang.ko.player}</th>
                                                  <th>{lang.ko.winning_amount}</th>
                                                  <th>{lang.ko.best_cards}</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {gameHistory?.winners?.map((winner, index) => {
                                                  return (
                                                    <tr key={index}>
                                                      <td className='histPlayer'>{winner.playerName}</td>
                                                      <td>{winner.winningAmount ? Number(winner.winningAmount).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}</td>
                                                      <td className='d-flex histCard-wrap'>
                                                        {winner.winningHands.length > 0 ? winner.winningHands.map((card, k) => {
                                                          return (
                                                            <img key={k} src={'../Images/Card/' + card + '.png'} className='historyCard cardlst'></img>
                                                          )
                                                        }) : ''}</td>
                                                    </tr>
                                                  );
                                                })}
                                              </tbody>
                                            </table>
                                          </div>
                                          : ''}
                                      </div>
                                      <div className={isActiveHand.table === 'gameplayer' ? `gameplayer` : 'gameplayer d-none'}>
                                        <h4 className='gameNameTitle gameDateilsTitle'>Players List</h4>
                                        <table className='table p-lb-table table-borderless'>
                                          <thead>
                                            <tr>
                                              <th>{lang.ko.player}</th>
                                              <th>Cards</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {gameHistory?.players?.length > 0 ? gameHistory?.players?.map((plr, index) => {
                                              return (
                                                <tr key={index}>
                                                  <td className='histPlayer'>{plr.playerName}</td>
                                                  <td className='d-flex histCard-wrap'>
                                                    {plr.cards.length > 0 ? plr.cards.map((card, k) => {
                                                      return (
                                                        <img key={k} src={'../Images/Card/' + card + '.png'} className='historyCard cardlst'></img>
                                                      )
                                                    }) : ''}</td>
                                                </tr>
                                              );
                                            }) : <tr>
                                              <td colSpan="7">No Player Found</td>
                                            </tr>}
                                          </tbody>
                                        </table>
                                      </div>
                                      <div className={isActiveHand.table === 'preflop' ? `preflop` : 'preflop d-none'}>
                                        {preFlop?.length > 0 ?
                                          <div>
                                            <h4 className='gameNameTitle gameDateilsTitle'>Hand Event - Pre Flop</h4>
                                            <table className='table p-lb-table table-borderless'>
                                              <thead>
                                                <tr>
                                                  <th>{lang.ko.player}</th>
                                                  <th>{lang.ko.action}</th>
                                                  <th>{lang.ko.chips}</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {preFlop.map((data, index) => {
                                                  return (
                                                    <tr key={index}>
                                                      <td style={{ width: '40%' }}>{data.playerName}</td>
                                                      <td style={{ width: '30%' }}>{playerActionArr(data.playerAction)}</td>
                                                      <td style={{ width: '30%' }}>{data.betAmount ? Number(data.betAmount).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}</td>
                                                      {/*<td style={{ width: '30%' }}>{data.betAmount > 0 ? data.betAmount.toFixed(0) : ''}</td>*/}
                                                    </tr>
                                                  );
                                                })}
                                              </tbody>
                                            </table>
                                          </div>
                                          : ''}
                                      </div>
                                      <div className={isActiveHand.table === 'flop' ? `flop` : 'flop d-none'}>
                                        {flop?.length > 0 ?
                                          <div>
                                            <h4 className='gameNameTitle gameDateilsTitle'>Hand Event - Flop

                                            </h4>
                                            <table className='table p-lb-table table-borderless'>
                                              <tbody>
                                                {gameHistory?.tableCards?.Flop.length > 0 ?
                                                  <tr>
                                                    <td className='flopTitle' style={{ width: '40%' }}>Flop</td>
                                                    <td style={{ width: '30%' }}>
                                                      {gameHistory?.tableCards?.Flop.map((card) => {
                                                        return (
                                                          <img className='historyCard' src={'../Images/Card/' + card + '.png'}></img>
                                                        )
                                                      })
                                                      }
                                                    </td>
                                                    <td style={{ width: '30%' }}></td>
                                                  </tr>
                                                  : ''}
                                                {flop.map((data, index) => {
                                                  return (
                                                    <tr key={index}>
                                                      <td style={{ width: '40%' }}>{data.playerName}</td>
                                                      <td style={{ width: '30%' }}>{playerActionArr(data.playerAction)}</td>
                                                      <td style={{ width: '30%' }}>{data.betAmount ? Number(data.betAmount).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}</td>
                                                      {/*<td style={{ width: '30%' }}>{data.betAmount > 0 ? data.betAmount.toFixed(0) : ''}</td>*/}
                                                    </tr>
                                                  );
                                                })}
                                              </tbody>
                                            </table>
                                          </div>
                                          : ''}
                                      </div>
                                      <div className={isActiveHand.table === 'turn' ? `turn` : 'turn d-none'}>
                                        {turn?.length > 0 ?
                                          <div>
                                            <h4 className='gameNameTitle gameDateilsTitle'>Hand Event - Turn</h4>
                                            <table className='table p-lb-table table-borderless'>
                                              <tbody>
                                                {gameHistory?.tableCards?.Turn.length > 0 ?
                                                  <tr >
                                                    <td style={{ width: '40%' }}>Turn</td>
                                                    <td style={{ width: '30%' }}>
                                                      {gameHistory?.tableCards?.Turn.map((card) => {
                                                        return (
                                                          <img className='historyCard' src={'../Images/Card/' + card + '.png'}></img>
                                                        )
                                                      })
                                                      }
                                                    </td>
                                                    <td style={{ width: '30%' }}></td>
                                                  </tr>
                                                  : ''}
                                                {turn.map((data, index) => {
                                                  return (
                                                    <tr key={index}>
                                                      <td style={{ width: '40%' }}>{data.playerName}</td>
                                                      <td style={{ width: '30%' }}>{playerActionArr(data.playerAction)}</td>
                                                      <td style={{ width: '30%' }}>{data.betAmount ? Number(data.betAmount).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}</td>
                                                      {/*<td style={{ width: '30%' }}>{data.betAmount > 0 ? data.betAmount.toFixed(0) : ''}</td>*/}
                                                    </tr>
                                                  );
                                                })}
                                              </tbody>
                                            </table>
                                          </div>
                                          : ''}
                                      </div>
                                      <div className={isActiveHand.table === 'river' ? `river` : 'river d-none'}>
                                        {river?.length > 0 ?
                                          <div>
                                            <h4 className='gameNameTitle gameDateilsTitle'>Hand Event - River</h4>
                                            <table className='table p-lb-table table-borderless'>
                                              <tbody>
                                                {gameHistory?.tableCards?.River.length > 0 ?
                                                  <tr >
                                                    <td style={{ width: '40%' }}>River</td>
                                                    <td style={{ width: '30%' }}>
                                                      {gameHistory?.tableCards?.River.map((card) => {
                                                        return (
                                                          <img className='historyCard' src={'../Images/Card/' + card + '.png'}></img>
                                                        )
                                                      })
                                                      }
                                                    </td>
                                                    <td style={{ width: '30%' }}></td>
                                                  </tr>
                                                  : ''}
                                                {river.map((data, index) => {
                                                  return (
                                                    <tr key={index}>
                                                      <td style={{ width: '40%' }}>{data.playerName}</td>
                                                      <td style={{ width: '30%' }}>{playerActionArr(data.playerAction)}</td>
                                                      <td style={{ width: '30%' }}>{data.betAmount ? Number(data.betAmount).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}</td>
                                                      {/*<td style={{ width: '30%' }}>{data.betAmount > 0 ? data.betAmount.toFixed(0) : ''}</td>*/}
                                                    </tr>
                                                  );
                                                })}
                                              </tbody>
                                            </table>
                                          </div>
                                          : ''}
                                      </div>
                                      <div className={isActiveHand.table === 'showdown' ? `showdown` : 'showdown d-none'}>
                                        {showDown?.length > 0 ?
                                          <div>
                                            <h4 className='gameNameTitle gameDateilsTitle'>Hand Event - Show Down</h4>
                                            <table className='table p-lb-table table-borderless'>
                                              <thead>
                                                <tr>
                                                  <th>{lang.ko.player}</th>
                                                  <th>{lang.ko.action}</th>
                                                  <th>{lang.ko.chips}</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {showDown.map((data, index) => {
                                                  return (
                                                    <tr key={index}>
                                                      <td style={{ width: '40%' }}>{data.playerName}</td>
                                                      <td style={{ width: '30%' }}>{playerActionArr(data.playerAction)}</td>
                                                      <td style={{ width: '30%' }}>{data.betAmount ? Number(data.betAmount).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}</td>
                                                      {/*<td style={{ width: '30%' }}>{data.betAmount > 0 ? data.betAmount.toFixed(0) : ''}</td>*/}
                                                    </tr>
                                                  );
                                                })}
                                              </tbody>
                                            </table>
                                          </div>
                                          : ''}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        :
                        <div className='playerProfileInfo'>
                          <table className='table p-lb-table table-borderless'>
                            <thead>
                              <tr>
                                <th width="10%">{lang.ko.date_time}</th>
                                <th width="20%">{lang.ko.game_name}</th>
                                <th width="20%">{lang.ko.winning_amount}</th>
                                {mobile ? <th width="20%">{lang.ko.hands}</th> : <th width="10%">{lang.ko.hands}</th> }
                                {mobile ?
                                  ''
                                  : <th width="50%">{lang.ko.best_cards}</th>}
                              </tr>
                            </thead>
                            <tbody>
                              {gameHistoryList && gameHistoryList.gamesHistoryList && gameHistoryList.gamesHistoryList.length > 0 ? gameHistoryList.gamesHistoryList.map((history, index) => {
                                return (
                                  <tr key={index} onClick={() => openGameHistory(history.gameId)}>
                                    <td>{moment(history.dateTime).format("HH:mm, YY/MM/DD ")}</td>
                                    <td>{history.game?.tableName}</td>
                                    <td>{history.winner?.winningAmount ? Number(history.winner?.winningAmount).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}</td>
                                    {/*<td>{history.winner?.winningAmount}</td>*/}
                                    <td className='histCard-wrap'>
                                      {history.handCards.length > 0 ? history.handCards.map((card, k) => {
                                        return (
                                          <img key={k} src={'../Images/Card/' + card + '.png'} className='historyCard cardlst'></img>
                                        )
                                      }) : ''}</td>
                                    {mobile ?
                                      ''
                                      :
                                      <td>
                                        {history.winner?.winningHands?.length > 0 ? history.winner.winningHands.map((winningCard, k) => {
                                          return (
                                            <img key={k} src={'../Images/Card/' + winningCard + '.png'} className='historyCard cardlst'></img>
                                          )
                                        }) : ''}</td>
                                    }
                                  </tr>
                                );
                              }) : <tr>
                                <td colSpan="7">데이터를 불러오는 중입니다.</td>
                              </tr>}
                            </tbody>
                          </table>
                          <div className="historyPagination">
                            <PaginationControl
                              page={page}
                              between={0}
                              total={gameHistoryList.recordsTotal}
                              limit={gameHistoryList.resultPerPage}
                              changePage={(page) => {
                                changePage(page);
                              }}
                              ellipsis={1}
                            />
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal >

      <Modal
        isOpen={isLogoutModalOpen}
        onRequestClose={closeLogoutModal}
        style={customStylesLogout}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className={mobile ? "gameModel mobileView" : 'gameModel'}>
          <div className='modalBody logout-modal'>
            <center>
              <h2 className='color-1 logout-title'>{lang.ko.warning}</h2>
              <h4 className='color-1 mt-4 logout-msg'>{lang.ko.alert_msg_leave_game}</h4>
              <div className='avtarBtnSection'>
                <button className='selectBuyInBtn logout-btn' onClick={() => logout()}>{lang.ko.yes}</button>
                <button className='selectBuyInBtn logout-btn' onClick={() => closeLogoutModal()}>{lang.ko.no}</button>
              </div>
            </center>
          </div>
        </div>
      </Modal>
    </div >
  );
};

export default HandHistory

