import React from 'react'
import { useState, useEffect } from 'react';
import './index.css'
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import socket from '../../socket';

const lang = require('../../Config/lang.js');

const TableSetting = (props) => {
  const userData = JSON.parse(localStorage.getItem('user'));
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(true)
  const [tableThemeSkinColor, setTableThemeSkinColor] = useState('default');
  const [tableThemeBackgroundColor, setTableThemeBackgroundColor] = useState('default');
  const [tableThemeFrontDeck, setTableThemeFrontDeck] = useState('default');
  const [tableThemeBackDeck, setTableThemeBackDeck] = useState('default');
  const [tableSettingTab, seTableSettingTab] = useState('frontDeck');
  const [isMobile, setIsMobile] = useState(false)

  const [isActive, setIsActive] = useState({
    table: 'tableTheme'
  })

  useEffect(() => {
    const mobile = window.innerWidth <= 1024;
    setIsMobile(mobile);

    hideShowDiv('tableTheme');
  }, [window.innerWidth]);


  const saveTableThemeSetting = (e) => {
    const data = {
      playerId: userData.playerId,
      tableTheme: tableThemeSkinColor,
      tableBackground: tableThemeBackgroundColor,
      backDeck: tableThemeBackDeck,
      frontDeck: tableThemeFrontDeck,
      productName: 'BytePoker'
    }
    socket.emit('TableThemeSettingsUpdate', data, (res) => {
      closeModal();
    });
  }

  const closeModal = () => {
    props.closeMyProfile()
  }

  const hideShowDiv = (id) => {
    if (id == 'tableTheme') {
      const data = {
        playerId: userData.playerId,
        productName: 'BytePoker'
      }
      socket.emit('TableThemeSettings', data, (res) => {
        if (res.status == 'success') {
          setTableThemeSkinColor(res.result.tableTheme)
          setTableThemeBackgroundColor(res.result.tableBackground)
          setTableThemeFrontDeck(res.result.frontDeck)
          setTableThemeBackDeck(res.result.backDeck)
        }
      });
    }
    setIsActive({
      table: id,
    })
  }
  const mobile = window.innerWidth <= 1024;
  let customStyles = {
    content: {
      inset: '44% auto auto 49.5%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#474B6B00',
      width: "60.625em",
      border: '1px solid #ffd0506e',
      boxShadow: '4px 4px 18px #ffd05075, -4px -4px 18px #ffd05075',
      padding: '0px',
      borderRadius: '15px',
    },
  }
  if (mobile) {
    customStyles = {
      content: {
        inset: '50% auto auto 50%',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#474B6B00',
        width: '100%',
        padding: '0px',
        border: 'none'
      },
    }
  }

  return (
    <div >
      <ToastContainer />
      <Modal
        isOpen={isAccountModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className={ isMobile ? "mobileView plyerProfile" : "plyerProfile" }>
          <div className="cashier-modal tblSetting">
            <div className='d-flex min-h-100'>
              <div className='p-col-3 modal-sidebar min-h-100'>
                <div className={ isMobile ? 'modal-mobile-header' : 'modal-sidebar-header'}>
                  <div className='cashier-heading'>
                    <h2>{lang.ko.table_setting}</h2>
                    { isMobile && (
                        <img className='close-modal-btn' src={'../Images/close.png'} onClick={() => closeModal(false)}></img>
                    )}
                  </div>
                </div>
                <div className={ isMobile ? 'cashier-modal-menu' : 'cashier-modal-body-profile-sidebar'}>
                  <button className={isActive.table === 'tableTheme' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="tableTheme"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>{lang.ko.table_theme}</button>
                  {/*<button className={isActive.table === 'settings' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="settings"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>Sound Setting</button>*/}
                </div>
              </div>
              <div className='p-col-9 min-h-100 theme-bg'>
                <div className='cashier-modal-card'>
                  <div className='cashier-modal-heading'>
                    {isActive.table === 'tableTheme' ?
                      <h2 >{lang.ko.table_theme}</h2>
                      : isActive.table === 'settings' ?
                        <h2 >{lang.ko.settings}</h2>
                        : ''
                    }
                    { !isMobile && (
                      <img className='close-modal-btn' src={'../Images/close.png'} onClick={() => closeModal(false)}></img>
                    )}
                  </div>
                  <div className='cashier-modal-body'>
                    <div className={isActive.table === 'settings' ? `settings` : 'settings d-none'}>
                      <div className='acount-security-table'>
                        <table className='table p-lb-table table-borderless'>
                          <tbody>
                            <tr>
                              <td>{lang.ko.sound_settings}</td>
                              <td><input type="checkbox" checked={true}></input></td>
                            </tr>
                            <tr>
                              <td>{lang.ko.vibration_settings}</td>
                              <td><input type="checkbox" checked={true}></input></td>
                            </tr>
                            <tr>
                              <td>{lang.ko.push_settings}</td>
                              <td><input type="checkbox" checked={true}></input></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className={isActive.table === 'tableTheme' ? `tableTheme` : 'tableTheme d-none'}>
                      <div className='table-theme-block'>
                        {/*
                        <div className={`table-theme-design table-bg-${tableThemeBackgroundColor} ${mobile ? 'mobile' : 'desktop'}`}>
                        */}
                        <div className={`table-theme-design table-bg-default ${mobile ? 'mobile' : 'desktop'}`}>
                          <div className='table-theme-game-area'>
                            <div className='table-shadow skin-section'></div>
                            <div className='table-border skin-section'></div>
                            {/*
                            <div className={`table-${tableThemeSkinColor} skin-section`}></div>
                            */}
                            <div className="table-default skin-section"></div>
                          </div>
                        </div>
                        <div className='table-theme-setting'>
                          <div className='d-flex gap-2 table-theme-setting-header'>
                            <h6 className={tableSettingTab === 'feltColor' ? 'active' : ''} id="feltColor" onClick={(e) => { seTableSettingTab(e.target.id) }} style={{display:'none'}}>{lang.ko.felt_color}</h6>
                            <h6 className={tableSettingTab === 'background' ? 'active' : ''} id="background" onClick={(e) => { seTableSettingTab(e.target.id) }} style={{display:'none'}}>{lang.ko.background}</h6>
                            <h6 className={tableSettingTab === 'frontDeck' ? 'active' : ''} id="frontDeck" onClick={(e) => { seTableSettingTab(e.target.id) }}>{lang.ko.front_deck}</h6>
                            <h6 className={tableSettingTab === 'backDeck' ? 'active' : ''} id="backDeck" onClick={(e) => { seTableSettingTab(e.target.id) }}>{lang.ko.back_deck}</h6>
                            {/* <h6 className={tableSettingTab === 'frontDeck' ? 'active' : ''} id="frontDeck" >FRONT DECK</h6> */}
                            {/* <h6 className={tableSettingTab === 'backDeck' ? 'active' : ''} id="backDeck" >BACK DECK</h6> */}
                          </div>

                          {tableSettingTab === 'feltColor' ?
                            <div className='table-setting-action-footer'>
                              <button className={tableThemeSkinColor === 'default' ? "table-setting-btn active " : "table-setting-btn"} onClick={() => setTableThemeSkinColor('default')}><div className='btn-box green-btn'></div></button>
                              <button className={tableThemeSkinColor === 'red' ? "table-setting-btn active " : "table-setting-btn"} onClick={() => setTableThemeSkinColor('red')}><div className='btn-box red-btn'></div></button>
                              {/* <button className={tableThemeSkinColor === 'gray' ? "table-setting-btn active " : "table-setting-btn"} onClick={() => setTableThemeSkinColor('gray')}><div className='btn-box gray-btn'></div></button> */}
                              <button className={tableThemeSkinColor === 'purpal' ? "table-setting-btn active " : "table-setting-btn"} onClick={() => setTableThemeSkinColor('purpal')}><div className='btn-box purpal-btn'></div></button>
                              {/* <button className={tableThemeSkinColor === 'gold' ? "table-setting-btn active " : "table-setting-btn"} onClick={() => setTableThemeSkinColor('gold')}><div className='btn-box gold-btn'></div></button> */}
                              <button className={tableThemeSkinColor === 'blue' ? "table-setting-btn active " : "table-setting-btn"} onClick={() => setTableThemeSkinColor('blue')}><div className='btn-box blue-btn'></div></button>
                            </div>
                            : tableSettingTab === 'background' ?
                              <div className='table-setting-action-footer'>
                                {/* <button className={tableThemeBackgroundColor === 'green' ? "table-setting-btn active " : "table-setting-btn"} onClick={() => setTableThemeBackgroundColor('green')}><div className='btn-box default-btn'></div></button> */}
                                <button className={tableThemeBackgroundColor === 'default' ? "table-setting-btn active " : "table-setting-btn"} onClick={() => setTableThemeBackgroundColor('default')}><div className='btn-box red-btn red-bg'></div></button>
                                {/* <button className={tableThemeBackgroundColor === 'default' ? "table-setting-btn active " : "table-setting-btn"} onClick={() => setTableThemeBackgroundColor('default')}><div className='btn-box gray-btn'></div></button> */}
                                <button className={tableThemeBackgroundColor === 'purpal' ? "table-setting-btn active " : "table-setting-btn"} onClick={() => setTableThemeBackgroundColor('purpal')}><div className='btn-box purpal-btn violet-bg'></div></button>
                                <button className={tableThemeBackgroundColor === 'gold' ? "table-setting-btn active " : "table-setting-btn"} onClick={() => setTableThemeBackgroundColor('gold')}><div className='btn-box gold-btn brown-bg'></div></button>
                                <button className={tableThemeBackgroundColor === 'blue' ? "table-setting-btn active " : "table-setting-btn"} onClick={() => setTableThemeBackgroundColor('blue')}><div className='btn-box blue-btn blue-bg'></div></button>
                                <button className={tableThemeBackgroundColor === 'gray' ? "table-setting-btn active " : "table-setting-btn"} onClick={() => setTableThemeBackgroundColor('gray')}><div className='btn-box gray-btn gray-bg'></div></button>
                              </div>
                              : tableSettingTab === 'frontDeck' ?
                                <div className="scroll mt-0">
                                  <div className="table-setting-container">
                                    <div className="table-setting-scroll deck-front">
                                      <button className={tableThemeFrontDeck === 'default' ? "box active " : "box"} onClick={() => setTableThemeFrontDeck('default')}>
                                        <img src="../Images/Deck/Front/default/TD.png"></img>
                                        <img src="../Images/Deck/Front/default/QC.png"></img>
                                        <img src="../Images/Deck/Front/default/KH.png"></img>
                                        <img src="../Images/Deck/Front/default/AS.png"></img>
                                      </button>
                                      <button className={tableThemeFrontDeck === 'premium' ? "box active " : "box"} onClick={() => setTableThemeFrontDeck('premium')}>
                                        <img src="../Images/Deck/Front/premium/TD.png?v=001"></img>
                                        <img src="../Images/Deck/Front/premium/QC.png?v=001"></img>
                                        <img src="../Images/Deck/Front/premium/KH.png?v=001"></img>
                                        <img src="../Images/Deck/Front/premium/AS.png?v=001"></img>
                                      </button>
                                      <button className={tableThemeFrontDeck === 'gold' ? "box active " : "box"} onClick={() => setTableThemeFrontDeck('gold')}>
                                        <img src="../Images/Deck/Front/gold/TD.png"></img>
                                        <img src="../Images/Deck/Front/gold/QC.png"></img>
                                        <img src="../Images/Deck/Front/gold/KH.png"></img>
                                        <img src="../Images/Deck/Front/gold/AS.png"></img>
                                      </button>
                                      <button className={tableThemeFrontDeck === 'silver' ? "box active " : "box"} onClick={() => setTableThemeFrontDeck('silver')}>
                                        <img src="../Images/Deck/Front/silver/TD.png"></img>
                                        <img src="../Images/Deck/Front/silver/QC.png"></img>
                                        <img src="../Images/Deck/Front/silver/KH.png"></img>
                                        <img src="../Images/Deck/Front/silver/AS.png"></img>
                                      </button>
                                      <button className={tableThemeFrontDeck === 'classic' ? "box active " : "box"} onClick={() => setTableThemeFrontDeck('classic')}>
                                        <img src="../Images/Deck/Front/classic/TD.png"></img>
                                        <img src="../Images/Deck/Front/classic/QC.png"></img>
                                        <img src="../Images/Deck/Front/classic/KH.png"></img>
                                        <img src="../Images/Deck/Front/classic/AS.png"></img>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                : tableSettingTab === 'backDeck' ?
                                  <div className="scroll mt-0">
                                    <div className="table-setting-container">
                                      <div className="table-setting-scroll deck-back">
                                        <button className={tableThemeBackDeck === 'default' ? "box active " : "box"} onClick={() => setTableThemeBackDeck('default')}>
                                          <img src="../Images/Deck/Back/default.png"></img>
                                        </button>
                                        <button className={tableThemeBackDeck === 'two' ? "box active " : "box"} onClick={() => setTableThemeBackDeck('two')}>
                                          <img src="../Images/Deck/Back/two.png"></img>
                                        </button>
                                        <button className={tableThemeBackDeck === 'three' ? "box active " : "box"} onClick={() => setTableThemeBackDeck('three')}>
                                          <img src="../Images/Deck/Back/three.png"></img>
                                        </button>
                                        <button className={tableThemeBackDeck === 'four' ? "box active " : "box"} onClick={() => setTableThemeBackDeck('four')}>
                                          <img src="../Images/Deck/Back/four.png"></img>
                                        </button>
                                        <button className={tableThemeBackDeck === 'five' ? "box active " : "box"} onClick={() => setTableThemeBackDeck('five')}>
                                          <img src="../Images/Deck/Back/five.png"></img>
                                        </button>
                                        <button className={tableThemeBackDeck === 'six' ? "box active " : "box"} onClick={() => setTableThemeBackDeck('six')}>
                                          <img src="../Images/Deck/Back/six.png"></img>
                                        </button>
                                        <button className={tableThemeBackDeck === 'seven' ? "box active " : "box"} onClick={() => setTableThemeBackDeck('seven')}>
                                          <img src="../Images/Deck/Back/seven.png"></img>
                                        </button>
                                        <button className={tableThemeBackDeck === 'eight' ? "box active " : "box"} onClick={() => setTableThemeBackDeck('eight')}>
                                          <img src="../Images/Deck/Back/eight.png"></img>
                                        </button>
                                        <button className={tableThemeBackDeck === 'nine' ? "box active " : "box"} onClick={() => setTableThemeBackDeck('nine')}>
                                          <img src="../Images/Deck/Back/nine.png"></img>
                                        </button>
                                        <button className={tableThemeBackDeck === 'ten' ? "box active " : "box"} onClick={() => setTableThemeBackDeck('ten')}>
                                          <img src="../Images/Deck/Back/ten.png"></img>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  : ''}

                          <div className='avtarBtnSection text-center'>
                            {/* <button type="button" className='defaultBtn' onClick={() => changePassword(1)}>Cancel</button> */}
                            <button type="button" className='edtAvtarBtn confirm-btn rounded-btn px-5' onClick={() => saveTableThemeSetting()}>{lang.ko.save}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal >
    </div >
  );
};

export default TableSetting

